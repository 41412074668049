import { GridPurpose } from "./models/siteAsset"

export const colors = {
  // Primary brand colors
  black: "#000D0F",
  white: "#ffffff",
  blue: {
    DEFAULT: "#0697B6", // Lake Blue
    100: "#F1FDFF", // Pastel Azure
    200: "#C0F5FF", // Frost Blue
    300: "#0697B6", // Lake Blue
    400: "#346876", // Light Sherpa Blue
    500: "#003E4A", // Sherpa Blue
    800: "#00232C", // Dark Sherpa Blue
  },
  neutral: {
    DEFAULT: "#6C8C93", // Sandy Blue Grey
    100: "#FAFAFB", // Temporary color for buttons
    200: "#EAEEEF", // Frozen Lake Grey
    300: "#CBCECF", // Dolphin Grey
    500: "#6C8C93", // Sandy Blue Grey
    700: "#346876", // Temporary color for buttons
    900: "#000D0F", // Black
  },

  "secondary-background": "#E9F0F1", // Temporary color for Enterprise

  // Highlight and accent colors
  highlight: "#0BD4FF", // Neon Blue - For indicating an active/selected state, or for CTAs on dark backgrounds.
  "highlight-hover": "#0CC6F0", // Temporary color for buttons
  "highlight-active": "#09BBE1", // Temporary color for buttons
  accent: "#E4FD7F", // Honeydew Peel - For calling attention to something, or for adding a pop of brightness to a cool palette.
  "accent-hover": "#DFFA75", // Temporary color for buttons
  "accent-active": "#D5F556", // Temporary color for buttons
  error: {
    DEFAULT: "#DE0000", // Error Red
    100: "#F8CCCC", // Error Red 100
    900: "#DE0000", // Error Red
  },

  // Gradient: for data visualization
  gradient: {
    900: "#0BD4FF", // Neon Blue
    800: "#09BBE1",
    700: "#08ACCE",
    600: "#079DBC",
    500: "#068DA9",
    400: "#057E98",
    300: "#046F85",
    200: "#036073",
    100: "#025161",
    50: "#003E4A", // Sherpa Blue
  },

  // Tertiary colors: For data visualization. Use sparingly in UI
  lilac: {
    DEFAULT: "#A99EEE",
    100: "#F2F0FC",
    200: "#E5E2FA",
    300: "#D4CEF6",
    400: "#E5E2FA",
    500: "#A99EEE",
  },
  orange: {
    DEFAULT: "#FF7E4D",
    100: "#FFECE4",
    200: "#FFD8CA",
    300: "#FFBEA6",
    400: "#FFA582",
    500: "#FF7E4D",
  },
  perkygreen: {
    DEFAULT: "#41907A",
    100: "#E3EEEB",
    200: "#C6DED7",
    300: "#A0C7BC",
    400: "#7AB1A2",
    500: "#41907A",
  },
  cornyellow: {
    DEFAULT: "#F8B600",
    100: "#FEF4D9",
    200: "#FDE9B3",
    300: "#FBDA80",
    400: "#FACC4D",
    500: "#F8B600",
  },
  neonred: {
    DEFAULT: "#FF4D59",
    100: "#FFE4E6",
    200: "#FFCACD",
    300: "#FFA6AC",
    400: "#FF828B",
    500: "#FF4D59",
  },
  mauveblue: {
    DEFAULT: "#353CD9",
    100: "#E1E2F9",
    200: "#C2C5F4",
    300: "#9A9DEC",
    400: "#7277E4",
    500: "#353CD9",
  },
  electricpurple: {
    DEFAULT: "#B600F8",
    100: "#F4D9FE",
    200: "#E9B3FD",
    300: "#DA80FB",
    400: "#CC4DFA",
    500: "#B600F8",
  },
  umepurple: {
    DEFAULT: "#904156",
    100: "#EEE3E6",
    200: "#DEC6CC",
    300: "#C7A0AA",
    400: "#B17A89",
    500: "#904156",
  },
  maygreen: {
    DEFAULT: "#499041",
    100: "#E4EEE3",
    200: "#C8DEC6",
    300: "#A4C7A0",
    400: "#80B17A",
    500: "#499041",
  },
  // Temporary off-palette colors for marketplace logo backgrounds
  "marketplace-grey": {
    100: "#F8F8F9",
    200: "#F4F4F5",
  },

  // Legacy graph colors
  graph: {
    text: "#000D0F",
    highlight: "#000D0F",
    background: "#FFFFFF",
    grid: "#757575",
    carbonBased: "#616161",
    carbonBasedLight: "#E0E0E0",
    carbonFree: "#FFAE0B",
    carbonFreeLight: "#FFEAB3",
    carbonFreeLocational: "#FFDD82",
    carbonFreeLocationalLight: "#FFFFFF",
    carbonFreeMarket: "#FFAE0B",
    carbonFreeMarketLight: "#FFEAB3",
    carbonIntensity: "#D30000",
    dark: {
      text: "#FFFFFF",
      highlight: "#E4FD7F",
      background: "#00232C",
      grid: "#346876",
      carbonBased: "#A99EEE",
      carbonBasedLight: "#D4CEF6",
      carbonFree: "#0BD4FF",
      carbonFreeLight: "#F1FDFF",
      carbonFreeLocational: "#0BD4FF",
      carbonFreeLocationalLight: "#F1FDFF",
      carbonFreeMarket: "#41907a",
      carbonFreeMarketLight: "#7ab1a2",
      carbonIntensity: "#D30000",
    },
  },
}

export const breakpoints = {
  // From quasar max values
  sm: 600,
  md: 1024,
  lg: 1440,
  xl: 1920,
  max: 1920,
}
export const gModelDict = {
  summary: {
    name: "summary",
    type: "summary",
    label: "Summary",
    text: "Summary",
    icon: "wc-ic:baseline-select-all",
    dataSource: "real_time",
    dataSourceLabel: "Summary",
    tags: [],
    params: ["annualUsageMwh", "areaSqft"],
  },
  single_family_detached: {
    name: "single_family_detached",
    type: "single_family_detached",
    label: "Single Family Detached",
    text: "A housing unit that provides living space for one household and is detached from any other housing unit.",
    icon: "wc-ic:baseline-house",
    tags: ["residential"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  single_family_attached: {
    name: "single_family_attached",
    type: "single_family_attached",
    label: "Single Family Attached",
    text: "A housing unit that provides living space for one family but is connected side-by-side with at least one other housing unit by a wall (e.g., townhouse, row house, or duplex.).",
    icon: "wc-ic:baseline-holiday-village",
    tags: ["residential"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  multi_family_2_4: {
    name: "multi_family_2_4",
    type: "multi_family_2_4",
    label: "Multi Family 2-4",
    text: "A building that includes two to four separate living quarters with individual kitchen facilities.",
    icon: "wc-ic:baseline-home-work",
    tags: ["residential"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  multi_family_5: {
    name: "multi_family_5",
    type: "multi_family_5",
    label: "Multi Family 5+",
    text: "A building that includes five or more separate living quarters with individual kitchen facilities.",
    icon: "wc-ic:baseline-apartment",
    tags: ["residential"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  mobile_home: {
    name: "mobile_home",
    type: "mobile_home",
    label: "Mobile Home",
    text: "A housing unit built off-site on a movable chassis and moved to a home site.",
    icon: "wc-ic:baseline-rv-hookup",
    tags: ["residential"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  small_office: {
    name: "small_office",
    type: "small_office",
    label: "Small Office",
    text: "A single-storey office building, usually under 10,000 sq.ft.",
    icon: "wc-ic:baseline-corporate-fare",
    tags: ["commercial"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  medium_office: {
    name: "medium_office",
    type: "medium_office",
    label: "Medium Office",
    text: "A low-rise office building with four floors or under.",
    icon: "wc-ic:baseline-business",
    tags: ["commercial"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  large_office: {
    name: "large_office",
    type: "large_office",
    label: "Large Office",
    text: "A mid- or high-rise office building with over four floors.",
    icon: "wc-ic:baseline-location-city",
    tags: ["commercial"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  small_hotel: {
    id: 12,
    name: "small_hotel",
    type: "small_hotel",
    label: "Small Hotel",
    text: "Short term lodging facility with under 50 rooms usually in one or two storeys (e.g. motels, inns, boutique hotels, hostels).",
    icon: "wc-ic:baseline-night-shelter",
    tags: ["commercial"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  large_hotel: {
    id: 13,
    name: "large_hotel",
    type: "large_hotel",
    label: "Large Hotel",
    text: "Large short-term lodging facility, typically with more than 50 rooms.",
    icon: "wc-ic:baseline-hotel",
    tags: ["commercial"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  retail_stripmall: {
    id: 14,
    name: "retail_stripmall",
    type: "retail_stripmall",
    label: "Retail Stripmall",
    text: "Shopping center comprised of multiple connected establishments, each with a separate exterior entrance.",
    icon: "wc-ic:baseline-store",
    tags: ["commercial", "retail"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  retail_standalone: {
    id: 15,
    name: "retail_standalone",
    type: "retail_standalone",
    label: "Retail Standalone",
    text: "Buildings used for the sale and display of goods other than food. E.g. retail stores, vehicle dealerships, liquor stores, galleries.",
    icon: "wc-ic:baseline-storefront",
    tags: ["commercial", "retail"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  quick_service_restaurant: {
    id: 16,
    name: "quick_service_restaurant",
    type: "quick_service_restaurant",
    label: "Quick Service Restaurant",
    text: "Restaurants that offer limited menus or fast food, and rarely provide table service or serve alcohol. They may or may not have seating for customers.",
    icon: "wc-ic:baseline-food-bank",
    tags: ["commercial"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  full_service_restaurant: {
    name: "full_service_restaurant",
    type: "full_service_restaurant",
    label: "Full Service Restaurant",
    text: "Restaurants that offer menus with a wide range of choices, serve alcohol and primarily serve dine-in customers.",
    icon: "wc-ic:baseline-restaurant",
    tags: ["commercial"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  warehouse: {
    name: "warehouse",
    type: "warehouse",
    label: "Warehouse",
    text: "Building used primarily for short or long-term storage of goods, manufactured products, merchandise, and raw materials. E.g. Non-refrigerated warehouses, distribution or shipping centers, other storage buildings, and public rental storage units.",
    icon: "wc-ic:baseline-warehouse",
    tags: ["commercial"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  outpatient: {
    name: "outpatient",
    type: "outpatient",
    label: "Outpatient",
    text: "Buildings used as diagnostic and treatment facilities for outpatient care. E.g. medical offices, dental clinics, veterinarians.",
    icon: "wc-ic:baseline-medication",
    tags: ["commercial", "public"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  hospital: {
    name: "hospital",
    type: "hospital",
    label: "Hospital",
    text: "Buildings used as diagnostic and treatment facilities for inpatient care.",
    icon: "wc-ic:baseline-local-hospital",
    tags: ["commercial", "public"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  primary_school: {
    name: "primary_school",
    type: "primary_school",
    label: "Primary School",
    text: "Buildings used for academic classroom instruction for elementary school-age students.",
    icon: "wc-ic:baseline-cottage",
    tags: ["commercial", "public"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  secondary_school: {
    name: "secondary_school",
    type: "secondary_school",
    label: "Secondary School",
    text: "Buildings used for academic classroom instruction for secondary school-age students.",
    icon: "wc-ic:baseline-school",
    tags: ["commercial", "public"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: ["annualUsageMwh", "areaSqft"],
  },
  solar: {
    name: "solar",
    type: "solar",
    gridPurpose: GridPurpose.MarketBasedProduction,
    label: "Solar REC",
    text: "A solar panel or a group of solar panels made of semiconductor materials and used to generate electricity from solar energy.",
    icon: "wc-ic:baseline-solar-power",
    tags: ["market_based_production"],
    dataSource: "real_time",
    params: ["nameplateCapacityKw", "annualEnergyGeneratedMwh"],
  },
  wind: {
    name: "wind",
    type: "wind",
    gridPurpose: GridPurpose.MarketBasedProduction,
    label: "Wind REC",
    text: "A wind turbine or a wind farm that converts the kinetic energy of wind into electrical energy.",
    icon: "wc-ic:baseline-wind-power",
    tags: ["market_based_production"],
    dataSource: "cached",
    params: ["nameplateCapacityMw", "annualEnergyGeneratedMwh"],
  },
  solar_locational: {
    name: "solar_locational",
    type: "solar",
    gridPurpose: GridPurpose.LocationalProduction,
    label: "Solar Locational",
    text: "A solar panel or a group of solar panels made of semiconductor materials and used to generate electricity from solar energy.",
    icon: "wc-ic:baseline-solar-power",
    tags: ["locational_production"],
    dataSource: "real_time",
    params: ["nameplateCapacityKw", "annualEnergyGeneratedMwh"],
  },
  custom: {
    name: "custom",
    type: "custom",
    label: "Custom",
    text: "Any energy resource for which you have hourly energy use data.",
    icon: "wc-ic:baseline-flash-on",
    tags: ["commercial", "residential"],
    dataSource: "real_time",
    gridPurpose: GridPurpose.Consumption,
    params: [],
  },
} as { [key: string]: any }
