import { authorizedRoute } from "@/services/auth/authorized.route-guard"
import { RouteRecordRaw } from "vue-router"
import { AppPortal } from "@common/models/models"
import DashboardView from "@/modules/dashboard/DashboardView.vue"
import AppLayout from "@/components/layout/AppLayout.vue"
export const dashboardRoutes: Array<RouteRecordRaw> = [
  {
    path: "/dashboard",
    name: "wc-dashboard",
    component: DashboardView,
    meta: { layout: AppLayout, appPortal: AppPortal.CarbonAccounting },
    beforeEnter: authorizedRoute,
  },
]
