import { defineStore } from "pinia"
import { Account, Auth, Provider, ProviderData } from "@common/models/models"
import { Asset } from "@common/models/asset"
import { TYPE, useToast } from "vue-toastification"
import { colors } from "@common/global"

const toast = useToast()

export const useMainStore = defineStore("main", {
  state: () => {
    return {
      auth: {
        token: "",
        user: undefined,
        isAuthenticated: false,
        scope: [],
      } as Auth,
      apiKey: "", // 2c715044-9cfe-4ba7-9d6c-28feafc3e533
      accounts: [] as Account[],
      provider: {} as Provider,
      providers: new Array<Provider>(),
      providerData: [] as ProviderData[],
      isDark: false,
      isAccountSwitcherModalOpen: false,
      assetsToUpdate: [] as Asset[],
      isUnlisting: false,
      colors: {
        text: colors.graph.text,
        highlight: colors.graph.text,
        background: colors.graph.background,
        grid: colors.graph.grid,
        carbonBased: colors.graph.carbonBased,
        carbonBasedLight: colors.graph.carbonBasedLight,
        carbonFree: colors.graph.carbonFree,
        carbonFreeLight: colors.graph.carbonFreeLight,
        carbonFreeLocational: colors.graph.carbonFreeLocational,
        carbonFreeLocationalLight: colors.graph.carbonFreeLocationalLight,
        carbonFreeMarket: colors.graph.carbonFreeMarket,
        carbonFreeMarketLight: colors.graph.carbonFreeMarketLight,
        carbonIntensity: colors.graph.carbonIntensity,
      },
    }
  },
  persist: true,
  getters: {
    isLoggedIn: (state: any) => {
      return state.auth.token && state.auth.isAuthenticated
    },
    isAdmin: (state: any) => {
      return state.auth.scope && state.auth.scope.indexOf("super") > -1
    },
    isSpoof: (state: any) => {
      return state.auth.scope && state.auth.scope.indexOf("wc:spoof") > -1
    },
    account: (state: any) => {
      const account = state.accounts.find((a: Account) => a.id === state.auth.user?.account_id)
      if (!account) {
        return { id: state.auth.user?.account_id }
      }
      return account
    },
    getProviderByKey: (state: any) => {
      return (providerKey: string) => state.providers.find((provider: Provider) => provider.key === providerKey)
    },
  },
  actions: {
    setAuth(auth: Auth) {
      this.auth = auth
    },

    addMessage(message: string, options: any = null) {
      if (!options) {
        options = {
          timeout: 5000,
        }
      }
      toast(message, options)
    },
    addErrorMessage(message: string, isStatic = false) {
      const options: any = {
        type: TYPE.ERROR,
        timeout: 5000,
      }
      if (isStatic) {
        options.timeout = false
      }
      this.addMessage(message, options)
    },
    addSuccessMessage(message: string) {
      const options = {
        type: TYPE.SUCCESS,
        timeout: 5000,
      }
      this.addMessage(message, options)
    },
    setProvider(provider: Provider) {
      this.provider = provider
    },
    toggleDark() {
      this.isDark = !this.isDark
      this.colors = this.isDark ? colors.graph.dark : colors.graph
    },
  },
})
