<template>
  <div class="cursor-pointer border-b-2 pb-2" :class="classes">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"

const props = defineProps({
  selected: {
    type: Boolean,
    required: true,
  },
  fontClass: {
    type: String,
    required: false,
    default: "text-heading-5",
  },
})

const classes = computed(() => {
  const selectedClasses = props.selected ? "text-blue-500 border-highlight" : "text-neutral-500 hover:text-blue-400 border-[transparent]"
  return [selectedClasses, props.fontClass].join(" ")
})
</script>
