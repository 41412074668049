<template>
  <button ref="button" :class="`${buttonClasses} flex items-baseline gap-2`" :type="type" :disabled="isDisabled">
    <WcButtonIcon v-if="icon && iconPosition === 'left'" :icon="icon" :color="color" :variant="variant" />
    {{ text }}
    <WcButtonIcon v-if="icon && iconPosition === 'right'" :icon="icon" :color="color" :variant="variant" />
  </button>
</template>

<script lang="ts" setup>
import useButtonClasses from "./useButtonClasses"
import WcButtonIcon from "./WcButtonIcon.vue"
import type { ButtonColor, ButtonIconPosition, ButtonSize, ButtonType, ButtonVariant } from "./WcButton"

type Props = {
  color?: ButtonColor
  icon?: string
  iconPosition?: ButtonIconPosition
  isDisabled?: boolean
  size?: ButtonSize
  text?: string
  type?: ButtonType
  variant?: ButtonVariant
}

const props = withDefaults(defineProps<Props>(), {
  color: "primary",
  iconPosition: "left",
  isDisabled: false,
  size: "large",
  type: "button",
  variant: "contained",
})

const buttonClasses = useButtonClasses(props)
</script>

<style scoped lang="scss">
.hover-shadow:hover {
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 4px -1px rgba(0, 0, 0, 0.2);
}
.active-shadow:active {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
</style>
