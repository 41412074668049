<template>
  <div class="wc-page-container text-body-3 w-full">
    <div v-if="!isInCheckoutFlow" class="ph-no-capture mb-8 flex justify-start">
      <ButtonArrowCTA
        direction="backward"
        type="router-link"
        :path="{ name: 'wc-order-history' }"
        class="mt-12"
        button-classes="text-blue-400 fill-blue-400 hover:fill-highlight"
        @click="captureBackToOrdersEvent">
        Back to Orders
      </ButtonArrowCTA>
    </div>
    <div class="mt-12 flex justify-center">
      <div class="basis-full md:basis-1/2">
        <h2 class="text-heading-4">Thank you</h2>
      </div>
    </div>
    <div class="my-10 flex justify-center">
      <div class="basis-full md:basis-1/2">
        <h3 class="text-heading-5">What’s next?</h3>
        <p class="mt-4">
          Track energy and carbon savings generated by this portfolio
          <router-link
            class="ph-no-capture cursor-pointer text-blue-400 underline"
            :to="{ name: 'wc-order-history' }"
            @click="captureAccountNavigationLinkEvent"
            >in your WattCarbon account</router-link
          >. Project deployment for this portfolio is currently scheduled as <strong>{{ order.portfolio.dateRangeDescription }}</strong
          >. As decarbonization projects in this portfolio are deployed and impacts are realized, WattCarbon will issue Energy Attribute Certificates
          (EACs) to you.
        </p>
      </div>
    </div>
    <div class="mt-10 flex justify-center pb-14">
      <div class="basis-full rounded-sm bg-neutral-200 p-8 md:basis-1/2">
        <h3 class="text-heading-5">Order summary</h3>
        <div class="items-end border-b border-neutral-500 px-5 py-10">
          <div v-if="orderAccount" class="flex justify-between">
            <div class="text-body-3">Account</div>
            <div class="text-body-3-strong">{{ orderAccount }}</div>
          </div>
          <div class="mt-6 flex justify-between">
            <div class="text-body-3">Order date</div>
            <div class="text-body-3-strong">{{ orderDate }}</div>
          </div>
          <div class="mt-6 flex justify-between">
            <div class="text-body-3">Order ID</div>
            <div class="text-body-3-strong">{{ order.id }}</div>
          </div>
          <div class="mt-6 flex justify-between">
            <div class="text-body-3">Portfolio</div>
            <div class="text-body-3-strong ph-no-capture text-right underline">
              <router-link :to="{ name: 'wc-portfolio', params: { id: order.portfolio.id || 0 } }" @click="capturePortfolioLinkEvent">
                {{ order.portfolio.name }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="border-b border-neutral-500 px-5 py-6">
          <div class="flex justify-between">
            <div>Amount</div>
            <div class="text-body-3-strong">{{ order.quantity }} {{ Portfolio.Units(order.portfolio) }}</div>
          </div>
          <div v-if="!!orderCalculatedCarbonSavings" class="mt-6 flex justify-between">
            <div>Calculated carbon savings</div>
            <div>
              <strong>{{ orderCalculatedCarbonSavings.toFixed(2) }} tCO2</strong> avoided
            </div>
          </div>
          <div class="mt-6 flex justify-between">
            <div>Price</div>
            <div>
              <strong>{{ Portfolio.PriceFormatted(order.portfolio) }}</strong> / {{ Portfolio.Units(order.portfolio) }}
            </div>
          </div>
        </div>
        <div class="border-b border-neutral-500 px-5 py-6">
          <div class="flex justify-between">
            <div>Agreement</div>
            <button class="text-body-3-strong ph-no-capture text-blue-400 underline" @click="handleClickDownloadAgreement">Download agreement</button>
          </div>
        </div>
        <div class="flex justify-between px-5 pt-8">
          <div class="text-body-3-strong">Total paid</div>
          <div class="text-body-1-strong">
            {{ Order.TotalFormatted(order).toLocaleString() }}
          </div>
        </div>
        <div class="mt-10 flex justify-center">
          <div>
            <router-link v-if="isInCheckoutFlow" :to="{ name: 'wc-order-history' }" @click="captureViewOrdersEvent">
              <ButtonCTA width="full" button-classes="bg-blue-500 hover:bg-blue-300 text-white ph-no-capture">View orders</ButtonCTA>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// Libraries
import { ref, computed } from "vue"
import posthog from "posthog-js"
import { useRoute, useRouter } from "vue-router"
import { format } from "date-fns"
// Models
import { Order, Portfolio } from "@common/models/order"
// Services
import { useMainStore } from "@/store"
import { useAuthService, useOrdersService } from "@/services/service-container"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import ButtonArrowCTA from "@/components/ui/ButtonArrowCTA.vue"

const store = useMainStore()
const route = useRoute()
const router = useRouter()
const authService = useAuthService()
const orderService = useOrdersService()
const order = ref<Order>(new Order())

const orderCalculatedCarbonSavings = computed(() => {
  return order.value.portfolio.estimatedCarbonIntensityTonnesCo2PerMwh
    ? order.value.portfolio.estimatedCarbonIntensityTonnesCo2PerMwh * order.value.quantity
    : undefined
})

const captureBackToOrdersEvent = () => posthog.capture("Clicked on the 'Back to Orders' link - Portfolio Confirmation View")
const captureAccountNavigationLinkEvent = () => posthog.capture("Clicked on the 'in your WattCarbon account' link - Portfolio Confirmation View")
const capturePortfolioLinkEvent = () => posthog.capture("Clicked on the 'Portfolio' link in the order summary - Portfolio Confirmation View")
const captureDownloadAgreementLinkEvent = () => posthog.capture("Clicked on the 'Download agreement' link - Portfolio Confirmation View")
const captureViewOrdersEvent = () => posthog.capture("Clicked on the 'View Orders' button - Portfolio Confirmation View")

const orderDate = computed(() => {
  if (order.value.createdTime) {
    return format(new Date(order.value.createdTime), "LLLL d, Y")
  } else {
    return ""
  }
})

try {
  order.value = await orderService.get(Number(route.params.id))
} catch (err) {
  console.error(`Could not fetch order: ${err}`)
  router.push({ name: "wc-orders" })
}

// set current account if it's different from the order account
if (order.value.accountId && store.account.id !== order.value.accountId) {
  authService.switchAccount(order.value.accountId)
}

const orderAccount = computed(() => {
  return store.accounts.find((account) => account.id === order.value.accountId)?.name || order.value.accountId.toString()
})

const handleClickDownloadAgreement = async () => {
  captureDownloadAgreementLinkEvent()
  const agreementLink = await orderService.getAgreementLink(Number(route.params.id))
  if (agreementLink) {
    window.open(agreementLink.url, "_blank")
  }
}

const isInCheckoutFlow = computed(() => {
  return route.query.redirect_status == "succeeded"
})
</script>

<style scoped lang="scss"></style>
