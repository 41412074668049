<template>
  <AppPage>
    <div v-if="allowUploadAssetTimeSeries === false" class="wc-page-container m-16">This page does not exist</div>
    <div v-if="allowUploadAssetTimeSeries === true">
      <AppPageHeader :backlink-route="{ name: 'wc-assets-asset', params: { assetId: props.assetId } }" backlink-text="Back to Asset"
        >Upload time series data for {{ getAssetDisplayName(asset) }}</AppPageHeader
      >
      <AppPageContent>
        <div class="max-w-[780px]">
          <form class="flex flex-col gap-2" @submit.prevent="handleSubmit">
            <p>
              Time series data should be formatted as a CSV file. The file's header row defines the column names. Each subsequent row represents a
              measurement. If the asset has previously-uploaded data for any of the rows, those rows will be overwritten with the updated values.
            </p>
            <p>At a minimum, these two columns are required:</p>
            <ol class="text-body-1 list-outside list-decimal pl-6">
              <li>A datetime column. Configure the format using the "Time Format" field.</li>
              <li>
                A measurement column. Commas in numeric values will be treated as thousands separators and discarded. Please use period (.) as a
                decimal separator.
              </li>
            </ol>
            <div class="mt-8 border-t border-neutral-300">
              <label class="my-8">
                Upload time series data as a CSV file *
                <WcInputFile
                  v-model="uploadedFiles"
                  required
                  name="uploadedFiles"
                  accept=".csv"
                  :max-file-size="MAX_FILE_SIZE"
                  class="mt-2"
                  @update:model-value="handleUploadFile" />
              </label>
              <label class="my-8">
                Skip lines
                <WcInputNumber v-model="skipLines" name="skipLines" class="mt-2" />
              </label>
              <label class="my-8">
                Delimiter
                <WcInputText v-model="delimiter" name="delimiter" class="mt-2" />
              </label>
              <label class="my-8">
                Time column *
                <WcDropdown v-model="timeColumn" required name="timeColumn" :options="availableColumnOptions" class="mt-2" />
              </label>
              <label class="my-8">
                Time Format *
                <WcDropdown v-model="timeFormat" required editable name="timeFormat" :options="timeFormatOptions" class="mt-2" />
              </label>
              <details class="-mt-6">
                <summary>Syntax</summary>

                <p>Select a pre-defined time format above or specify the time format with the following tokens:</p>

                <dl>
                  <dt>Year</dt>
                  <dd><code>yyyy</code> &mdash; 4-digit year (e.g. 2021)</dd>
                  <dt>Month</dt>
                  <dd><code>mmmm</code> &mdash; full month name (e.g. January)</dd>
                  <dd><code>mmm</code> &mdash; 3-letter month abbreviation (e.g. Jan)</dd>
                  <dd><code>mm</code> &mdash; 2-digit month (e.g. 01)</dd>
                  <dd><code>m</code> &mdash; 1- or 2-digit month (e.g 1)</dd>
                  <dt>Day</dt>
                  <dd><code>dd</code> &mdash; 2-digit day (e.g. 01)</dd>
                  <dd><code>d</code> &mdash; 1- or 2-digit day (e.g. 1)</dd>
                  <dt>Hour</dt>
                  <dd><code>HH</code> &mdash; 2-digit 24-hour (e.g. 13)</dd>
                  <dd><code>H</code> &mdash; 1- or 2-digit 24-hour (e.g. 13)</dd>
                  <dd><code>hh</code> &mdash; 2-digit 12-hour (e.g. 01)</dd>
                  <dd><code>h</code> &mdash; 1- or 2-digit 12-hour (e.g. 1)</dd>
                  <dt>Minute</dt>
                  <dd><code>MM</code> &mdash; 2-digit minute (e.g. 01)</dd>
                  <dd><code>M</code> &mdash; 1- or 2-digit minute (e.g. 1)</dd>
                  <dt>Second</dt>
                  <dd><code>SS</code> &mdash; 2-digit second (e.g. 01)</dd>
                  <dd><code>S</code> &mdash; 1- or 2-digit second (e.g. 1)</dd>
                  <dt>AM/PM</dt>
                  <dd><code>a</code> &mdash; AM or PM</dd>
                  <dt>Timezone</dt>
                  <dd><code>ZZZZ</code> &mdash; timezone offset (e.g. +0000)</dd>
                </dl>

                <p>
                  Any non-alphabetic characters are treated as literals. For example, <code>yyyy-mm-dd HH:MM</code> would accept
                  <code>2011-11-17 12:00</code>.
                </p>
              </details>
              <label class="my-8">
                Time kind *
                <WcDropdown v-model="timeKind" required name="timeKind" :options="timeKindOptions" class="mt-2" />
              </label>
              <label class="my-8">
                Value column *
                <WcDropdown v-model="valueColumn" required name="valueColumn" :options="availableColumnOptions" class="mt-2" />
              </label>
              <label class="my-8">
                Value units *
                <WcDropdown v-model="valueUnits" required name="valueUnits" :options="valueUnitOptions" class="mt-2" />
              </label>
              <label class="my-8">
                Null value
                <WcInputText v-model="valueNull" name="valueNull" class="mt-2" />
              </label>
              <label class="my-8">
                Time zone *
                <WcInputTimeZone v-model="timeZone" required name="timeZone" class="mt-2" :additional-options="additionalTimeZoneOptions" />
              </label>
              <label class="my-8">
                Missing data *
                <WcDropdown v-model="missingData" required name="missingData" :options="missingDataOptions" class="mt-2" />
              </label>
              <WcButton
                :icon="isUploading ? 'spinner' : undefined"
                text="Upload"
                type="submit"
                :is-disabled="isUploading || uploadedFiles == null || uploadedFiles?.length === 0" />
            </div>
          </form>
        </div>
      </AppPageContent>
    </div>
  </AppPage>
</template>

<script setup lang="ts">
import { onMounted, ref, watchEffect } from "vue"
import { useRouter } from "vue-router"
import type { Asset } from "@common/models/asset"
import { getAssetDisplayName } from "@common/models/asset"
import type { AssetTimeSeriesMissingData, AssetTimeSeriesTimeKind, AssetTimeSeriesValueUnit } from "@common/models/assetTimeSeries"
import { ASSET_TIMESERIES_MISSING_DATA, ASSET_TIMESERIES_TIME_KIND, ASSET_TIMESERIES_VALUE_UNIT } from "@common/models/assetTimeSeries"
import type { SelectOption } from "@/components/input"
import { pickDropdownValue, WcDropdown, WcInputFile, WcInputTimeZone, WcInputNumber, WcInputText } from "@/components/input"
import { AppPage, AppPageContent, AppPageHeader } from "@/components/layout"
import { WcButton } from "@/components/button"
import { useAllowUploadAssetTimeSeries } from "@/features"
import { useAssetService } from "@/services/service-container"
import { parseCsvFileHeadings } from "@/utils/csv"
import type { AssetTimeSeriesUploadResult } from "./asset.service"
import { useAssetTimeSeriesStore } from "./assetTimeSeries.state"

const MAX_FILE_SIZE = 20 * 1024 * 1024 // 20 MB
const DEFAULT_TIME_COLUMN_NAME = "datetime"
const DEFAULT_VALUE_COLUMN_NAME = "value_kwh"

const additionalTimeZoneOptions = [
  { label: "Infer time zone from address", value: "local" },
  { label: "Use time zones specified in CSV", value: "" },
]
const missingDataOptions = Object.entries(ASSET_TIMESERIES_MISSING_DATA).map(([value, label]) => ({
  label,
  value: value as AssetTimeSeriesMissingData,
}))
const timeFormatOptions = [{ label: "ISO-8601", value: "ISO-8601" }]
const timeKindOptions = Object.entries(ASSET_TIMESERIES_TIME_KIND).map(([value, label]) => ({
  label,
  value: value as AssetTimeSeriesTimeKind,
}))
const valueUnitOptions = Object.entries(ASSET_TIMESERIES_VALUE_UNIT).map(([value, label]) => ({
  label,
  value: value as AssetTimeSeriesValueUnit,
}))

const router = useRouter()
const allowUploadAssetTimeSeries = useAllowUploadAssetTimeSeries()
const assetService = useAssetService()
const assetTimeSeriesStore = useAssetTimeSeriesStore()

const props = defineProps<{ assetId: number }>()

const asset = ref<Asset | undefined>()
const isUploading = ref<boolean>(false)

// Input models
const uploadedFiles = ref<FileList | null>(null)
const delimiter = ref<string>(",")
const skipLines = ref<number>(0)
const timeZone = ref<SelectOption<string>>(additionalTimeZoneOptions[0])
const availableColumnOptions = ref<SelectOption<string>[]>([])
const missingData = ref<SelectOption<AssetTimeSeriesMissingData>>(missingDataOptions[0])
const timeColumn = ref<SelectOption<string> | null>(null)
const timeFormat = ref<SelectOption<string>>(timeFormatOptions[0])
const timeKind = ref<SelectOption<AssetTimeSeriesTimeKind>>(timeKindOptions[0])
const valueColumn = ref<SelectOption<string> | null>(null)
const valueUnits = ref<SelectOption<AssetTimeSeriesValueUnit>>(valueUnitOptions[0])
const valueNull = ref<string>("")

onMounted(async () => {
  if (!props.assetId) {
    router.replace({ name: "wc-assets" })
  }
  try {
    asset.value = await assetService.getAsset(props.assetId!)
  } catch (error) {
    router.replace({ name: "wc-assets" })
  }

  // Restore previous values if this is the same asset
  const inputs = assetTimeSeriesStore.assetTimeSeriesInputs
  if (inputs?.assetId === props.assetId) {
    // Create a DataTransfer object and add our file to it
    const dataTransfer = new DataTransfer()
    dataTransfer.items.add(inputs.fileData)
    uploadedFiles.value = dataTransfer.files

    // Wait a blip for the available column dropdowns to be populated before setting the values
    setTimeout(() => {
      timeColumn.value = pickDropdownValue(availableColumnOptions.value, inputs.time_column, null)
      valueColumn.value = pickDropdownValue(availableColumnOptions.value, inputs.value_column, null)
    }, 200)
    delimiter.value = inputs.delimiter ?? ","
    skipLines.value = inputs.skip_lines ?? 0
    missingData.value = pickDropdownValue(missingDataOptions, inputs.missing_data)
    timeKind.value = pickDropdownValue(timeKindOptions, inputs.time_kind)
    timeFormat.value = pickDropdownValue(timeFormatOptions, inputs.time_format, inputs.time_format ?? timeFormatOptions[0])
    timeZone.value = pickDropdownValue(additionalTimeZoneOptions, inputs.timezone)
    valueUnits.value = pickDropdownValue(valueUnitOptions, inputs.value_units)
    valueNull.value = inputs.value_null ?? ""
  }
})

watchEffect(async () => {
  const files = uploadedFiles.value

  if (files && files.length > 0) {
    const fileData = files[0]
    let headings = [] as string[]
    try {
      headings = await parseCsvFileHeadings(fileData, { delimiter: delimiter.value, skipLines: skipLines.value })
    } catch (error) {
      if (error instanceof Error) {
        console.error("There was a problem parsing the time series", error.message)
      }
    }
    availableColumnOptions.value = headings.map((heading) => ({ label: heading, value: heading }))
    if (!timeColumn.value) {
      const datetimeColumnIndex = headings.findIndex((heading) => heading.toLowerCase() === DEFAULT_TIME_COLUMN_NAME)
      if (datetimeColumnIndex > -1) {
        timeColumn.value = availableColumnOptions.value[datetimeColumnIndex]
      }
    }
    if (!valueColumn.value) {
      const energyKwhColumnIndex = headings.findIndex((heading) => heading.toLowerCase() === DEFAULT_VALUE_COLUMN_NAME)
      if (energyKwhColumnIndex > -1) {
        valueColumn.value = availableColumnOptions.value[energyKwhColumnIndex]
      }
    }
  } else {
    availableColumnOptions.value = []
  }
})

const handleUploadFile = (files: FileList | null) => {
  uploadedFiles.value = files
}

const handleSubmit = async () => {
  const fileData = uploadedFiles.value?.[0]

  if (!fileData) {
    console.error("No file selected")
    return
  }

  isUploading.value = true
  assetTimeSeriesStore.assetTimeSeriesFile = fileData
  assetTimeSeriesStore.assetTimeSeriesErrors = []
  assetTimeSeriesStore.assetTimeSeriesInputs = {
    assetId: props.assetId,
    fileData,
    missing_data: missingData.value!.value,
    time_column: timeColumn.value!.value,
    time_format: typeof timeFormat.value === "string" ? timeFormat.value : timeFormat.value.value,
    time_kind: timeKind.value!.value,
    timezone: timeZone.value!.value,
    value_column: valueColumn.value!.value,
    value_units: valueUnits.value!.value,
    value_null: valueNull.value,
    delimiter: delimiter.value,
    skip_lines: skipLines.value,
  }

  // Validate the uploaded assets and persist to store
  let entries = [] as AssetTimeSeriesUploadResult
  try {
    const result = await assetService.validateUploadedAssetTimeSeries(assetTimeSeriesStore.assetTimeSeriesInputs)
    if (Array.isArray(result)) {
      entries = result
    } else {
      assetTimeSeriesStore.assetTimeSeriesErrors = result.detail
    }
  } catch (error) {
    if (error instanceof Error) {
      assetTimeSeriesStore.assetTimeSeriesErrors = error.message
    }
  }
  assetTimeSeriesStore.entries = entries

  isUploading.value = false
  router.push({ name: "wc-assets-asset-review-timeseries-upload" })
}
</script>

<style scoped lang="scss">
label span {
  padding: 0;
}

details p {
  @apply mt-2;
}
details summary {
  @apply cursor-pointer;
}
dl {
  @apply mt-2;
}
dd {
  @apply ml-4;
}
code {
  @apply font-mono;
}
</style>
