import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import { authRoutes } from "@/modules/auth/auth.router"
import { adminRoutes } from "@/modules/admin/admin.router"
import { dashboardRoutes } from "@/modules/dashboard/dashboard.router"
import { accountingRoutes } from "@/modules/accounting/accounting.router"
import { accountsRoutes } from "./modules/accounts/accounts.router"
import { vppLoadRoutes } from "@/modules/vpp/load.router"
import { marketRoutes } from "@/modules/market/market.router"
import { suppliersRoutes } from "./modules/suppliers/suppliers.router"
import { orderRoutes } from "./modules/orders/orders.router"
import { portfolioRoutes } from "@/modules/portfolio/portfolio.router"
import { assetRoutes } from "@/modules/asset/asset.router"
import { historyRoutes } from "./modules/history/history.router"
import { eacRoutes } from "./modules/eacs/eacs.router"
import { errorRoutes } from "./modules/errors/errors.router"
import { enterpriseRoutes } from "./modules/enterprise/enterprise.router"

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "wc-landing",
    redirect: { name: "wc-accounts-dashboard" },
  },
  ...errorRoutes,
  ...accountingRoutes,
  ...adminRoutes,
  ...authRoutes,
  ...dashboardRoutes,
  ...marketRoutes,
  ...suppliersRoutes,
  ...vppLoadRoutes,
  ...orderRoutes,
  ...portfolioRoutes,
  ...accountsRoutes,
  ...assetRoutes,
  ...historyRoutes,
  ...eacRoutes,
  ...enterpriseRoutes,
]

const router = createRouter({
  // BASE_URL comes from the `base` config option at build time
  // https://vitejs.dev/guide/env-and-mode.html
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

export default router
