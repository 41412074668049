<template>
  <table class="w-full">
    <thead>
      <tr class="border-b border-neutral-500">
        <th class="text-left">ID</th>
        <th class="text-left">Custom ID</th>
        <th v-if="showAccount" class="text-left">Account</th>
        <th v-if="showPortfolio" class="text-left">Portfolio</th>
        <th class="text-left">Project Registry ID</th>
        <th class="text-left">Status</th>
      </tr>
    </thead>
    <tbody class="divide-y divide-neutral-500">
      <tr v-if="!projects.length">
        <td colspan="6" class="px-4 py-2 text-left">
          <p v-if="!isLoading">No projects.</p>
          <Icon v-else icon="mdi:loading" class="mx-auto my-8 size-20 animate-spin" />
        </td>
      </tr>
      <tr v-for="[project, portfolio] in projectsAndPortfolios" :key="project.id">
        <td class="px-4 py-2 text-left">{{ project.id }}</td>
        <td class="px-4 py-2 text-left">{{ project.customId || "" }}</td>
        <td v-if="showAccount" class="max-w-[20ch] truncate px-4 py-2 text-left underline">
          <router-link :to="{ name: 'wc-admin-account-projects', params: { accountId: project.accountId } }">
            {{ adminAccountsStore.getAccountById(project.accountId)!.name }}
          </router-link>
        </td>
        <td v-if="showPortfolio" class="max-w-[20ch] truncate px-4 py-2 text-left underline">
          <router-link :to="{ name: 'wc-admin-portfolio-projects', params: { portfolioId: portfolio.id } }">
            {{ portfolio.name }}
          </router-link>
        </td>
        <td class="flex items-center gap-2 px-4 py-2 text-left">
          <template v-if="project.projectRegistryId !== null">
            <Icon icon="wc-carbon:checkmark-filled" title="Approved" class="text-perkygreen" />
            {{ project.projectRegistryId }}
          </template>
          <template v-else>
            <Icon icon="wc-carbon:pending-filled" title="Pending approval" class="text-cornyellow" />
            Pending
          </template>
        </td>
        <td class="px-4 py-2 text-left">{{ project.status }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from "vue"
import { useProjectService } from "@/services/service-container"
import { Icon } from "@iconify/vue"
import { Project } from "@common/models/project"
import { useAdminPortfoliosStore } from "@/modules/admin/adminPortfolios.state"
import { useAdminAccountsStore } from "@/modules/admin/adminAccounts.state"
import { ProjectFilter } from "@/services/api/project.service"
import { Portfolio } from "@common/models/order"

const props = defineProps<{
  accountId?: number
  portfolioId?: number
}>()

const projectService = useProjectService()
const adminPortfoliosStore = useAdminPortfoliosStore()
const adminAccountsStore = useAdminAccountsStore()

const showAccount = computed(() => props.accountId === undefined)
const showPortfolio = computed(() => props.portfolioId === undefined)
const projects = ref<Array<Project>>([])
const isLoading = ref<boolean>(false)

watchEffect(async () => {
  const filters = {} as ProjectFilter

  if (props.accountId !== undefined) {
    filters.accountId = props.accountId
  }

  if (props.portfolioId !== undefined) {
    filters.portfolioId = props.portfolioId
  }

  isLoading.value = true
  try {
    projects.value = await projectService.listProjectsAdmin(filters)
  } finally {
    isLoading.value = false
  }
})

const projectsAndPortfolios = computed<Array<[Project, Portfolio]>>(() =>
  projects.value.map((p) => [p, adminPortfoliosStore.getPortfolioByDisplayId(p.desiredPortfolioId)!])
)
</script>

<style scoped lang="scss">
thead th {
  @apply font-normal px-4 py-2;
}
</style>
