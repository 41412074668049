<template>
  <AppPage>
    <AppPageHeader show-account-context>
      {{ store.account.name }}
      <template #title-adjacent>
        <ButtonCTA
          type="router-link"
          :path="{ name: 'wc-accounts-manage' }"
          theme="light"
          :is-filled="false"
          class="ph-no-capture hidden self-center md:block"
          @click="captureManageAccountsEvent"
          >Manage Accounts</ButtonCTA
        >
        <router-link
          class="ph-no-capture text-hyperlink inline-block self-baseline md:hidden"
          :to="{ name: 'wc-accounts-manage' }"
          @click="captureManageAccountsEvent"
          >Manage Accounts</router-link
        >
      </template>
    </AppPageHeader>

    <AppPageContent class="wc-homepage-grid">
      <AppPageSection class="col-span-12 md:col-span-9">
        <AppPageSectionHeader>Your EACs</AppPageSectionHeader>
        <EacBalanceSummary :show-button="true" />
      </AppPageSection>
      <AppPageSection class="col-span-12 md:col-span-3">
        <AppPageSectionHeader>Sold EACs</AppPageSectionHeader>
        <SoldEacsSummary :show-button="true" />
      </AppPageSection>
      <AppPageSection id="transactions" class="col-span-12">
        <AppPageSectionHeader>Recent Transactions</AppPageSectionHeader>
        <AccountTransactionsTable :summary="true" class="overflow-x-scroll" />
      </AppPageSection>

      <AppPageSection v-if="ordersSummary.length > 0" id="portfolios" class="col-span-12">
        <AppPageSectionHeader>Portfolios</AppPageSectionHeader>
        <AccountsPortfoliosTable :portfolios="ordersSummary" class="overflow-x-scroll" />
      </AppPageSection>
    </AppPageContent>
  </AppPage>
</template>

<script setup lang="ts">
// Components
import posthog from "posthog-js"
import { AppPage, AppPageContent, AppPageSection, AppPageHeader, AppPageSectionHeader } from "@/components/layout"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import AccountsPortfoliosTable from "./AccountsPortfoliosTable.vue"
import AccountTransactionsTable from "./components/AccountTransactionsTable.vue"
import EacBalanceSummary from "./components/EacBalanceSummary.vue"
import SoldEacsSummary from "./components/SoldEacsSummary.vue"

// Services
import { useOrdersService } from "@/services/service-container"
import { useMainStore } from "@/store"

const store = useMainStore()

// PostHog Events
const captureManageAccountsEvent = () => posthog.capture("Clicked on the 'Manage Accounts' button - Accounts View")

const ordersService = useOrdersService()
const ordersSummary = await ordersService.getOrdersSummary()
</script>

<style scoped>
.table .table-row-group div[role="row"]:nth-child(2n + 2) {
  @apply bg-blue-100;
}
</style>
