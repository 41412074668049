<template>
  <div class="grid grid-cols-6">
    <div class="col-span-2">
      <div>
        <h3 class="text-overline-2 mb-4 inline-block">Active</h3>
        <ToolTip class="ph-no-capture inline-block" panel-class="bg-white border border-neutral-300">
          The total amount of EACs you own but have not yet retired. Retire to claim against your emissions goals.
        </ToolTip>
      </div>
      <div data-cy="total-active">
        <div class="mb-1">
          <span class="text-featured-number pr-2">
            {{ activeEacs.quantity }}
          </span>
          <span>
            {{ activeEacs.unit }}
          </span>
        </div>
        <div class="text-body-1">
          ({{ getFormattedEacQuantity(accountBalanceSummary.active.wh_electricity?.carbonG || 0, EacMeasurementParameter.CarbonDioxide) }})
        </div>
      </div>
    </div>
    <div class="col-span-2">
      <div>
        <h3 class="text-overline-2 mb-4 inline-block">Retired</h3>
        <ToolTip class="ph-no-capture inline-block" panel-class="bg-white border border-neutral-300">
          The total amount of EACs you have retired (canceled). These can be claimed against your emissions goals.
        </ToolTip>
      </div>
      <div data-cy="total-retired">
        <div class="mb-1">
          <span class="text-featured-number pr-2">
            {{ retiredEacs.quantity }}
          </span>
          <span>
            {{ retiredEacs.unit }}
          </span>
        </div>
        <div class="text-body-1">
          ({{ getFormattedEacQuantity(accountBalanceSummary.retired.wh_electricity?.carbonG || 0, EacMeasurementParameter.CarbonDioxide) }})
        </div>
      </div>
    </div>
    <div class="col-span-2">
      <div>
        <h3 class="text-overline-2 mb-4 inline-block">Procured</h3>
        <ToolTip class="ph-no-capture inline-block" panel-class="bg-white border border-neutral-300">
          The total amount of EACs ordered from suppliers.
        </ToolTip>
      </div>
      <div class="mb-1" data-cy="total-purchased">
        <span class="text-featured-number pr-2">
          {{ totalPurchasedAmountMWh }}
        </span>
        <span> MWh </span>
      </div>
    </div>
  </div>
  <div v-if="props.showButton" class="mt-6">
    <ButtonCTA type="router-link" :to="{ name: 'wc-eacs' }" class="items-stretch" theme="light" :is-filled="true">View & Manage EACs</ButtonCTA>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import ToolTip from "@/components/ui/ToolTip.vue"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import { useRegistryService, useOrdersService } from "@/services/service-container"

// Models
import { EacMeasurementParameter, getFormattedEacQuantity } from "@common/models/order"

const props = defineProps({
  showButton: Boolean,
})

const registryService = useRegistryService()
const accountBalanceSummary = await registryService.getAllTimeBalanceSummary()

const ordersService = useOrdersService()
const ordersSummary = await ordersService.getOrdersSummary()

const totalPurchasedAmountMWh = computed(() => {
  return ordersSummary.filter((row) => row.portfolio.units === "mwh_electricity").reduce((total, row) => total + (row.quantityTotal || 0), 0)
})

const activeEacs = computed(() => {
  const combined = getFormattedEacQuantity(accountBalanceSummary.active.wh_electricity?.count || 0, EacMeasurementParameter.Electricity)
  const [quantity, unit] = combined.split(" ")
  return { quantity, unit }
})
const retiredEacs = computed(() => {
  const combined = getFormattedEacQuantity(accountBalanceSummary.retired.wh_electricity?.count || 0, EacMeasurementParameter.Electricity)
  const [quantity, unit] = combined.split(" ")
  return { quantity, unit }
})
</script>
