// import useFeature from "./useFeature"

// These represent feature flags defined in posthog. Flags should be
// short-lived, existing only during development of a larger feature, or as
// part of an A/B test with a concrete outcome.

/* e.g.
// Tracking removal in WATT-2478 (Deadline: May 21)
// https://wattcarbon.atlassian.net/browse/WATT-2478
export const useMarketplaceStoriesFeature = () => useFeature("marketplace-stories", { valueInDev: true })
*/

import useFeature from "./useFeature"

// Tracking removal in WATT-2712 (Deadline: Sept 1)
// https://wattcarbon.atlassian.net/browse/WATT-2712
export const useAllowUploadAssetTimeSeries = () => useFeature("allow-upload-asset-timeseries", { valueInDev: true })

// Tracking removal in WATT-2847 (Deadline: Sept 12)
// https://wattcarbon.atlassian.net/browse/WATT-2847
export const useEnterprise = () => useFeature("enterprise", { valueInDev: true })
