<template>
  <button class="flex items-center gap-2" :class="classes" :disabled="isDisabled">
    <Icon v-if="icon && iconPosition === 'left'" :icon="icon" aria-hidden="true" :class="iconClasses" :aria-label="icon" />
    {{ text }}
    <Icon v-if="icon && iconPosition === 'right'" :icon="icon" aria-hidden="true" :class="iconClasses" :aria-label="icon" />
  </button>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { Icon } from "@iconify/vue"
import type { ButtonIconPosition } from "./WcButton"

const COLOR_MAPPING = {
  primary: "text-black",
  secondary: "text-neutral",
  neutral: "text-[#040F24]",
}

const HOVER_COLOR_MAPPING = {
  primary: "hover:text-blue-800",
  secondary: "hover:text-[#5B7A80]",
  neutral: "hover:text-[#4F5766]",
}

const SIZE_MAPPING = {
  small: {
    normal: "text-body-3",
    heavy: "text-subheading-3",
  },
  medium: {
    normal: "text-body-2",
    heavy: "text-subheading-2",
  },
  large: {
    normal: "text-body-1",
    heavy: "text-subheading-1",
  },
}

const ICON_SIZE_MAPPING = {
  small: "size-4",
  medium: "size-5",
  large: "size-6",
}

type Props = {
  color?: "primary" | "secondary" | "neutral"
  icon?: string
  iconPosition?: ButtonIconPosition
  isDisabled?: boolean
  size?: "small" | "medium" | "large"
  text?: string
  weight?: "normal" | "heavy"
}

const props = withDefaults(defineProps<Props>(), {
  color: "primary",
  iconPosition: "left",
  isDisabled: false,
  size: "large",
  weight: "normal",
})

const classes = computed(() => {
  const colorClasses = props.isDisabled ? "text-neutral-300" : `${COLOR_MAPPING[props.color]} ${HOVER_COLOR_MAPPING[props.color]}`
  const sizeClass = SIZE_MAPPING[props.size][props.weight]
  const underlineClass = !props.isDisabled ? "hover:underline hover:underline-offset-2" : ""
  return [colorClasses, sizeClass, underlineClass].filter(Boolean).join(" ")
})

const iconClasses = computed(() => {
  return ICON_SIZE_MAPPING[props.size]
})
</script>
