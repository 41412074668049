export class CarbonBasedFuelMix {
  coal = 0
  naturalGas = 0
  other = 0
  oil = 0
  unknown = 0
}

export class CarbonFreeFuelMix {
  hydro = 0
  solar = 0
  wind = 0
  nuclear = 0
}

export class FuelMix {
  carbonBased = new CarbonBasedFuelMix()
  carbonFree = new CarbonFreeFuelMix()
}

export class MarketFuelMix {
  electrification = 0
  load_management_dr = 0
  load_management_ee = 0
  solar = 0
  wind = 0
}

export class LoadshapeRow {
  [key: string]: any

  datetime: Date
  locationalCarbonFreePercent: number
  locationalCarbonIntensityKgCo2PerMwh: number
  locationalCarbonSumKgCo2: number
  locationalEnergySumMwh: number
  locationalNumTimePeriodsAggregated: number
  marketBasedCarbonFreePercent: number
  marketBasedCarbonIntensityKgCo2PerMwh: number
  marketBasedCarbonSumKgCo2: number
  marketBasedEnergySumMwh: number
  marketBasedNumTimePeriodsAggregated: number
  consumedNumValuesAggregated: number
  locationalGridFuelMix: FuelMix
  marketBasedGridFuelMix: FuelMix
  marketBasedVppFuelMix: MarketFuelMix

  constructor(data: any) {
    Object.assign(this, data)
    this.datetime = new Date(data["datetime"])
  }

  public get NetCarbon(): number {
    return this.consumedCarbonSumTonnesCo2e - this.avoidedCarbonSumTonnesCo2e
  }

  public get Year(): number {
    return this.datetime.getFullYear()
  }

  public get Month(): number {
    return this.datetime.getMonth()
  }

  public get MonthLabel(): string {
    return this.datetime.toLocaleString("default", { month: "short" })
  }

  public get Day(): number {
    return this.datetime.getDate()
  }

  public get Hour(): number {
    return this.datetime.getHours()
  }

  public get HourLabel(): string {
    return Loadshape.HourFormatted[this.Hour] ?? ""
  }
}

export class Loadshape {
  [key: string]: any
  public static HourFormatted = [
    "12-1 AM",
    "1-2 AM",
    "2-3 AM",
    "3-4 AM",
    "4-5 AM",
    "5-6 AM",
    "6-7 AM",
    "7-8 AM",
    "8-9 AM",
    "9-10 AM",
    "10-11 AM",
    "11-12 AM",
    "12-1 PM",
    "1-2 PM",
    "2-3 PM",
    "3-4 PM",
    "4-5 PM",
    "5-6 PM",
    "6-7 PM",
    "7-8 PM",
    "8-9 PM",
    "9-10 PM",
    "10-11 PM",
    "11-12 PM",
  ]

  public static GetHourFormatted(hour: number) {
    return this.HourFormatted[hour]
  }

  public static GetEmptyLoadshape(): Loadshape {
    return new Loadshape(new Array<LoadshapeRow>())
  }

  public static GetLoadshape(loadshape: Loadshape) {
    if (!loadshape) {
      return Loadshape.GetEmptyLoadshape()
    }

    loadshape.rows = loadshape.rows.map((h: any) => new LoadshapeRow(h))
    loadshape.carbonRatio =
      loadshape.locationalEnergySumMwh > 0 ? Number((loadshape.locationalCarbonSumKgCo2 / 1000 / loadshape.locationalEnergySumMwh).toFixed(2)) : 0

    return loadshape
  }
  sourceId: number
  sourceType: string
  locationalCarbonSumKgCo2: number
  locationalHourlyCarbonFreePercent: number
  locationalEnergySumMwh: number
  marketBasedCarbonSumKgCo2: number
  marketBasedHourlyCarbonFreePercent: number
  marketBasedEnergySumMwh: number
  sources: Record<string, Array<number>> = {}
  collectionType = "consumed"
  annualCarbonFreePercent = 0
  hourlyCarbonFreePercent = 0
  carbonRatio = 0
  locationalGridFuelMixMwh: FuelMix = new FuelMix()
  marketBasedGridFuelMixMwh: FuelMix = new FuelMix()
  marketBasedVppFuelMixMwh: MarketFuelMix = new MarketFuelMix()
  marketBasedVppFuelMixMwhPercent: any

  rows: Array<LoadshapeRow>
  startDate: Date
  endDate: Date
  constructor(rows: Array<LoadshapeRow>) {
    this.rows = rows.sort((a: LoadshapeRow, b: LoadshapeRow) => +new Date(a.datetime) - +new Date(b.datetime))
  }

  public static getSourceCount(loadshape: Loadshape): number {
    return Object.values(loadshape.sources).reduce((total, sourceIds) => {
      return total + sourceIds.length
    }, 0)
  }

  public static hasAsset(loadshape: Loadshape, id: number): boolean {
    return loadshape.sources.asset?.indexOf(id) > -1 || false
  }
}

export class LoadshapeSummaryRow {
  datetime: Date
  locationalCarbonSumKgCo2: number
  locationalEnergySumMwh: number
  locationalCarbonIntensityKgCo2PerMwh: number
  locationalCarbonFreePercent: number
  locationalNumTimePeriodsAggregated: number

  marketBasedProductionEnergySumMwh: number
  marketBasedProductionCarbonSumKgCo2: number

  marketBasedCarbonSumKgCo2: number
  marketBasedEnergySumMwh: number
  marketBasedCarbonFreePercent: number
  marketBasedCarbonIntensityKgCo2PerMwh: number
  marketBasedNumTimePeriodsAggregated: number

  constructor(data: any) {
    Object.assign(this, data)
    this.datetime = new Date(data["datetime"])
  }

  public get LocationalCarbonAvgKgCo2(): number {
    return Number((this.locationalCarbonSumKgCo2 / this.locationalNumTimePeriodsAggregated).toFixed(4))
  }

  public get LocationalEnergyAvgMwh(): number {
    return Number((this.locationalEnergySumMwh / this.locationalNumTimePeriodsAggregated).toFixed(4))
  }

  public get MarketBasedCarbonAvgKgCo2(): number {
    return Number((this.marketBasedCarbonSumKgCo2 / this.marketBasedNumTimePeriodsAggregated).toFixed(4))
  }

  public get MarketBasedEnergyAvgMwh(): number {
    return Number((this.marketBasedEnergySumMwh / this.marketBasedNumTimePeriodsAggregated).toFixed(4))
  }

  public get Hour(): number {
    return this.datetime.getHours()
  }

  public get HourLabel(): string {
    return Loadshape.HourFormatted[this.Hour] ?? ""
  }
}

export class LoadshapeSummaryBase {
  constructor(data: Array<LoadshapeSummaryRow>) {
    this.data = data.map((r: any) => new LoadshapeSummaryRow(r))
  }

  [key: string]: any
  startDate: Date
  endDate: Date
  hourlyCarbonFreePercent: number
  locationalCarbonSumKgCo2: number
  locationalEnergySumMwh: number
  locationalHourlyCarbonFreePercent: number
  locationalNumTimePeriodsAggregated: number
  marketBasedCarbonSumKgCo2: number
  marketBasedEnergySumMwh: number
  marketBasedHourlyCarbonFreePercent: number
  marketBasedNumTimePeriodsAggregated: number
  data: Array<LoadshapeSummaryRow>

  public get CarbonRatio(): number {
    return this.locationalEnergySumMwh > 0 ? Number((this.locationalCarbonSumKgCo2 / 1000 / this.locationalEnergySumMwh).toFixed(2)) : 0
  }
}

export class LoadshapeSummary extends LoadshapeSummaryBase {
  constructor(data = new Array<LoadshapeSummaryRow>()) {
    super(data)
  }

  public static Get(summary: LoadshapeSummary) {
    if (!summary) {
      return new LoadshapeSummary()
    }
    summary.data = summary.rows.map((r: any) => new LoadshapeSummaryRow(r))
    return summary
  }
}

export class LoadshapeGroupedSummary extends LoadshapeSummaryBase {
  [key: string]: any
  sources: Array<number>

  constructor(data = new Array<LoadshapeSummaryRow>()) {
    super(data)
  }

  public static Get(summary: LoadshapeGroupedSummary) {
    if (!summary) {
      return new LoadshapeGroupedSummary()
    }
    summary.data = summary.rows.map((r: any) => new LoadshapeSummaryRow(r))
    return summary
  }
}
