export enum AccountType {
  individual = "individual",
  organization = "organization",
  system = "system",
}

export interface AccountCreate {
  email: string
  contact_info: AccountContactInfo
}

export class AccountContactInfo {
  name: string
  email: string
  phone: string
  url: string
  street_1: string
  street_2: string
  city: string
  state_province: string
  postal_code: string
  country: string
}

export class Account {
  id: number
  api_key: string
  name: string
  username: string
  updatedTime: Date
  deletedTime: Date
  users: Array<User>
  type: AccountType
  contact_info: AccountContactInfo
  email: string
  logo_url: string
}

export enum AppPortal {
  Marketplace = "Marketplace",
  CarbonAccounting = "Carbon Accounting",
  SupplierPortal = "Supplier Portal",
  AdminPortal = "Admin",
  Accounts = "Accounts",
  Enterprise = "Enterprise",
}

export interface Auth {
  token: string
  user?: User
  isAuthenticated: boolean
  scope?: Array<string>
}

export interface Provider {
  id: number
  key: string
  name: string
  text: string
}

export interface ProviderData {
  datetime: Date
  carbon_intensity: number
  fuel_mix: any
}

export interface MinimalUser {
  id: string
  username: string
  email: string
}

export interface User extends MinimalUser {
  name: string
  token: string
  account_id: number
  roles: Array<string>
}

export interface Coordinates {
  latitude: string
  longitude: string
}
