<template>
  <section>
    <AssetMetadataTable title="Public Metadata" :metadata="details.public" />
    <AssetMetadataTable title="All Metadata" :metadata="details.private" />
    <DemandResponseMetadata v-if="asset.kind === AssetKind.demand_response" :asset="(asset as DemandResponseAsset)" />
    <ElectrificationMetadata v-if="asset.kind === AssetKind.electrification" :asset="(asset as ElectrificationAsset)" />
    <EnergyEfficiencyMetadata v-if="asset.kind === AssetKind.energy_efficiency" :asset="(asset as EnergyEfficiencyAsset)" />
    <SolarMetadata v-if="asset.kind === AssetKind.solar" :asset="(asset as SolarAsset)" />
  </section>
</template>

<script setup lang="ts">
import { computed } from "vue"
import type { Asset, PublicAsset, DemandResponseAsset, ElectrificationAsset, SolarAsset } from "@common/models/asset"
import { ASSET_KIND, AssetKind, EnergyEfficiencyAsset } from "@common/models/asset"
import AssetMetadataTable from "./AssetMetadataTable.vue"
import DemandResponseMetadata from "./DemandResponseMetadata.vue"
import ElectrificationMetadata from "./ElectrificationMetadata.vue"
import EnergyEfficiencyMetadata from "./EnergyEfficiencyMetadata.vue"
import SolarMetadata from "./SolarMetadata.vue"

// This emit is not implemented, but defined here in order to follow the
// interface between AdminAssetView and its tabs
defineEmits(["on-asset-update-state"])
const { asset } = defineProps<{ asset: Asset }>()

const details = computed(() => {
  const publicAsset: PublicAsset = asset

  return {
    // Only things from publicAsset should be here!
    public: {
      ID: publicAsset.id,
      Type: ASSET_KIND[publicAsset.kind],
      State: publicAsset.state,
      Coordinates: `${publicAsset.coordinates.latitude}, ${publicAsset.coordinates.longitude}`,
      Utility: publicAsset.utility,
    },
    private: {
      ID: asset.id,
      Type: ASSET_KIND[asset.kind],
      Created: new Date(asset.createdTime).toLocaleString(),
      Updated: new Date(asset.updatedTime).toLocaleString(),

      Name: asset.name,
      "Custom ID": asset.customId,
      Address: asset.address,
      Provider: asset.provider,
      "Load Zone": asset.loadZone,
      Utility: asset.utility,
      "Device Owner": asset.deviceOwner?.name || "",
      "EAC Owner": asset.eacOwner?.name || "",
      Methodology: asset.methodologyUrl,

      City: asset.city,
      State: asset.state,
      "Zip Code": asset.zipCode,
      Coordinates: `${asset.coordinates.latitude}, ${asset.coordinates.longitude}`,
      "Climate Zone (ASHRAE 2004)": asset.climateZoneAshrae2004,
      "Cambium Region": asset.cambiumRegion,
      "NHGIS County ID": asset.nhgisCountyId,
      "Time Zone": asset.timezone,
    },
  }
})
</script>

<style scoped>
section > * {
  @apply mt-8;
}
</style>
