import { authorizedRoute } from "@/services/auth/authorized.route-guard"
import { RouteRecordRaw } from "vue-router"
import { AppPortal } from "@common/models/models"
import PortfolioLayout from "@/modules/portfolio/PortfolioLayout.vue"
import AccountsEacPositionsView from "../accounts/AccountsEacPositionsView.vue"

export const eacRoutes: Array<RouteRecordRaw> = [
  {
    path: "/eacs",
    name: "wc-eacs",
    component: AccountsEacPositionsView,
    meta: { layout: PortfolioLayout, appPortal: AppPortal.Accounts, title: "EACs" },
    beforeEnter: authorizedRoute,
  },
]
