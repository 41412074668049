<template>
  <table class="w-full">
    <thead>
      <tr class="border-b border-neutral-500">
        <th class="text-left">ID</th>
        <th class="text-left">Date</th>
        <th v-if="showAccount" class="text-left">Buyer</th>
        <th v-if="showPortfolio" class="text-left">Portfolio</th>
        <th class="text-right">Quantity</th>
        <th class="text-right">Total</th>
        <th class="text-right">Status</th>
      </tr>
    </thead>
    <tbody class="divide-y divide-neutral-500">
      <tr v-if="!orders.length">
        <td colspan="7" class="px-4 py-2 text-left">
          <p v-if="!adminOrdersStore.isLoading">No orders.</p>
          <Icon v-else icon="mdi:loading" class="mx-auto my-8 size-20 animate-spin" />
        </td>
      </tr>
      <tr v-for="[order, account] in ordersAndAccounts" :key="order.id">
        <td class="px-4 py-2 text-left">{{ order.id }}</td>
        <td class="px-4 py-2 text-left">{{ formatDate(order.paidTime || order.updatedTime) }}</td>
        <td v-if="showAccount" class="max-w-[20ch] truncate px-4 py-2 text-left underline">
          <router-link v-if="account" :to="{ name: 'wc-admin-account-orders', params: { accountId: account.id } }">
            {{ account.name }}
          </router-link>
        </td>
        <td v-if="showPortfolio" class="max-w-[20ch] truncate px-4 py-2 text-left underline">
          <router-link :to="{ name: 'wc-admin-portfolio-orders', params: { portfolioId: order.portfolio.id } }">
            {{ order.portfolio.name }}
          </router-link>
        </td>
        <td class="px-4 py-2 text-right">{{ order.quantity }}&nbsp;{{ Portfolio.Units(order.portfolio) }}</td>
        <td class="px-4 py-2 text-right">{{ Order.PriceFormatted(order) }}</td>
        <td class="px-4 py-2 text-right">{{ order.status }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useAdminOrdersStore } from "@/modules/admin/adminOrders.state"
import { useAdminAccountsStore } from "@/modules/admin/adminAccounts.state"
import { Icon } from "@iconify/vue"
import { Portfolio, Order } from "@common/models/order"
import { Account } from "@common/models/models"

const props = defineProps<{
  accountId?: number
  portfolioId?: number
}>()

const adminOrdersStore = useAdminOrdersStore()
const adminAccountsStore = useAdminAccountsStore()

const showAccount = computed(() => props.accountId === undefined)
const showPortfolio = computed(() => props.portfolioId === undefined)
const orders = computed(() => {
  let filtered = adminOrdersStore.orders

  if (props.accountId !== undefined) {
    filtered = filtered.filter((o) => o.accountId == props.accountId)
  }

  if (props.portfolioId !== undefined) {
    filtered = filtered.filter((o) => o.portfolio.id == props.portfolioId)
  }

  return filtered
})

const ordersAndAccounts = computed<Array<[Order, Account]>>(() => orders.value.map((o) => [o, adminAccountsStore.getAccountById(o.accountId)!]))

const formatter = new Intl.DateTimeFormat("en", { dateStyle: "short", timeStyle: "short" })
const formatDate = (date: string) => formatter.format(new Date(date))
</script>

<style scoped lang="scss">
thead th {
  @apply font-normal px-4 py-2;
}
</style>
