<template>
  <div v-if="isCertificateVisible" class="fixed inset-0 z-50">
    <div class="fixed inset-0 bg-black opacity-80" @click="emit('close')"></div>
    <div class="wc-page-container pointer-events-none flex min-h-screen w-full items-center justify-center">
      <div class="relative size-full px-2 md:px-0">
        <div class="pointer-events-auto mx-auto w-[540px] max-w-full rounded-lg">
          <div class="mb-4 flex justify-end">
            <button class="text-right" @click="emit('close')">
              <CloseIcon icon-classes="fill-white" />
            </button>
          </div>
          <div class="aspect-square w-full">
            <img class="w-full transition-opacity" :class="{ 'opacity-0': !canvasImage }" :src="canvasImage" />
          </div>
          <div ref="certificateMarkup" class="eac-export fixed left-[200%] top-[200%] mx-auto aspect-square w-[540px] overflow-hidden drop-shadow-md">
            <div class="relative size-full rounded-lg border border-blue-400 bg-gradient-to-b from-blue-100 to-white p-6">
              <img class="mx-auto w-[32px]" src="@/static/img/wattcarbon-logo_dark-32.svg" alt="WattCarbon Logo" />
              <h2 class="text-certificate text-heading-1 -mb-2 -mt-10 text-center">Energy Attribute Certificates</h2>
              <table class="text-body-2 w-full">
                <tr>
                  <th><img src="@/static/img/icons/mini/person.svg" /><span>Owner</span></th>
                  <td>{{ props.accountName }}</td>
                </tr>
                <tr>
                  <th><img src="@/static/img/icons/mini/meter.svg" /><span>Amount</span></th>
                  <td>{{ props.totalAmount.toLocaleString() }} {{ totalAmountUnit }}</td>
                </tr>
                <tr v-if="props.units === 'mwh_electricity'">
                  <th><img src="@/static/img/icons/mini/powerplant.svg" /><span>Carbon Value</span></th>
                  <td>{{ props.carbonValue.toLocaleString() }} grams CO2e</td>
                </tr>
                <tr>
                  <th><img src="@/static/img/icons/mini/battery.svg" /><span>Energy Resources</span></th>
                  <td>{{ props.resourceType }}</td>
                </tr>
                <tr>
                  <th><img src="@/static/img/icons/mini/calendar.svg" /><span>Dispatch Dates</span></th>
                  <td>{{ props.startDate }} to {{ updatedTime }}</td>
                </tr>
                <tr>
                  <th><img src="@/static/img/icons/mini/location.svg" /><span>Location</span></th>
                  <td>{{ props.locations }} (USA)</td>
                </tr>
              </table>
              <div class="my-2 h-[0.5px] w-full bg-blue-400"></div>
              <table class="text-body-2 w-full">
                <tr>
                  <th class="pl-8">Operation Start Date</th>
                  <td>{{ props.startDate }}</td>
                </tr>
                <tr v-if="props.balancingAuthority">
                  <th class="pl-8">Balancing Authority</th>
                  <td>{{ props.balancingAuthority }}</td>
                </tr>
              </table>
              <div class="text-caption absolute bottom-5 left-6 text-left">
                <div class="mb-2 text-[10px]">Last updated {{ props.updatedTime }}</div>
              </div>
              <div class="text-caption absolute bottom-6 right-6 text-right">
                <div class="mb-2 text-[10px]">Issued by</div>
                <img src="@/static/img/wattcarbon_logo_on-light_90.svg" alt="WattCarbon Logo" class="block w-[90px]" />
              </div>
            </div>
          </div>
          <div class="relative z-10 mt-8 flex flex-wrap justify-center gap-4">
            <ButtonCTA class="basis-full sm:basis-auto" theme="dark" :is-filled="true" :is-disabled="!generatedCanvas" @click="saveImage()"
              >Save Image</ButtonCTA
            >
            <ButtonCTA class="basis-full sm:basis-auto" theme="dark" :is-filled="false" @click="emit('clickedReviewEacs')"
              >Review & Retire EACs</ButtonCTA
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, defineExpose, onMounted } from "vue"
import CloseIcon from "@/components/icon/CloseIcon.vue"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import html2canvas from "html2canvas"
const isCertificateVisible = ref(true)
const certificateMarkup = ref<HTMLElement | null>(null)
const generatedCanvas = ref<HTMLCanvasElement | undefined>(undefined)
const canvasImage = ref("")
const props = defineProps({
  accountName: { type: String, default: "" },
  totalAmount: { type: Number, default: 0 },
  carbonValue: { type: Number, default: 0 },
  portfolioName: { type: String, default: "" },
  startDate: { type: String, default: "" },
  updatedTime: { type: String, default: "" },
  resourceType: { type: String, default: "" },
  locations: { type: String, default: "" },
  balancingAuthority: { type: String, default: "" },
  units: { type: String, default: "" },
})

const totalAmountUnit = computed(() => {
  if (props.units === "mwh_electricity") {
    return props.totalAmount > 1 ? "Watt-hours" : "Watt-hour"
  } else {
    return props.totalAmount > 1 ? "grams CO2e" : "gram CO2e"
  }
})

const generateImage = async () => {
  const element = certificateMarkup.value
  if (!element) {
    throw Error("certificateMarkup element is not defined")
  }
  try {
    const canvas = await html2canvas(certificateMarkup.value as HTMLElement, { scale: 2, backgroundColor: null })
    generatedCanvas.value = canvas
    canvasImage.value = canvas.toDataURL("image/png")
  } catch (e) {
    console.error(e)
  }
}

const saveImage = () => {
  if (generatedCanvas.value) {
    const link = document.createElement("a")
    const timestamp = props.updatedTime.replace(",", "").replace(/\s/g, "-")
    link.download = `WattCarbon-EACs_${timestamp}.png`
    link.href = generatedCanvas.value.toDataURL()
    link.click()
  } else {
    console.error("No generated canvas")
  }
}

onMounted(() => {
  setTimeout(() => {
    generateImage()
  }, 500)
})

const emit = defineEmits(["close", "clickedReviewEacs"])
defineExpose({ isCertificateVisible })
</script>

<style scoped lang="scss">
.text-certificate.text-body-1 {
  font-size: 15px;
}
.text-certificate.text-heading-1 {
  font-size: 27px;
}
.text-certificate.text-heading-2 {
  font-size: 21px;
}
.eac-export {
  table {
    tr {
      @apply pb-4;
    }
    th {
      @apply text-blue-400 font-normal text-left w-[180px] align-top;
      img {
        @apply inline-block mr-2 align-[-14px];
      }
      span {
        @apply inline-block mt-0 align-top;
      }
    }
    td {
      @apply text-black align-top pl-4 pb-4;
    }
  }
}
</style>
