<template>
  <Calendar
    v-model="model"
    option-label="label"
    :placeholder="insetLabel"
    :name="name"
    class="w-full"
    :class="inputClass"
    selection-mode="range"
    show-icon
    icon-display="input"
    manual-input
    show-button-bar>
    <template v-if="insetLabel" #header>
      <h4 class="text-subheading-1 m-2 w-full">{{ insetLabel }}</h4>
    </template>
  </Calendar>
</template>

<script setup lang="ts">
import Calendar from "primevue/calendar"
import type { InputSize } from "@/components/input"
import { useInputClass } from "@/components/input"

const model = defineModel<Date[] | null>()
const props = defineProps<{
  insetLabel?: string
  name: string
  size?: InputSize
}>()
const inputClass = useInputClass(props)
</script>
