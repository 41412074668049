<template>
  <div v-if="!!asset || isLoading" class="p-3">
    <Icon v-if="assetId && isLoading" icon="mdi:loading" class="mx-auto my-8 size-20 animate-spin" />
    <div v-if="asset && !isLoading">
      <div>
        <h1 class="text-heading-4 mb-4 max-w-3xl break-words">{{ assetDisplayName }}</h1>
        <AssetSummaryPanel :asset="asset" />
        <div class="mt-4 flex gap-2">
          <ButtonCTA
            button-classes="flex gap-2"
            theme="light"
            is-compact
            is-filled
            :is-disabled="asset.status === AssetStatus.approved"
            @click="handleApproveAsset">
            <Icon icon="wc-carbon:checkmark" />
            Approve
          </ButtonCTA>

          <ButtonCTA
            button-classes="flex gap-2"
            theme="light"
            is-compact
            is-filled
            :is-disabled="asset.status === AssetStatus.changes_requested"
            @click="handleStartRequestChanges">
            <Icon icon="wc-carbon:redo" />
            Request Changes
          </ButtonCTA>

          <ButtonCTA
            button-classes="flex gap-2 review-button"
            theme="light"
            is-compact
            is-filled
            :is-disabled="asset.status === AssetStatus.pending"
            @click="handleChangeToInReview">
            <Icon icon="wc-carbon:pending" />
            Change to In Review
          </ButtonCTA>
        </div>
      </div>

      <nav class="my-4 flex flex-row gap-2 rounded-md bg-neutral-200 p-1">
        <router-link class="tab" :to="{ name: 'wc-admin-asset-metadata' }">Metadata</router-link>
        <router-link class="tab" :to="{ name: 'wc-admin-asset-internal-notes' }">Internal Notes</router-link>
        <router-link class="tab" :to="{ name: 'wc-admin-asset-documents' }">Documents</router-link>
      </nav>

      <router-view :asset="asset" @on-asset-update-state="handleUpdateAssetState"></router-view>
    </div>

    <WcModal
      v-if="!isLoading && asset"
      :is-open="isChangesRequiredDialogOpen"
      size="small"
      :header="`Request changes for ${assetDisplayName}`"
      @update:is-open="(isOpen) => (isChangesRequiredDialogOpen = isOpen)">
      <template #panel>
        <form ref="formRef" @submit.prevent="submitRequestChanges">
          <label>
            Changes requested
            <textarea v-model="reviewNotes" rows="4" required />
          </label>
        </form>
      </template>
      <template #footer>
        <ButtonCTA theme="light" :is-filled="false" @click="isChangesRequiredDialogOpen = false">Cancel</ButtonCTA>
        <ButtonCTA theme="light" :is-filled="true" @click="formRef?.requestSubmit()">Request changes</ButtonCTA>
      </template>
    </WcModal>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from "vue"
import { useToast } from "vue-toastification"
import { Icon } from "@iconify/vue"
import type { Asset } from "@common/models/asset"
import { AssetStatus, getAssetDisplayName } from "@common/models/asset"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import WcModal from "@/components/WcModal.vue"
import AssetSummaryPanel from "@/modules/asset/components/AssetSummaryPanel.vue"
import { useAssetService } from "@/services/service-container"

const assetService = useAssetService()
const toast = useToast()

const emit = defineEmits(["on-asset-status-update"])
const props = defineProps<{ assetId: number | null }>()

const formRef = ref<HTMLFormElement | null>(null)
const asset = ref<Asset | undefined>()
const isChangesRequiredDialogOpen = ref<boolean>(false)
const isLoading = ref<boolean>(true)
const reviewNotes = ref<string>("")
const reviewNotesInternal = ref<string>("")

const assetDisplayName = computed(() => getAssetDisplayName(asset.value))

const loadAssetData = async (assetId: number) => {
  try {
    asset.value = await assetService.getAssetAdmin(assetId)
    reviewNotes.value = asset.value.reviewNotes || ""
    reviewNotesInternal.value = asset.value.reviewNotesInternal || ""
  } catch (error) {
    console.error(`There was an error loading asset ${assetId}`, error)
  }
  isLoading.value = false
}

watchEffect(async () => {
  // The asset list endpoint doesn't include all asset info so we need to fetch
  // additional details from the getAssetAdmin endpoint
  if (props.assetId && !isNaN(props.assetId)) {
    isLoading.value = true
    loadAssetData(props.assetId)
  }
})

const submitAssetReview = async (reviewProps: { status?: AssetStatus; reviewNotesInternal?: string; reviewNotesExternal?: string }) => {
  if (!props.assetId) {
    return
  }
  // Merge inputs with existing state before sending the update to the API
  const review = {
    status: asset.value!.status,
    reviewNotesInternal: reviewNotesInternal.value,
    reviewNotesExternal: reviewNotes.value,
    ...reviewProps,
  }
  await assetService.submitAssetReview(props.assetId, review)
  await loadAssetData(props.assetId)
}

const handleApproveAsset = async () => {
  try {
    await submitAssetReview({ status: AssetStatus.approved, reviewNotesExternal: "" })
    emit("on-asset-status-update", AssetStatus.approved)
    toast.success(`Approved ${assetDisplayName.value}`, { timeout: 3000 })
  } catch (error) {
    const message = `There was a problem approving ${assetDisplayName.value}`
    if (error instanceof Error) {
      toast.error(`${message}\n${error.message}`)
    }
    console.error(message, error)
  }
}

const handleStartRequestChanges = () => {
  isChangesRequiredDialogOpen.value = true
}

const handleChangeToInReview = async () => {
  try {
    await submitAssetReview({ status: AssetStatus.pending })
    emit("on-asset-status-update", AssetStatus.pending)
    toast.success(`Changed ${assetDisplayName.value} to In review`, { timeout: 3000 })
  } catch (error) {
    const message = `There was a problem changing ${assetDisplayName.value} to In review`
    if (error instanceof Error) {
      toast.error(`${message}\n${error.message}`)
    }
    console.error(message, error)
  }
}

const handleUpdateAssetState = (updatedAsset: Partial<Asset>) => {
  if (updatedAsset.reviewNotesInternal !== undefined) {
    reviewNotesInternal.value = updatedAsset.reviewNotesInternal
  }
  asset.value = {
    ...asset.value,
    ...updatedAsset,
  } as Asset
}

const submitRequestChanges = async () => {
  if (!props.assetId) {
    return
  }
  try {
    await submitAssetReview({
      status: AssetStatus.changes_requested,
      reviewNotesExternal: reviewNotes.value,
    })
    reviewNotes.value = ""
    isChangesRequiredDialogOpen.value = false
    emit("on-asset-status-update", AssetStatus.changes_requested)
    toast.success(`Changes requested for ${assetDisplayName.value}`, { timeout: 3000 })
  } catch (error) {
    const message = `There was a problem requesting changes for ${assetDisplayName.value}`
    if (error instanceof Error) {
      toast.error(`${message}\n${error.message}`)
    }
    console.error(message, error)
  }
}
</script>

<style scoped lang="scss">
.tab {
  @apply px-4 py-2 rounded-md;
}

.router-link-active {
  @apply bg-neutral-300;
}
</style>
