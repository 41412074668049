import { mapSite, Site } from "@common/models/site"
import ApiFetcher from "@/services/api-fetcher"
import { SiteAsset, SiteAssetRequest } from "@common/models/siteAsset"
import { Provider } from "@common/models/models"
import { TYPE, useToast } from "vue-toastification"

export default class SiteService {
  fetcher: ApiFetcher
  constructor(fetcher: ApiFetcher) {
    this.fetcher = fetcher
  }
  async listSites(accountId = 0): Promise<Array<Site>> {
    const endpoint = "/sites"
    let params = {}

    if (accountId > 0) {
      params = { accountId: accountId }
    }
    try {
      const result = await this.fetcher.httpGet<Array<Site>>(endpoint, params)
      return new Promise<Array<Site>>((resolve) => {
        resolve(result.map(mapSite))
      })
    } catch (error) {
      console.log(error)
      return new Promise<any>((resolve, reject) => {
        reject(error)
      })
    }
  }

  async getSite(siteId = 0) {
    const endpoint = `/sites/${siteId}`
    const params = {}
    try {
      const site = await this.fetcher.httpGet<Site>(endpoint, params)
      return new Promise<Site>((resolve) => {
        resolve(site)
      })
    } catch (error) {
      return new Promise<Site>((resolve, reject) => {
        reject(error)
      })
    }
  }
  async getProviders(postalCode: string): Promise<Array<Provider>> {
    const endpoint = `/providers`
    const params = {
      postalCode,
    }
    try {
      const providers = await this.fetcher.httpGet<Array<Provider>>(endpoint, params)
      return new Promise<Array<Provider>>((resolve) => {
        resolve(providers)
      })
    } catch (error) {
      return new Promise<Array<Provider>>((resolve, reject) => {
        reject(error)
      })
    }
  }

  async geocode(street: string, city: string, state: string, postalCode: string, country: string) {
    const endpoint = `/sites/geocode`
    const params = {
      street: street,
      city: city,
      state: state,
      postalCode: postalCode,
      country: country ?? "",
    }
    try {
      const site = await this.fetcher.httpGet<Site>(endpoint, params)
      return new Promise<Site>((resolve) => {
        resolve(site)
      })
    } catch (error) {
      return new Promise<Site>((resolve, reject) => {
        reject(error)
      })
    }
  }
  // async save(site: Site): Promise<Site> {
  //   const toast = useToast()
  //   const toastId = toast(`Saving "${site.name}"…`, { id: `site:${site.id}`, type: TYPE.DEFAULT, timeout: false })
  //
  //   try {
  //     const updatedSite = Object.assign({}, site)
  //     const savedSite = await this.service.updateSite(updatedSite)
  //     this.site = savedSite
  //     this.isDirty = true
  //     toast.update(toastId, { content: `Saved "${site.name}"!`, options: { type: TYPE.SUCCESS, timeout: 1000 } })
  //     return savedSite
  //   } catch (error) {
  //     toast.update(toastId, { content: `Error while saving "${site.name}": ${error}`, options: { type: TYPE.ERROR } })
  //     throw error
  //   }
  // },

  async updateSite(site: Site) {
    const toast = useToast()
    const toastId = toast(`Saving "${site.name}"…`, { id: `site:${site.id}`, type: TYPE.DEFAULT, timeout: false })

    try {
      const updatedSite = Object.assign({}, site)
      const endpoint = site.id ? `/sites/${site.id}` : `/sites`
      const action = !site.id || site.id === 0 ? this.fetcher.httpPost : this.fetcher.httpPut
      const savedSite = await action<Site>(endpoint, updatedSite)
      // this.site = savedSite
      // this.isDirty = true
      toast.update(toastId, { content: `Saved "${site.name}"!`, options: { type: TYPE.SUCCESS, timeout: 1000 } })
      return savedSite
    } catch (error) {
      toast.update(toastId, { content: `Error while saving "${site.name}": ${error}`, options: { type: TYPE.ERROR } })
      throw error
    }
  }

  async deleteSite(siteId: number) {
    const endpoint = `/sites/${siteId}`
    await this.fetcher.httpDelete(endpoint)
  }

  async getAsset(assetId = 0) {
    const endpoint = `/assets/${assetId}`
    const params = {}
    return await this.fetcher.httpGet<SiteAsset>(endpoint, params)
  }
  async saveAsset(asset: SiteAsset): Promise<SiteAsset> {
    const toast = useToast()
    const toastId = toast(`Saving "${asset.name}"…`, { id: `asset:${asset.id}`, type: TYPE.DEFAULT, timeout: false })

    try {
      const _asset = Object.assign({}, asset) as SiteAssetRequest
      const endpoint = `/assets`
      const action = !asset.id || asset.id === 0 ? this.fetcher.httpPost : this.fetcher.httpPut
      const savedAsset = await action<SiteAsset>(endpoint, _asset)
      toast.update(toastId, { content: `Saved "${asset.name}"!`, options: { type: TYPE.SUCCESS, timeout: 1000 } })
      return savedAsset
    } catch (error) {
      toast.update(toastId, { content: `Error while saving "${asset.name}": ${error}`, options: { type: TYPE.ERROR } })
      throw error
    }
  }

  async deleteAsset(asset: SiteAsset) {
    const toast = useToast()
    const toastId = toast(`Deleting "${asset.name}"…`, { id: `asset:${asset.id}`, type: TYPE.DEFAULT, timeout: false })

    try {
      const endpoint = `/assets/${asset.id}`

      await this.fetcher.httpDelete(endpoint)
      toast.update(toastId, { content: `Deleted asset.`, options: { type: TYPE.SUCCESS, timeout: 1000 } })
      return
    } catch (error: any) {
      toast.update(toastId, { content: `Error while deleting "${asset.name}": ${error}`, options: { type: TYPE.ERROR } })
      throw error
    }
  }
}
