<template>
  <AppPageSection>
    <AppPageSectionHeader class="pb-9">
      EAC Inventory
      <template #title-adjacent>
        <div class="flex items-center gap-4">
          <div class="text-subheading-1">Accounts</div>
          <SelectSingleAccount v-model="filterAccount" class="min-w-[12.5rem]" name="accounts" required size="small" allow-select-all />
        </div>
      </template>
    </AppPageSectionHeader>
    <div v-if="scopes && scopes[1] && scopes[2] && scopes[3]">
      <div class="text-subheading-3 inventory-header-row grid grid-cols-8">
        <EacInventoryHeaderCell />
        <EacInventoryHeaderCell>Consumed EACs</EacInventoryHeaderCell>
        <!-- TODO: Replace these placeholders with the actual tooltip copy -->
        <EacInventoryHeaderCell
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
          >Avoided Emissions</EacInventoryHeaderCell
        >
        <EacInventoryHeaderCell>Purchased</EacInventoryHeaderCell>
        <EacInventoryHeaderCell
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
          >Sold</EacInventoryHeaderCell
        >
        <EacInventoryHeaderCell>Net Balance</EacInventoryHeaderCell>
        <EacInventoryHeaderCell>Retired</EacInventoryHeaderCell>
        <EacInventoryHeaderCell>Available To Sell</EacInventoryHeaderCell>
      </div>
      <div class="text-body-3 inventory-row grid grid-cols-8">
        <EacInventoryCell class="text-subheading-3">
          Scope 1
          <img width="20" height="30" class="-mb-9 pt-1.5" src="@/static/img/icons/mini/scope1.svg" />
        </EacInventoryCell>
        <EacInventoryCell :value="scopes[1].consumption" :unit="GHG_EMISSIONS" />
        <EacInventoryCell
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
          :value="scopes[1].generation"
          :unit="GHG_EMISSIONS" />
        <EacInventoryCell />
        <EacInventoryCell />
        <EacInventoryCell class="text-subheading-3" :value="netEmissionsForScope(1)" :unit="GHG_EMISSIONS" />
        <EacInventoryCell :value="scopes[1].retired" :unit="GHG_EMISSIONS" />
        <EacInventoryCell />
      </div>
      <div class="text-body-3 inventory-row grid grid-cols-8">
        <EacInventoryCell class="text-subheading-3">
          Scope 2
          <img width="40" height="20" class="-mb-9 pt-4" src="@/static/img/icons/mini/scope2.svg" />
        </EacInventoryCell>
        <EacInventoryCell :value="scopes[2].consumption" :unit="ELECTRICITY" />
        <EacInventoryCell :value="scopes[2].generation" :unit="ELECTRICITY" />
        <EacInventoryCell :value="scopes[2].purchased" :unit="ELECTRICITY" />
        <EacInventoryCell :value="scopes[2].sold" :unit="ELECTRICITY" />
        <EacInventoryCell class="text-subheading-3" :value="netEmissionsForScope(2)" :unit="ELECTRICITY" />
        <EacInventoryCell :value="scopes[2].retired" :unit="ELECTRICITY" />
        <EacInventoryCell :value="scopes[2].available" :unit="ELECTRICITY" />
      </div>
      <div class="text-body-3 inventory-row grid grid-cols-8">
        <EacInventoryCell class="text-subheading-3">
          Scope 3
          <img width="40" height="24" class="-mb-9 pt-3" src="@/static/img/icons/mini/scope3.svg" />
        </EacInventoryCell>
        <EacInventoryCell :value="scopes[3].consumption" :unit="GHG_EMISSIONS" />
        <EacInventoryCell :value="scopes[3].generation" :unit="GHG_EMISSIONS" />
        <EacInventoryCell :value="scopes[3].purchased" :unit="GHG_EMISSIONS" />
        <EacInventoryCell :value="scopes[3].sold" :unit="GHG_EMISSIONS" />
        <EacInventoryCell class="text-subheading-3" :value="netEmissionsForScope(3)" :unit="GHG_EMISSIONS" />
        <EacInventoryCell :value="scopes[3].retired" :unit="GHG_EMISSIONS" />
        <EacInventoryCell :value="scopes[3].available" :unit="GHG_EMISSIONS" />
      </div>
    </div>
  </AppPageSection>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue"
import { EacMeasurementParameter } from "@common/models/order"
import type { SelectOption } from "@/components/input"
import { AppPageSection, AppPageSectionHeader } from "@/components/layout"
import SelectSingleAccount from "@/modules/accounts/components/SelectSingleAccount.vue"
import { useRegistryService } from "@/services/service-container"
import type { ScopeSummary } from "@/services/api/registry.service"
import EacInventoryHeaderCell from "./components/EacInventoryHeaderCell.vue"
import EacInventoryCell from "./components/EacInventoryCell.vue"

const ELECTRICITY = EacMeasurementParameter.Electricity
const GHG_EMISSIONS = EacMeasurementParameter.GhgEmissions

const registryService = useRegistryService()

const scopes = ref<Record<number, ScopeSummary>>({})
const filterAccount = ref<SelectOption<number | "all">>({ label: "All", value: "all" })

const loadScopesInventory = async () => {
  try {
    const result = await registryService.getAllTimeBalanceScoped(filterAccount.value.value)
    scopes.value = result.scopes
  } catch (error) {
    console.error("There was an error loading inventory data", error)
  }
}

watch([filterAccount], async () => {
  await loadScopesInventory()
})

onMounted(async () => {
  await loadScopesInventory()
})

const netEmissionsForScope = (scope: number) => {
  const scopeData = scopes.value[scope]
  if (!scopeData) return 0
  return scopeData.consumption - scopeData.generation + scopeData.purchased - scopeData.sold
}
</script>

<style lang="scss">
// Round the corners on the top-left, top-right, bottom-left, and bottom-right cells
.inventory-header-row > div:first-child {
  @apply rounded-tl-xl;
}
.inventory-header-row > div:last-child {
  @apply rounded-tr-xl;
}
.inventory-row:last-of-type > div:first-child {
  @apply rounded-bl-xl;
}
.inventory-row:last-of-type > div:last-child {
  @apply rounded-br-xl;
}

// Add dashed borders between cells in the table
.inventory-row:not(:last-of-type) > div {
  @apply border-b border-neutral-300 border-dashed;
}
.inventory-row,
.inventory-header-row {
  > div:not(:first-of-type) {
    @apply border-l border-neutral-300 border-dashed;
  }
}

// Separate the Retired and Available to Sell columns from the rest of the table with whitespace
.inventory-row,
.inventory-header-row {
  > div:nth-child(6) {
    @apply border-l-[#F1F514] border-l-2 mr-1;
    border-left-style: solid;
  }
  > div:nth-child(7) {
    @apply border-l-0;
    @apply ml-1;
  }
}
</style>
