<template>
  <footer class="fixed flex w-full items-center justify-between bg-neutral-900 px-2 py-1">
    <span class="inline-block text-white">Build #{{ version }}</span>
    <img src="@/static/img/logo_60.svg" class="h-6 pr-2" alt="WattCarbon" />
  </footer>
</template>

<script lang="ts" setup>
// eslint-disable-next-line no-undef
const version = __APP_VERSION__
</script>

<style scoped lang="scss">
footer {
  min-height: 24px;
}
</style>
