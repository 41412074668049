<template>
  <a v-if="type === 'anchor'" ref="button" :href="href" :class="outerClasses" :target="openInNewTab ? '_blank' : ''">
    <span :class="innerClasses">
      <slot />
    </span>
  </a>
  <router-link v-else-if="type === 'router-link'" :to="routerTo" :class="outerClasses">
    <span :class="innerClasses">
      <slot />
    </span>
  </router-link>
  <button v-else-if="type === 'button' || type === 'submit'" ref="button" :class="outerClasses" :type="type" :disabled="isDisabled">
    <span :class="innerClasses">
      <slot />
    </span>
  </button>
</template>

<script lang="ts" setup>
import { computed } from "vue"

const props = withDefaults(
  defineProps<{
    type?: string
    width?: string
    path?: string | object
    href?: string
    openInNewTab?: boolean
    buttonClasses?: string
    isCompact?: boolean
    isDisabled?: boolean
    isFilled?: boolean
    theme?: "dark" | "light"
  }>(),
  {
    type: "button",
    openInNewTab: false,
    isDisabled: false,
    isCompact: false,
    isFilled: false,
  }
)

const themeClasses = computed(() => {
  if (props.theme === "dark" && props.isFilled === true) return "bg-highlight hover:bg-blue-300 text-blue-500 hover:text-white"
  else if (props.theme === "dark" && props.isFilled === false) return "border border-highlight hover:border-white text-highlight hover:text-white"
  else if (props.theme === "light" && props.isFilled === true) return "bg-blue-500 hover:bg-blue-300 text-white hover:text-white"
  else if (props.theme === "light" && props.isFilled === false)
    return "border border-blue-500 hover:border-blue-300 text-blue-500 hover:text-blue-300"
  else return ""
})

const outerClasses = computed(() => {
  return `inline-flex justify-center items-center flex-col shrink-0 relative ${props.width === "fixed" ? " w-200 md:w-[33.5rem]" : ""}${
    props.width === "fixed-large-mobile" ? " w-[28rem] md:w-[33.5rem]" : ""
  }${props.width === "full" ? " w-full" : ""}${props.isDisabled ? " opacity-25" : ""}`
})
const innerClasses = computed(() => {
  const padding = props.isCompact ? "px-4 py-2" : "px-5 py-3"
  const text = props.isCompact ? "text-button-small" : "text-button-large"
  return `tracking-wider rounded flex-1 flex justify-center items-center text-blue-500 w-full text-center ${text} ${padding} transition-colors ${
    props.buttonClasses
  } ${props.isDisabled ? "" : "cursor-pointer"} ${themeClasses.value}`
})

const routerTo = computed(() => {
  return props.path ?? ""
})
</script>
