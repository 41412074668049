<template>
  <Multiselect
    v-model="model"
    :name="name"
    :option-group-label="hasGroups ? 'label' : undefined"
    :option-group-children="hasGroups ? 'items' : undefined"
    option-label="label"
    option-value="value"
    :options="options"
    :placeholder="allSelectedLabel"
    class="w-full"
    :class="inputClass"
    :scroll-height="scrollHeight"
    :show-clear="!required"
    :show-toggle-all="false">
    <template #value><slot name="value"></slot></template>
    <template #header>
      <div class="mb-1 mt-5 border-b border-neutral-200 px-5">
        <h4 class="text-subheading-1 p-dropdown-item">{{ header }}</h4>
        <div class="align-items-center mb-2 mt-4 flex">
          <Checkbox v-model="isAllSelected" input-id="allSelected" name="allSelected" :binary="true" @click="() => (model = [])" />
          <label for="allSelected" class="ml-2 cursor-pointer text-base"> {{ allSelectedLabel }} </label>
        </div>
      </div>
    </template>
  </Multiselect>
</template>

<script setup lang="ts" generic="T">
import { computed } from "vue"
import Checkbox from "primevue/checkbox"
import Multiselect from "primevue/multiselect"
import type { InputSize } from "@/components/input"
import { useInputClass } from "@/components/input"
import type { SelectOption, SelectOptionGroup } from "./WcDropdown"

const model = defineModel<T[] | null>()
const props = defineProps<{
  allSelectedLabel?: string
  header?: string
  name: string
  options: SelectOption<T>[] | SelectOptionGroup<T>[]
  required?: boolean
  size?: InputSize
  scrollHeight?: string
}>()

const inputClass = useInputClass(props)
const isAllSelected = computed(() => (model.value?.length ?? 0) === 0)
const hasGroups = computed(() => (props.options ?? []).some((option) => !!(option as SelectOptionGroup<T>).items))
</script>
