<template>
  <div ref="viewContainer" class="w-full">
    <div class="wc-page-container">
      <StorySearchHeader class="py-12">
        <template #quantity>{{ totalEacs }} ({{ totalGco2 }})</template>
        <template #quantity-subtitle>available based on search filters</template>
      </StorySearchHeader>
      <div class="wc-homepage-grid md:min-h-screen">
        <div class="col-span-6 border-t border-neutral-300 pb-8 pt-12 md:col-span-7 md:pb-20">
          <InlineError
            v-if="hasError"
            class="mx-auto max-w-main-content"
            error-message="There was a problem loading EAC listings. Please try again." />
          <InlineLoading v-if="isLoading" />
          <div v-else-if="!stories?.length" class="rounded-lg bg-blue-100 p-6">
            <div class="flex items-center gap-2">
              <div
                class="text-body-2-strong mt-0.5 flex aspect-square size-6 items-center justify-center rounded-full border-2 border-blue-400 pt-0.5 text-blue-400">
                i
              </div>
              <h3 class="text-heading-6">No EACs found</h3>
            </div>
            <p class="text-body-3 ml-3 mt-4">
              Try adjusting your filters or
              <button class="underline hover:text-blue-300" @click="openContactForm">contact us</button>
              — we are adding more supply every day.
            </p>
          </div>
          <div v-else class="flex flex-col gap-5">
            <StorySearchResultCard
              v-for="story in stories"
              :key="story.id"
              :story="story"
              :quantity="{ energy: story.totalEacs, carbon: story.totalGco2 }" />
          </div>
        </div>
        <div id="map-grid-slot" class="sticky top-0 col-span-5 -ml-5 h-[320px] w-screen md:ml-0 md:h-screen md:w-auto">
          <div ref="mapContainer" class="relative h-full max-w-[680px]">
            <WcMap :locations="locations" />
            <div class="text-body-1 absolute left-6 top-6 rounded-lg bg-white p-4 text-blue-400">
              <strong>{{ totalDevices }}</strong> assets
            </div>
          </div>
        </div>
      </div>
    </div>
    <ContactUsForm
      ref="contactUsForm"
      client:load
      :form-context="`Search view - No EACs found`"
      title="Contact us"
      success-message="We'll contact you shortly." />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from "vue"
import { useRoute } from "vue-router"
import ContactUsForm from "@/components/ContactUsForm.vue"
import StorySearchHeader from "@/modules/market/components/StorySearchHeader.vue"
import StorySearchResultCard from "@/modules/market/components/StorySearchResultCard.vue"
import WcMap from "@/components/WcMap/WcMap.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import InlineError from "@/components/ui/InlineError.vue"
import { MarketplaceStory } from "@common/models/story"
import { useListingService } from "@/services/service-container"
import { useMarketStore } from "./market.state"
import { debounce } from "@/utils/debounce"
import { EacMeasurementParameter, getFormattedEacQuantity } from "@common/models/order"
import { Coordinates } from "@common/models/models"
import { parseInitialDispatchDates, parseInitialLocations, parseInitialResourceTypes } from "./components/StorySearchHeader.utils"
import posthog from "posthog-js"

const listingService = useListingService()
const marketStore = useMarketStore()

// Filters are set from query params if there are no active filters already set
const route = useRoute()
if (!marketStore.hasActiveFilters) {
  marketStore.setListingFilters({
    deviceKind: parseInitialResourceTypes(route.query),
    quarter: parseInitialDispatchDates(route.query),
    state: parseInitialLocations(route.query),
  })
}

const stories = ref<MarketplaceStory[]>()
const hasError = ref<boolean>(false)
const isLoading = ref<boolean>(true)
const loadStories = async () => {
  try {
    isLoading.value = true
    hasError.value = false
    const data = await listingService.getAllListings({ ...marketStore.listingFilterParams })
    stories.value = data.stories
  } catch (error) {
    hasError.value = true
    console.error("There was an error loading stories", error)
  }
  isLoading.value = false
}
const debouncedLoadStories = debounce(loadStories, 2000)
marketStore.$subscribe(() => {
  debouncedLoadStories()
})

const totalEacs = computed(() => {
  if (!stories.value) return
  const total = stories.value.reduce((acc, story) => acc + story.totalEacs, 0)
  return getFormattedEacQuantity(total, EacMeasurementParameter.Electricity, 1)
})
const totalGco2 = computed(() => {
  if (!stories.value) return
  const total = stories.value.reduce((acc, story) => acc + story.totalGco2, 0)
  return getFormattedEacQuantity(total, EacMeasurementParameter.CarbonDioxide, 1)
})
const totalDevices = computed(() => {
  if (!stories.value) return
  return stories.value.reduce((acc, story) => acc + story.totalDevices, 0)
})
const locations = computed(() => {
  if (!stories.value) return [] as Coordinates[]
  return stories.value.reduce((acc, story) => acc.concat(story.coordinates ?? []), [] as Coordinates[])
})

// Map Sizing
const viewContainer = ref<HTMLElement | null>(null)
const mapContainer = ref<HTMLElement | null>(null)

// Make the map full-bleed on the right side of the page
function initializeMapContainerWidth() {
  updateMapContainerWidth()
  window.dispatchEvent(new Event("resize")) // Trigger an initial resize for Mapbox. Mapbox automatically listens to resize events going forward.
  new ResizeObserver(() => {
    updateMapContainerWidth()
  }).observe(document.body) // Resize the map container when the window is resized
}

function updateMapContainerWidth() {
  const mapGridSlotDOM = document.getElementById("map-grid-slot")
  if (mapGridSlotDOM && viewContainer.value && mapContainer.value) {
    const rect = mapGridSlotDOM.getBoundingClientRect()
    // Measure the width from the viewContainer (not Window) to exclude the scrollbar for mouse users
    const distanceToRightEdge = viewContainer.value.clientWidth - rect.right
    const newMapWidth = rect.width + distanceToRightEdge
    mapContainer.value.style.width = `${newMapWidth}px`
  }
}

onMounted(async () => {
  await loadStories()
  initializeMapContainerWidth()
})

const contactUsForm = ref()
const openContactForm = () => {
  posthog.capture("Clicked on Contact Us from the Search View")
  contactUsForm.value.openContactForm()
}
</script>
