<template>
  <header class="relative z-40 w-full border-b border-neutral-300 bg-white dark:bg-blue-500">
    <nav
      class="wc-page-container flex w-full max-w-[1440px] flex-wrap items-center justify-between gap-x-10 gap-y-2 lg:flex-nowrap"
      :class="{ spoof: store.isSpoof }">
      <!-- Logo (mobile) -->
      <a :href="WWW_BASE_URL" class="ph-no-capture order-1 flex shrink-0 cursor-pointer justify-start lg:mt-0 lg:hidden" @click="captureHomeEvent">
        <img src="@/static/img/wattcarbon_logo_on-light.svg" alt="WattCarbon" class="h-[30px] pr-2 dark:hidden" />
      </a>
      <div
        class="text-body-2 order-3 basis-full lg:order-2 lg:basis-auto"
        :class="{ 'hidden lg:block': route.meta.appPortal == AppPortal.Marketplace }">
        <ul class="flex items-center justify-center gap-5 lg:gap-12">
          <!-- Logo (Desktop) -->
          <a :href="WWW_BASE_URL" class="ph-no-capture hidden shrink-0 cursor-pointer justify-start lg:mt-0 lg:flex" @click="captureHomeEvent">
            <img src="@/static/img/wattcarbon_logo_on-light.svg" alt="WattCarbon" class="h-[30px] pr-2 dark:hidden" />
          </a>

          <!-- Carbon Accounting -->
          <li v-if="route.meta.appPortal == AppPortal.CarbonAccounting" :class="getMenuClass(['wc-load'])">
            <router-link to="/load" class="ph-no-capture py-3" aria-current="page" @click="captureEvent">Load</router-link>
          </li>
          <li v-if="route.meta.appPortal == AppPortal.CarbonAccounting" :class="getMenuClass(['wc-accounting'])">
            <router-link to="/accounting" class="ph-no-capture py-3" @click="captureEvent">Accounting</router-link>
          </li>

          <!-- Accounts -->
          <li v-if="route.meta.appPortal == AppPortal.Accounts" :class="getMenuClass(['wc-accounts'])">
            <router-link to="/accounts" class="ph-no-capture py-3" @click="captureEvent">Dashboard</router-link>
          </li>
          <li v-if="route.meta.appPortal == AppPortal.Accounts" :class="getMenuClass(['wc-assets'])">
            <router-link to="/assets" class="ph-no-capture py-3" @click="captureEvent">Assets</router-link>
          </li>
          <li v-if="route.meta.appPortal == AppPortal.Accounts" :class="getMenuClass(['wc-eacs'])">
            <router-link to="/eacs" class="ph-no-capture py-3" @click="captureEvent">EACs</router-link>
          </li>
          <li v-if="route.meta.appPortal == AppPortal.Accounts" :class="getMenuClass(['wc-history'])">
            <router-link to="/history" class="ph-no-capture py-3" @click="captureEvent">History</router-link>
          </li>

          <!-- Marketplace -->
          <li v-if="route.meta.appPortal == AppPortal.Marketplace">
            <a :href="WWW_BASE_URL" class="ph-no-capture py-3" @click="captureMarketplaceEvent">Marketplace</a>
          </li>
          <li
            v-if="route.meta.appPortal == AppPortal.Marketplace"
            class="hover:text-black"
            @mouseover="showHeaderDropdowns(HeaderDropdowns.Resources)"
            @mouseleave="hideHeaderDropdowns">
            <div class="text-body-2 flex cursor-pointer items-center gap-2">
              Resources
              <ChevronIcon class="transition-transform" :class="currentHeaderDropdown === HeaderDropdowns.Resources ? 'rotate-180' : 'rotate-0'" />
            </div>
            <transition name="fade">
              <div
                v-if="currentHeaderDropdown === HeaderDropdowns.Resources"
                class="absolute -mx-4 flex w-36 flex-col items-start gap-4 bg-white px-4 py-2">
                <ButtonNav
                  theme="light"
                  type="anchor"
                  :href="`${WWW_BASE_URL}/weats`"
                  class="ph-no-capture"
                  button-classes="text-body-2"
                  @click="captureEvent"
                  >Registry</ButtonNav
                >
                <ButtonNav
                  theme="light"
                  type="anchor"
                  :href="`${WWW_BASE_URL}/faq`"
                  class="ph-no-capture"
                  button-classes="text-body-2"
                  @click="captureEvent"
                  >FAQ</ButtonNav
                >
                <ButtonNav
                  theme="light"
                  type="anchor"
                  href="https://blog.wattcarbon.com"
                  class="ph-no-capture"
                  button-classes="text-body-2"
                  open-in-new-tab="true"
                  @click="captureEvent"
                  >Blog</ButtonNav
                >
              </div>
            </transition>
          </li>

          <!-- Enterprise -->
          <li v-if="route.meta.appPortal == AppPortal.Enterprise" :class="getMenuClass(['wc-enterprise'])">
            <router-link to="/enterprise-dashboard" class="ph-no-capture py-3" @click="captureEvent">Dashboard</router-link>
          </li>
          <li v-if="route.meta.appPortal == AppPortal.Enterprise" :class="getMenuClass(['wc-assets'])">
            <a :href="WWW_BASE_URL" class="ph-no-capture py-3" @click="captureMarketplaceEvent">OpenEAC Exchange</a>
          </li>
          <li v-if="route.meta.appPortal == AppPortal.Enterprise">
            <a :href="`${WWW_BASE_URL}/weats`" class="ph-no-capture py-3" @click="captureMarketplaceEvent">WEATS Registry</a>
          </li>
          <li
            v-if="route.meta.appPortal == AppPortal.Enterprise"
            class="hover:text-black"
            @mouseover="showHeaderDropdowns(HeaderDropdowns.Resources)"
            @mouseleave="hideHeaderDropdowns">
            <div class="text-body-2 flex cursor-pointer items-center gap-2">
              Resources
              <ChevronIcon class="transition-transform" :class="currentHeaderDropdown === HeaderDropdowns.Resources ? 'rotate-180' : 'rotate-0'" />
            </div>
            <transition name="fade">
              <div
                v-if="currentHeaderDropdown === HeaderDropdowns.Resources"
                class="absolute -mx-4 flex w-36 flex-col items-start gap-4 bg-white px-4 py-2">
                <ButtonNav
                  theme="light"
                  type="anchor"
                  :href="`${WWW_BASE_URL}/weats`"
                  class="ph-no-capture"
                  button-classes="text-body-2"
                  @click="captureEvent"
                  >Registry</ButtonNav
                >
                <ButtonNav
                  theme="light"
                  type="anchor"
                  :href="`${WWW_BASE_URL}/faq`"
                  class="ph-no-capture"
                  button-classes="text-body-2"
                  @click="captureEvent"
                  >FAQ</ButtonNav
                >
                <ButtonNav
                  theme="light"
                  type="anchor"
                  href="https://blog.wattcarbon.com"
                  class="ph-no-capture"
                  button-classes="text-body-2"
                  open-in-new-tab="true"
                  @click="captureEvent"
                  >Blog</ButtonNav
                >
              </div>
            </transition>
          </li>
          <li v-if="route.meta.appPortal == AppPortal.Enterprise" :class="getMenuClass(['wc-history'])">
            <router-link to="/history" class="ph-no-capture py-3" @click="captureEvent">Products</router-link>
          </li>
        </ul>
      </div>
      <div class="order-2 flex py-3 lg:order-3">
        <ul class="mr-10 hidden items-center gap-5 lg:flex">
          <li v-if="route.meta.appPortal == AppPortal.Marketplace">
            <router-link to="/assets" class="text-body-2 ph-no-capture py-3" aria-current="page" @click="captureEvent">Sell EACs</router-link>
          </li>
          <li v-if="route.meta.appPortal == AppPortal.Accounts">
            <a :href="WWW_BASE_URL" class="text-body-2 ph-no-capture py-3" @click="captureMarketplaceEvent">Marketplace</a>
          </li>
        </ul>
        <Menu as="div" class="text-body-2 relative">
          <MenuButton class="flex items-center gap-3 rounded-lg border border-neutral-300 px-3 py-2 transition-colors hover:border-neutral-500">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 24V21.7664H28V24H4ZM4 17.1V14.9H28V17.1H4ZM4 10.2336V8H28V10.2336H4Z" fill="#000D0F" />
            </svg>
            <div v-if="!store.isLoggedIn" class="size-[30px] rounded-full bg-neutral-300"></div>
            <div
              v-else
              class="text-body-2 flex size-8 items-center justify-center rounded-full bg-neutral-500 text-center text-white"
              data-cy="header-menu-icon">
              {{ store.account.name?.[0] }}
            </div>
          </MenuButton>
          <transition>
            <MenuItems
              v-if="store.isLoggedIn"
              class="absolute right-0 mt-2 w-[240px] origin-top-right divide-y divide-neutral-200 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none dark:bg-blue-500 dark:text-white">
              <div class="px-3 py-4">
                <section class="pb-1">
                  <div class="group flex w-full cursor-pointer flex-col rounded-sm border-b border-neutral-200 px-2 pb-2 pt-1">
                    <div class="text-body-2 mb-2 text-neutral-500">You are logged in as</div>
                    <div class="text-body-1">{{ store.account.name }}</div>
                  </div>
                  <MenuItem as="div" class="mt-1 cursor-pointer hover:bg-neutral-200" @click="captureEvent">
                    <router-link
                      to="/accounts"
                      class="group flex w-full items-center rounded-sm px-2 py-1 hover:bg-neutral-200"
                      active-class="bg-blue-200">
                      <Icon icon="wc-carbon:dashboard" />
                      <div class="ml-2">Account Dashboard</div>
                    </router-link>
                  </MenuItem>
                  <MenuItem as="div" class="mt-1 cursor-pointer" @click="store.isAccountSwitcherModalOpen = true">
                    <div class="group flex w-full items-center rounded-sm px-2 py-1 hover:bg-neutral-200" data-cy="header-menu-switch-account">
                      <Icon icon="wc-carbon:user-multiple" />
                      <div class="ml-2">Switch account</div>
                    </div>
                  </MenuItem>
                  <MenuItem as="div" class="mt-1 cursor-pointer" @click="captureEvent">
                    <router-link
                      to="/accounts/manage"
                      class="group flex w-full items-center rounded-sm px-2 py-1 hover:bg-neutral-200"
                      active-class="bg-blue-200">
                      <Icon icon="wc-carbon:credentials" />
                      <div class="ml-2">Manage accounts</div>
                    </router-link>
                  </MenuItem>
                </section>
                <section :class="{ 'border-t border-neutral-300 pb-1': route.meta.appPortal == AppPortal.Marketplace || store.isAdmin }">
                  <MenuItem
                    v-if="isEnterpriseEnabled"
                    as="div"
                    class="mt-1 cursor-pointer"
                    :class="route.meta.appPortal == AppPortal.Enterprise ? 'bg-blue-200' : 'hover:bg-neutral-200'"
                    @click="captureEvent">
                    <router-link to="/enterprise-dashboard" class="group flex w-full items-center rounded-sm px-2 py-1">
                      <div>Enterprise</div>
                    </router-link>
                  </MenuItem>
                  <MenuItem
                    as="div"
                    class="mt-1 cursor-pointer"
                    :class="route.meta.appPortal == AppPortal.Marketplace ? 'bg-blue-200' : 'hover:bg-neutral-200'"
                    @click="captureEvent">
                    <router-link to="/marketplace" class="group flex w-full items-center rounded-sm px-2 py-1">
                      <div>Marketplace</div>
                    </router-link>
                  </MenuItem>
                  <MenuItem
                    as="div"
                    class="mt-1 cursor-pointer"
                    :class="route.meta.appPortal == AppPortal.CarbonAccounting ? 'bg-blue-200' : 'hover:bg-neutral-200'"
                    @click="captureEvent">
                    <router-link to="/accounting" class="group flex w-full items-center rounded-sm px-2 py-1">
                      <div>Carbon Accounting</div>
                    </router-link>
                  </MenuItem>
                  <MenuItem
                    v-if="store.isAdmin"
                    as="div"
                    class="mt-1 cursor-pointer"
                    :class="route.meta.appPortal == AppPortal.AdminPortal ? 'bg-blue-200' : 'hover:bg-neutral-200'"
                    @click="captureEvent">
                    <router-link to="/admin" class="group flex w-full items-center rounded-sm px-2 py-1">
                      <div>Admin Portal</div>
                    </router-link>
                  </MenuItem>
                  <MenuItem
                    as="div"
                    class="mt-1 cursor-pointer"
                    :class="route.meta.appPortal == AppPortal.AdminPortal ? 'bg-blue-200' : 'hover:bg-neutral-200'"
                    @click="captureEvent">
                    <a :href="`${WWW_BASE_URL}/terms`" class="group flex w-full items-center rounded-sm px-2 py-1">
                      <div>Terms & Conditions</div>
                    </a>
                  </MenuItem>
                </section>
                <section class="border-t border-neutral-300 pb-1">
                  <MenuItem
                    v-if="store.isSpoof"
                    as="div"
                    class="mt-1 cursor-pointer rounded-sm bg-neutral-300 p-2 text-center hover:bg-neutral-200"
                    @click="unspoof">
                    <div>Unspoof</div>
                  </MenuItem>
                  <MenuItem as="div" class="mt-1 cursor-pointer rounded-sm hover:bg-neutral-200" @click="logout">
                    <div class="group flex w-full items-center px-2 py-1">
                      <Icon icon="wc-carbon:logout" />
                      <div class="ml-2">Sign Out</div>
                    </div>
                  </MenuItem>
                </section>
              </div>
            </MenuItems>
            <MenuItems
              v-else
              class="text-body-1 absolute right-0 mt-2 w-[240px] origin-top-right divide-y divide-neutral-200 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
              <div class="py-1">
                <section class="border-b border-neutral-200 py-2">
                  <MenuItem as="div" class="cursor-pointer hover:bg-neutral-200" @click="captureEvent">
                    <router-link to="/register" class="text-body-1-strong group flex w-full items-center rounded-sm p-2 hover:bg-neutral-200">
                      <div class="ml-2">Create an account</div>
                    </router-link>
                  </MenuItem>
                  <MenuItem as="div" class="cursor-pointer hover:bg-neutral-200" @click="captureEvent">
                    <router-link to="/register" class="group flex w-full items-center rounded-sm p-2 hover:bg-neutral-200">
                      <div class="ml-2">Sign in</div>
                    </router-link>
                  </MenuItem>
                </section>
                <section class="border-b border-neutral-200 py-2">
                  <MenuItem as="div" class="cursor-pointer hover:bg-neutral-200" @click="captureEvent">
                    <router-link to="/marketplace" class="group flex w-full items-center rounded-sm p-2 hover:bg-neutral-200" @click="captureEvent">
                      <span class="ml-2">Marketplace</span>
                    </router-link>
                  </MenuItem>
                  <MenuItem as="div" class="cursor-pointer hover:bg-neutral-200" @click="captureEvent">
                    <router-link to="/assets" class="group flex w-full items-center rounded-sm p-2 hover:bg-neutral-200">
                      <div class="ml-2">Sell EACs</div>
                    </router-link>
                  </MenuItem>
                </section>
                <section class="py-2">
                  <div class="text-body-3 my-1 ml-4 text-neutral-500">Resources</div>
                  <MenuItem
                    as="div"
                    class="cursor-pointer"
                    :class="route.meta.appPortal == AppPortal.AdminPortal ? 'bg-blue-200' : 'hover:bg-neutral-200'"
                    @click="captureEvent">
                    <a :href="`${WWW_BASE_URL}/weats`" class="group flex w-full items-center rounded-sm p-2">
                      <div class="ml-2">Registry</div>
                    </a>
                  </MenuItem>
                  <MenuItem
                    as="div"
                    class="cursor-pointer"
                    :class="route.meta.appPortal == AppPortal.AdminPortal ? 'bg-blue-200' : 'hover:bg-neutral-200'"
                    @click="captureEvent">
                    <a :href="`${WWW_BASE_URL}/faq`" class="group flex w-full items-center rounded-sm p-2">
                      <div class="ml-2">FAQ</div>
                    </a>
                  </MenuItem>
                  <MenuItem
                    as="div"
                    class="cursor-pointer"
                    :class="route.meta.appPortal == AppPortal.AdminPortal ? 'bg-blue-200' : 'hover:bg-neutral-200'"
                    @click="captureEvent">
                    <a href="https://blog.wattcarbon.com" class="group flex w-full items-center rounded-sm p-2" target="_blank">
                      <div class="ml-2">Blog</div>
                    </a>
                  </MenuItem>
                </section>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </nav>
  </header>
  <AccountSwitcher />
</template>

<script lang="ts" setup>
import { getEnvironment } from "@/environment"
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue"
import { useRoute, useRouter } from "vue-router"
import { Icon } from "@iconify/vue"
import { onMounted, ref } from "vue"
import posthog from "posthog-js"
import { useMainStore } from "@/store"
import { AppPortal } from "@common/models/models"
import { useAccountsService, useAuthService } from "@/services/service-container"
import ChevronIcon from "@/components/icon/ChevronIcon.vue"
import AccountSwitcher from "@/modules/accounts/AccountSwitcher.vue"
import ButtonNav from "../ui/ButtonNav.vue"
import { useEnterprise } from "@/features"

const { WWW_BASE_URL } = getEnvironment()
const route = useRoute()

const { push } = useRouter()
const store = useMainStore()
const authService = useAuthService()
const accountsService = useAccountsService()

const isEnterpriseEnabled = useEnterprise()

const captureHomeEvent = () => posthog.capture("Clicked on the 'Home' link - Nav Header")
const captureMarketplaceEvent = () => posthog.capture("Clicked on the 'Marketplace' link - Nav Header")
const captureSignOutEvent = () => posthog.capture("Clicked on the 'Sign Out' link - Nav Header")
const captureEvent = (e: PointerEvent) => posthog.capture(`Clicked on the '${(e.target as HTMLElement).textContent}' link - Nav Header`)

const logout = async () => {
  captureSignOutEvent()
  authService.logout()
  await push({ name: "wc-landing" })
}

const unspoof = async () => {
  await authService.unspoof()
  store.accounts = await accountsService.listMyAccounts()
  await push({ path: "/" })
}

onMounted(async () => {
  if (store.isLoggedIn) {
    store.accounts = await accountsService.listMyAccounts()
  }
})

const getMenuClass = (prefixes: Array<string>) => {
  return prefixes.find((prefix) => route.name?.toString().indexOf(prefix) === 0) ? "active" : ""
}

enum HeaderDropdowns {
  Resources = "Resources",
}
const currentHeaderDropdown = ref<HeaderDropdowns>()
const showHeaderDropdowns = (dropdown: HeaderDropdowns) => {
  currentHeaderDropdown.value = dropdown
}
const hideHeaderDropdowns = () => {
  currentHeaderDropdown.value = undefined
}
</script>

<style scoped lang="scss">
@tailwind utilities;

ul {
  li {
    @apply dark:text-white;

    &:hover {
      @apply text-blue-300;
    }

    &.active {
      @apply font-bold #{!important};
      @apply lg:-mb-[28px] lg:pb-[25px] lg:border-b-[3px] lg:border-b-neutral-500 lg:-mx-6 lg:px-6;
    }
  }
}
nav.spoof {
  background-color: theme("colors.blue.400");

  div ul.tabs {
    li {
      @apply border-b-4 border-b-blue-300;

      &:hover,
      &.active {
        @apply border-b-neutral-900;
      }

      &.active {
        @apply shadow-md;
      }
    }
  }
}
</style>
