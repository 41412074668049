<template>
  <router-link v-if="route" v-slot="{ isActive, href: routerHref }" custom v-bind="$props" :to="route">
    <a v-bind="$attrs" :href="routerHref" :class="isActive ? activeClass : inactiveClass" @click="onNavigate">
      <slot />
    </a>
  </router-link>
  <a v-else v-bind="$attrs" :href="href">
    <slot />
  </a>
</template>

<script lang="ts" setup>
import { RouterLink } from "vue-router"
import { useRouter } from "vue-router"

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  // @ts-ignore TS thinks props doesn't exist, ignoring recommended in vue docs
  ...RouterLink.props,
  inactiveClass: String,
  href: { type: String },
  route: { type: Object },
  isBackLink: { type: Boolean },
})

const router = useRouter()

// Checks to see if the defined route matches the previous history state,
// so that we can propertly navigate _back_, keeping the user's browser history clean
const onNavigate = (event: Event) => {
  // Shouldn't be possible, since we only bind this in the case where we are
  // wrapping the link with <router-link>, which we only do when we have a route!
  if (!props.route) return

  event.preventDefault()

  if (props.isBackLink) {
    const previousPageUrl = router.options.history.state.back
    const previousRoute = router.resolve({ path: `${previousPageUrl}` })
    if (previousRoute && props.route.name === previousRoute.name) {
      router.back()
      return
    }
  }

  const newRoute = router.resolve(props.route)
  router.push(newRoute)
}
</script>
