import type { PublicAsset } from "@common/models/asset"
import type { Coordinates } from "@common/models/models"
import type { Story, MarketplaceStory } from "@common/models/story"

import type { Slide } from "@/components/ui/WcCarousel"

import { marketplaceStoryPrice, marketplaceStoryVolume, marketplaceStoryKinds, marketplaceStoryMethodologies } from "@common/models/story"
import { ASSET_KIND } from "@common/models/asset"

export interface Methodology {
  displayName: string
  url: string
}

export interface StoryMetaData {
  isAvailableToOrder: boolean
  volume: string
  resource: string
  price: string
  locations?: Coordinates[]
  methodologies?: Methodology[]
  images: Slide[]
}

export type LoadableStory = Story | MarketplaceStory

export function createMetaData(story: LoadableStory, isAvailableToOrder: boolean, publicAssets?: PublicAsset[]): StoryMetaData {
  const volume = story.volumeDescription || marketplaceStoryVolume(story)

  let resource = ""
  if (story.deviceKindDescription) resource = story.deviceKindDescription
  else if (isAvailableToOrder) resource = marketplaceStoryKinds(story)
  else if (publicAssets) resource = [...new Set(publicAssets.map(({ kind }) => ASSET_KIND[kind]))].join(", ")

  const price = marketplaceStoryPrice(story)

  let locations: Coordinates[]
  if (isAvailableToOrder) locations = story.coordinates ?? []
  else if (publicAssets) locations = publicAssets.map((asset) => asset.coordinates)
  else locations = []

  let methodologies: Methodology[]
  if (isAvailableToOrder) methodologies = marketplaceStoryMethodologies(story)
  else methodologies = []

  const images = [
    {
      id: 0,
      url: story.imageUrl ?? undefined,
    },
  ]

  return {
    isAvailableToOrder,
    volume,
    resource,
    price,
    locations,
    methodologies,
    images,
  }
}
