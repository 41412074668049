<template>
  <div>
    <WcTable
      v-if="props.portfolios.length"
      :data="portfolioOrderSummaries"
      :columns="[
        { key: 'name', label: 'Name', align: 'left' },
        { key: 'displayId', label: 'Portfolio ID', align: 'left' },
        { key: 'status', label: 'Status', align: 'left' },
        { key: 'deploymentTimeline', label: 'Deployment Timeline', align: 'left' },
        { key: 'purchasedAmount', label: 'Purchased Amount', align: 'left' },
        { key: 'allocatedAmount', label: 'Allocated Amount', align: 'left' },
      ]"
      row-id-field="id"
      table-class="text-body-2 w-main-content mx-auto"
      th-class="p-4"
      td-class="p-4"
      tr-class="cursor-pointer hover:bg-blue-200 transition-colors"
      @row-clicked="handlePortfolioRowClick">
      <template #name="{ row }">
        <span data-cy="portfolio-table-row">{{ row.name }}</span>
      </template>
      <template #status="{ row }">
        <span class="capitalize">{{ row.status }}</span>
      </template>
      <template #purchasedAmount="{ row }">
        {{ `${row.purchasedAmount} ${UtilityHelper.getShorthandUnit(row.units)}` }}
      </template>
      <template #allocatedAmount="{ row }">
        {{
          getFormattedEacQuantity(
            row.allocatedAmount || 0,
            row.units === "mwh_electricity" ? EacMeasurementParameter.Electricity : EacMeasurementParameter.GhgEmissions
          )
        }}
      </template>
    </WcTable>
    <div v-else class="wc-page-container">
      <div class="flex flex-col items-center justify-center gap-8 bg-neutral-200 p-8">
        <p class="text-body-2 text-center">You have not ordered from any portfolios. Stay tuned for more information.</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// Libraries
import { computed } from "vue"
import { useRouter } from "vue-router"
import UtilityHelper from "@common/services/utility.helper"
import posthog from "posthog-js"

// Components
import WcTable from "@/components/WcTable/WcTable.vue"

// Models
import { EacMeasurementParameter, getFormattedEacQuantity } from "@common/models/order"

const router = useRouter()

const props = defineProps({
  portfolios: { type: Array, required: true },
})

const portfolioOrderSummaries = computed(() => {
  return props.portfolios.map((row: any) => ({
    id: row.portfolio.id,
    name: row.portfolio.name,
    displayId: row.portfolio.displayId,
    status: row.portfolio.status,
    deploymentTimeline: row.portfolio.dateRangeDescription,
    purchasedAmount: row.quantityTotal,
    allocatedAmount: row.eacsAllocated,
    units: row.portfolio.units,
  }))
})

const handlePortfolioRowClick = (row: any) => {
  capturePortfolioSelectionEvent(row.name)
  router.push(`/accounts/portfolio/${row.id}`)
}

const capturePortfolioSelectionEvent = (portfolioName: string) =>
  posthog.capture(`Clicked on the '${portfolioName}' portfolio - Accounts Portfolio Table`)
</script>
