<template>
  <div class="flex size-full items-center justify-center">
    <div ref="spinner" class="loader" :class="{ 'light-loader': color === 'light', 'neutral-loader': color === 'neutral' }"></div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue"

interface Props {
  height: string
  width: string
  color: "dark" | "light" | "neutral"
  delay: number
}

const props = withDefaults(defineProps<Props>(), {
  height: "100",
  width: "100",
  color: "dark",
  delay: 80,
})

//
const spinner = ref()
//

onMounted(async () => {
  spinner.value.style.height = `${Number(props.height) / 2}%`
  spinner.value.style.width = `${Number(props.width) / 2}%`
})
</script>

<style scoped lang="scss">
@tailwind;
.loader {
  position: relative;
  background-repeat: no-repeat;
  background-image: linear-gradient(theme("colors.blue.500") 100%, transparent 0), linear-gradient(theme("colors.blue.500") 100%, transparent 0),
    linear-gradient(theme("colors.blue.500") 100%, transparent 0), linear-gradient(theme("colors.blue.500") 100%, transparent 0),
    linear-gradient(theme("colors.blue.500") 100%, transparent 0), linear-gradient(theme("colors.blue.500") 100%, transparent 0);
  background-position: 12% center, 27% center, 42% center, 57% center, 72% center, 87% center, 100% center;
  animation: rikSpikeRoll 1s linear infinite alternate;
}

.light-loader {
  background-image: linear-gradient(theme("colors.white") 100%, transparent 0), linear-gradient(theme("colors.white") 100%, transparent 0),
    linear-gradient(theme("colors.white") 100%, transparent 0), linear-gradient(theme("colors.white") 100%, transparent 0),
    linear-gradient(theme("colors.white") 100%, transparent 0), linear-gradient(theme("colors.white") 100%, transparent 0);
}

.neutral-loader {
  background-image: linear-gradient(theme("colors.neutral.300") 100%, transparent 0), linear-gradient(theme("colors.neutral.300") 100%, transparent 0),
    linear-gradient(theme("colors.neutral.300") 100%, transparent 0), linear-gradient(theme("colors.neutral.300") 100%, transparent 0),
    linear-gradient(theme("colors.neutral.300") 100%, transparent 0), linear-gradient(theme("colors.neutral.300") 100%, transparent 0);
}

@keyframes rikSpikeRoll {
  0% {
    background-size: 10% 5%;
  }
  16% {
    background-size: 10% 100%, 10% 6%, 10% 5%, 10% 5%, 10% 5%, 10% 5%;
  }
  35% {
    background-size: 10% 30%, 10% 100%, 10% 5%, 10% 5%, 10% 5%, 10% 5%;
  }
  50% {
    background-size: 10% 10%, 10% 30%, 10% 100%, 10% 5%, 10% 5%, 10% 5%;
  }
  66% {
    background-size: 10% 5%, 10% 10%, 10% 30%, 10% 100%, 10% 5%, 10% 5%;
  }
  85% {
    background-size: 10% 5%, 10% 5%, 10% 10%, 10% 30%, 10% 100%, 10% 5%;
  }
  100% {
    background-size: 10% 5%, 10% 5%, 10% 5%, 10% 10%, 10% 30%, 10% 100%;
  }
}
</style>
