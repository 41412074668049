<template>
  <section class="page disable-select w-full max-w-[400px] px-4">
    <img class="w-[320px]" src="https://static.wattcarbon.com/wattcarbon/logo/wattcarbon_logo_on-light_158px.svg" />
    <div class="text-body-3 disable-select mt-8 flex w-full flex-col items-center justify-center rounded-lg border border-blue-500 bg-white p-4">
      <form class="w-full" @submit.prevent="login(email)">
        <h2 class="text-heading-5 mb-6 mt-4 text-center">Sign in</h2>
        <div v-if="isLoggedIn" class="mb-6 rounded-md bg-blue-100 p-4">
          <p><strong>We sent you a link to sign in and continue.</strong></p>
          <p class="mt-4">This link will expire in 10 minutes. Each link can only be used once.</p>
        </div>
        <div v-else class="mb-6">Enter the email address associated with your account, and we’ll send a magic link to your inbox.</div>
        <div v-if="errors.length > 0" class="my-4 w-full rounded-lg bg-accent p-4">
          <ol>
            <li v-for="e in errors" :key="e">{{ e }}</li>
          </ol>
        </div>
        <TextInput
          v-model="email"
          :disabled="isLoggedIn"
          placeholder="jordan@example.com"
          label="Your email"
          class="ph-no-capture"
          @click="captureEmailInputEvent" />
        <p class="text-body-3 mt-3">
          By clicking "Continue", you are agreeing to be bound by our
          <a :href="`${WWW_BASE_URL}/terms`" class="underline">Terms and Conditions</a>.
        </p>
        <ButtonCTA v-if="!isLoggedIn" theme="light" :is-filled="true" type="submit" width="full" button-classes="mt-4 ph-no-capture"
          >Continue</ButtonCTA
        >
        <ButtonCTA
          v-else
          theme="light"
          :is-filled="false"
          width="full"
          button-classes="mt-4 ph-no-capture"
          @click.prevent="login(email)"
          @click="captureResendLinkEvent"
          >Resend link</ButtonCTA
        >
        <div class="mt-8 w-full text-center">
          <router-link class="text-body-3 ph-no-capture text-blue-400 underline" to="/register" @click="captureCreateNewAccountEvent"
            >Create a new account</router-link
          >
        </div>
      </form>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { computed, onActivated, ref } from "vue"
import posthog from "posthog-js"
import { useMainStore } from "@/store"
import { useAuthStore } from "@/modules/auth/auth.state"
import { useAuthService } from "@/services/service-container"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import TextInput from "@/components/ui/TextInput.vue"
import { getEnvironment } from "@/environment"
import scalarFromVectorOrScalar from "@/utils/scalarFromVectorOrScalar"

import { push, getQueryParam } from "./auth.utils"

const { WWW_BASE_URL } = getEnvironment()

const authStore = useAuthStore()

const email = ref("")
const errors = computed(() => authStore.errors)
const isLoggedIn = ref(false)
const service = useAuthService()
const store = useMainStore()

// PostHog Events
const captureEmailInputEvent = () => posthog.capture('Clicked on the "Email" input - Sign In View')
const captureSignInEvent = () => posthog.capture('Clicked on the "Continue" button - User attempted to sign in - Sign In View')
const captureSignInErrorEvent = () => posthog.capture("Error when trying to sign in - Sign In View")
const captureCreateNewAccountEvent = () => posthog.capture('Clicked on the "Create A New Account" link - Sign In View')
const captureResendLinkEvent = () => posthog.capture('Clicked on the "Resend Link" button - Sign In View')

if (store.auth.isAuthenticated) {
  push({ name: "wc-landing" }, "/")
}

onActivated(() => {
  if (store.auth.isAuthenticated) {
    push({ name: "wc-landing" }, "/")
  }
})

const login = (email: string) => {
  captureSignInEvent()
  authStore.resetErrors()
  const lRouteQuery = scalarFromVectorOrScalar(getQueryParam("l"))
  service.magic(email, lRouteQuery || "").then(
    () => {
      isLoggedIn.value = true
    },
    () => {
      authStore.addError("There was a problem with that account")
      authStore.addError("Please check your credentials")
      captureSignInErrorEvent()
    }
  )
}
</script>

<style scoped lang="scss">
.login-card {
  width: 400px;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
