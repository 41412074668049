<template>
  <AppPage>
    <AppPageHeader :backlink-route="{ name: 'wc-assets' }" backlink-text="Back to Assets">
      <template v-if="isUnlisting"> Unlist EACS </template>
      <template v-else> List EACs for Sale </template>
    </AppPageHeader>
    <div class="wc-page-container mb-8 w-full">
      <template v-if="assets?.length > 0">
        <section class="text-body-1 mb-8 max-w-[794px]">
          <template v-if="isUnlisting">
            By continuing, all active EACs in your inventory that originate from these assets will removed from the marketplace.
          </template>
          <template v-else>
            By continuing, all active EACs in your inventory that originate from these assets will become available for sale on the marketplace. This
            includes EACs that are already in your inventory, and any new EACs minted by these assets. You can delist an asset at any time to remove
            that asset's EACs from the marketplace.
          </template>
        </section>
        <section class="mb-14">
          <h4 class="text-subheading-1 mb-5">{{ assets.length }} Selected Assets</h4>
          <div class="mt-4 max-h-[304px] overflow-x-scroll outline outline-1 outline-neutral-300">
            <AssetsTable :assets="assets" show-price />
          </div>
          <div v-if="selectionIncludesInactiveAssets && !isUnlisting" class="text-body-2 mt-4">
            Some of the selected assets are not Active. EACs from these assets will not be listed for sale on the marketplace until the asset is
            Active. You can still set a price for the EACs now, and assets will be listed for sale immediately after the asset is Active.
          </div>
        </section>
        <section>
          <form @submit.prevent="handleSubmit">
            <template v-if="isUnlisting">
              <input v-model="price" type="hidden" name="price" />
            </template>
            <template v-else>
              <div class="text-subheading-1 mb-4">Set an asking price for the EACs</div>
              <div class="text-body-2 mb-12 flex items-center gap-4">
                <WcInputNumber v-model="price" class="max-w-40" name="price" mode="currency" @input="handlePriceChange" />
                <span>per</span>
                <WcDropdown
                  v-model="priceFunction"
                  class="basis-40"
                  name="priceFunction"
                  :options="priceFunctionOptions"
                  :show-clear="false"
                  inset-label="Price Function" />
                <p class="text-body-2 mt-1">
                  <template v-if="price"> Your proceeds from sale on the marketplace after WattCarbon's fee: {{ payoutAmount }} </template>
                  <template v-else> Warning: Setting the price to zero will <em>unlist</em> all EACs from these assets. </template>
                </p>
              </div>
              <!-- TODO: Re-enable once total revenue is being calculated from actual asset info -->
              <div v-if="false" class="mb-8">
                <div class="text-body-2">Total possible revenue per quarter</div>
                <div class="text-body-1">{{ formatCurrency(totalPossibleRevenueInPennies) }}</div>
              </div>
            </template>
            <ButtonCTA type="submit" :is-filled="true" theme="light">Submit</ButtonCTA>
          </form>
        </section>
      </template>
    </div>
  </AppPage>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue"
import { useRouter } from "vue-router"
import { useToast } from "vue-toastification"
import type { SelectOption, WcInputNumberInputEvent } from "@/components/input"
import { WcDropdown, WcInputNumber } from "@/components/input"
import { AppPage, AppPageHeader } from "@/components/layout"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import { useAssetService } from "@/services/service-container"
import { useMainStore } from "@/store"
import { debounce } from "@/utils/debounce"
import formatCurrencyInPenniesFactory from "@common/utils/formatCurrencyInPenniesFactory"
import { Asset, ASSET_PRICING_FUNCTION_TYPE, AssetPricingFunctionType, AssetStatus } from "@common/models/asset"
import AssetsTable from "./components/AssetsTable.vue"
import { calculateTotalPossibleRevenue, calculatePayoutAfterFees } from "./ListAssetEacsForSaleView.utils"

const DEFAULT_PRICE = 160

const priceFunctionOptions = Object.entries(ASSET_PRICING_FUNCTION_TYPE).map(([value, label]) => ({
  label,
  value,
})) as SelectOption<AssetPricingFunctionType>[]

const formatCurrency = formatCurrencyInPenniesFactory()
const assetService = useAssetService()
const store = useMainStore()
const router = useRouter()
const toast = useToast()

const assets = ref<Asset[]>([])
const price = ref<number>(DEFAULT_PRICE)
const isUnlisting = ref<boolean>(false)
const priceFunction = ref<SelectOption<AssetPricingFunctionType>>(priceFunctionOptions[1])

const priceInPennies = computed(() => price.value * 100)

const payoutAmount = computed(() => {
  const afterFees = calculatePayoutAfterFees(price.value)
  return afterFees.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  })
})

const selectionIncludesInactiveAssets = computed(() => {
  return assets.value.some((asset) => asset.status !== AssetStatus.approved)
})

const totalPossibleRevenueInPennies = computed(() => {
  return calculateTotalPossibleRevenue({
    assets: assets.value,
    priceInPennies: priceInPennies.value,
    priceFunction: priceFunction.value.value,
  })
})

onMounted(() => {
  assets.value = store.assetsToUpdate
  isUnlisting.value = store.isUnlisting

  if (store.isUnlisting) price.value = 0
})

const handlePriceChange = debounce(async ({ value }: WcInputNumberInputEvent) => {
  if (typeof value == "number") {
    price.value = value
  } else if (!value) {
    price.value = 0
  }
})

const handleSubmit = async () => {
  try {
    const assetIds = assets.value.map((asset) => asset.id)

    if (priceInPennies.value === 0) {
      await assetService.deleteAssetPriceBulk(assetIds)
    } else {
      await assetService.setAssetPriceBulk(assetIds, {
        pricePerUnitPenniesUsd: priceInPennies.value,
        priceFunction: priceFunction.value.value,
      })
    }

    toast.success(`${assetIds.length} assets were updated.`)
    router.back()
  } catch (error) {
    const message = "There was an error setting asset price(s)"
    console.error(message, error)
    if (error instanceof Error) {
      toast.error(`${message}\n${error.message}`)
    }
  }
}
</script>
