<template>
  <AppPage>
    <AppPageHeader :backlink-route="{ name: 'wc-marketplace' }" backlink-text="Back to Marketplace" page-type-label="Listing">
      Listing {{ listing.id }}
      <template v-if="!listing.isAvailable">
        <span class="text-body-2 mt-2 block text-error">This listing has been sold</span>
      </template>
      <template #title-adjacent>
        <ButtonCTA
          type="router-link"
          :is-filled="true"
          theme="light"
          :to="{
            name: 'wc-listing-checkout',
            query: { id: listing.id },
          }"
          :is-disabled="!listing.isAvailable"
          >Purchase EACs</ButtonCTA
        >
      </template>
    </AppPageHeader>
    <div class="mt-8 overflow-x-scroll px-4 pb-8 md:px-[50px]">
      <WcTable
        :data="listingData"
        :columns="[
          { key: 'id', label: 'ID', tdClass: 'text-center' },
          { key: 'provider', label: 'Grid', tdClass: 'text-center' },
          { key: 'dateRange', label: 'Term', tdClass: 'text-center' },
          { key: 'hoursOfDayLocal', label: `Hour(s) (${localTZName})`, tdClass: 'text-center' },
          {
            key: 'averageCarbonIntensityKgco2PerMwh',
            label: 'Average Carbon Intensity (kgCO2/MWh)',
            tdClass: 'text-center',
            thClass: 'w-[135px]',
          },
          { key: 'category', label: 'Category', tdClass: 'capitalize text-center' },
          { key: 'source', label: 'Source', tdClass: 'capitalize text-center' },
          { key: 'pricePerEac', label: 'Price per MWh', tdClass: 'text-right' },
          { key: 'totalEacs', label: 'Amount', tdClass: 'text-right' },
          { key: 'pricePenniesUsd', label: 'Price (USD)', tdClass: 'text-right' },
        ]"
        row-id-field="id"
        table-class="min-w-main-content mx-auto text-body-2"
        th-class="text-center"
        td-class="px-3 py-2 whitespace-nowrap"
        :sortable="false">
        <template #category="{ row }">
          {{ row.category.replaceAll("_", " ") }}
        </template>
        <template #totalEacs="{ row }">
          {{ getFormattedEacQuantity(row.totalEacs || 0, EacMeasurementParameter.Electricity) }}
        </template>
        <template #averageCarbonIntensityKgco2PerMwh="{ row }">
          {{ row.averageCarbonIntensityKgco2PerMwh.toFixed(1) }}
        </template>
        <template #pricePenniesUsd="{ row }">
          {{ (parseInt(row.pricePenniesUsd) / 100).toLocaleString("en-US", { style: "currency", currency: "USD" }) }}
        </template>
        <template #pricePerEac="{ row }">
          {{ (row.pricePerEac * 10000).toLocaleString("en-US", { style: "currency", currency: "USD" }) }} / MWh
        </template>
      </WcTable>
    </div>
    <div v-if="MAPBOX_ACCESS_TOKEN" class="wc-page-container mb-24 mt-4 h-[600px]">
      <AppPageSectionHeader>Asset Locations</AppPageSectionHeader>
      <div class="relative size-full">
        <WcMap v-if="listing?.locations?.length > 0" :locations="listing.locations ?? []" class="mb-8" />
      </div>
    </div>
  </AppPage>
</template>

<script setup lang="ts">
import { formatDateRange, formatHoursOfDay } from "@common/models/listing"
import { AppPage, AppPageHeader, AppPageSectionHeader } from "@/components/layout"
import { computed } from "vue"
import { useListingService } from "@/services/service-container"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import WcMap from "@/components/WcMap/WcMap.vue"
import { MAPBOX_ACCESS_TOKEN } from "@/components/WcMap/WcMap.utils"
import WcTable from "@/components/WcTable/WcTable.vue"
import { EacMeasurementParameter, getFormattedEacQuantity } from "@common/models/order"
import { getLocalTZName } from "./components/ListingsSection.utils"

const props = defineProps<{
  listingId: number
}>()
const listingService = useListingService()
const listing = await listingService.getListing(props.listingId)
const listingData = computed(() => {
  return [listing].map((listing) => ({
    id: listing.id,
    dateRange: formatDateRange(listing.dateRange),
    hoursOfDayLocal: formatHoursOfDay(listing.hoursOfDayUtc),
    totalEacs: listing.totalEacs,
    source: listing.source,
    category: listing.category,
    provider: listing.provider,
    averageCarbonIntensityKgco2PerMwh: listing.averageCarbonIntensityKgco2PerMwh,
    pricePenniesUsd: listing.pricePenniesUsd,
    pricePerEac: listing.pricePenniesUsd / listing.totalEacs,
  }))
})
const localTZName = getLocalTZName()
</script>
