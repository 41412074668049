<template>
  <form class="flex flex-col gap-2" @submit.prevent="upsertPortfolio(portfolio)">
    <label>
      Name
      <input v-model="portfolio.name" type="text" required />
    </label>

    <label>
      Display ID (for buyer agreement)
      <input v-model="portfolio.displayId" type="text" required pattern="[A-Z0-9_]+" />
    </label>

    <label>
      Status
      <select v-model="portfolio.status" class="p-2" required>
        <option v-for="key in PortfolioStatusType" :key="key" :value="key">{{ key }}</option>
      </select>
    </label>

    <label>
      Short Description
      <textarea v-model="portfolio.shortDescription" class="p-2" rows="5" required></textarea>
    </label>

    <label>
      Long Description
      <textarea v-model="portfolio.longDescription" class="p-2" rows="10"></textarea>
    </label>

    <label>
      Location Description
      <input v-model="portfolio.locationDescription" type="text" />
    </label>

    <label>
      Provider
      <select v-model="portfolio.provider" class="p-2">
        <option :value="null">No provider restriction</option>
        <option v-for="p in providers" :key="p.key" :value="p.key">{{ p.key }}: {{ p.text }}</option>
      </select>
    </label>

    <label>
      Estimated Carbon Intensity (tCo2 per MWh)
      <input v-model="portfolio.estimatedCarbonIntensityTonnesCo2PerMwh" type="text" inputmode="numeric" pattern="[0-9]+\.?[0-9]*" />
    </label>

    <div class="flex gap-4">
      <label>
        Start Date
        <input v-model="portfolio.dateRange.lower" type="date" required />
      </label>
      <label>
        End Date
        <input v-model="portfolio.dateRange.upper" type="date" :min="portfolio.dateRange.lower" required />
      </label>
    </div>

    <label>
      EAC Transfer Deadline
      <input v-model="portfolio.transferDeadline" type="date" class="w-fit" :min="portfolio.dateRange.upper" required />
    </label>

    <label>
      Date Range Description
      <input v-model="portfolio.dateRangeDescription" type="text" placeholder="January" required />
    </label>

    <label>
      Hours Description
      <input v-model="portfolio.hoursDescription" type="text" placeholder="Evenings" required />
    </label>

    <label>
      Units
      <select v-model="portfolio.units" class="p-2" required>
        <option value="mwh_electricity">MWh of Electricity</option>
        <option value="tonnes_co2e">Tonnes of CO2e</option>
      </select>
    </label>

    <label>
      Quantity Total
      <input v-model="portfolio.quantityTotal" type="text" inputmode="numeric" pattern="\d*" required />
    </label>

    <label>
      Price (Pennies per Unit)
      <input v-model="portfolio.pricePenniesUsdPerUnit" type="text" inputmode="numeric" pattern="\d*" required />
    </label>

    <label>
      Allocation Source
      <select v-model="portfolio.allocationSource" class="p-2" required>
        <option value="orders">Stripe Orders</option>
        <option value="stakes">Stakes</option>
      </select>
    </label>

    <label>
      Buyer Agreement Template
      <input v-model="portfolio.buyerAgreementTemplate" type="text" placeholder="renewables_direct" required />
    </label>

    <ButtonCTA button-classes="bg-highlight gap-2" type="submit" class="w-fit">
      <Icon icon="wc-carbon:save" />
      Save
    </ButtonCTA>
  </form>
</template>

<script setup lang="ts">
import { useAdminPortfoliosStore } from "@/modules/admin/adminPortfolios.state"
import { computed, onMounted, ref } from "vue"
import { Icon } from "@iconify/vue"
import _ from "lodash"
import { Portfolio, PortfolioStatusType } from "@common/models/order"
import { useProviderService } from "@/services/service-container"
import { Provider } from "@common/models/models"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import { useRouter } from "vue-router"

const props = defineProps<{
  portfolioId?: number
}>()

const adminPortfoliosStore = useAdminPortfoliosStore()
const providerService = useProviderService()
const router = useRouter()

const providers = ref<Array<Provider>>([])
onMounted(async () => {
  providers.value = await providerService.listProviders()
})

const portfolio = computed(() => {
  if (props.portfolioId !== undefined) {
    return _.cloneDeep(adminPortfoliosStore.getPortfolioById(props.portfolioId)!)
  } else {
    return new Portfolio()
  }
})

const upsertPortfolio = async (portfolio: Portfolio) => {
  if (portfolio.id === undefined) {
    const newPortfolio = await adminPortfoliosStore.createPortfolio(portfolio)
    router.push({ name: "wc-admin-portfolio", params: { portfolioId: newPortfolio.id } })
  } else {
    await adminPortfoliosStore.updatePortfolio(portfolio)
  }
}
</script>

<style scoped lang="scss">
input[type="text"],
input[type="date"],
textarea,
select {
  @apply invalid:border-error;
}
</style>
