import { ref, computed, watch } from "vue"
import { useRegistryService } from "@/services/service-container"
import { DateRange } from "@/services/api/registry.service"
import { defineStore } from "pinia"
import { addDays } from "date-fns"

interface EacBalances {
  [isoDateString: string]: EacBalance
}

interface EacBalance {
  dateTime: string // this will be the same value as the key (isoDateString)
  dateIndex: number
  hourIndex: number
  active: number
  retired: number
}

interface Selection {
  minDateIndex: number
  maxDateIndex: number
  minHourIndex: number
  maxHourIndex: number
}

export const isRetired = (eac: any) => {
  return eac["Status"] === "retired"
}

export const useEacStore = defineStore("eac", () => {
  const registryService = useRegistryService()
  const isLoading = ref(true)

  const dateRange = ref<DateRange | null>(null)
  const eacBalances = ref<EacBalances>({})
  const selection = ref<Selection | null>(null)

  const setDateRange = (newDateRange: DateRange) => {
    dateRange.value = newDateRange
    resetSelection()
  }

  watch(dateRange, async () => {
    await fetchEacBalances()
  })

  const fetchEacBalances = async () => {
    if (dateRange.value) {
      isLoading.value = true

      const hourlySummary = await registryService.getHourlyBalanceSummary(dateRange.value.startDate, dateRange.value.endDate)
      const dateRangeStartUtcDateWithoutTime = constructUtcDateWithoutTime(dateRange.value.startDate)
      eacBalances.value = Object.fromEntries(
        Object.entries(hourlySummary.hours)
          .filter(([, accountBalances]) => {
            const activeBalance = accountBalances?.active?.wh_electricity?.count ?? 0
            const retiredBalance = accountBalances?.retired?.wh_electricity?.count ?? 0
            return activeBalance > 0 || retiredBalance > 0
          })
          .map(([timestamp, accountBalances]) => {
            const hour = parseUTCTimestamp(timestamp)
            const utcDateWithoutTime = constructUtcDateWithoutTime(hour)
            const dateIndex = dateDiffInDays(dateRangeStartUtcDateWithoutTime, utcDateWithoutTime)

            return [
              hour.toISOString(),
              {
                dateTime: hour.toISOString(),
                dateIndex: dateIndex,
                hourIndex: hour.getUTCHours(),
                active: Object.values(accountBalances.active).reduce(
                  (totalBalanceAnyUnit, eacCount) => totalBalanceAnyUnit + (eacCount.count ?? 0),
                  0
                ),
                retired: Object.values(accountBalances.retired).reduce(
                  (totalBalanceAnyUnit, eacCount) => totalBalanceAnyUnit + (eacCount.count ?? 0),
                  0
                ),
              },
            ]
          })
      )

      isLoading.value = false
    }
  }

  const isBalanceSelected = (balance: EacBalance) => {
    if (!selection.value) return false
    const { minDateIndex, maxDateIndex, minHourIndex, maxHourIndex } = selection.value
    return (
      minHourIndex <= balance.hourIndex && balance.hourIndex <= maxHourIndex && minDateIndex <= balance.dateIndex && balance.dateIndex <= maxDateIndex
    )
  }

  const isDateTimeSelected = (isoDateString: string) => {
    const balance = eacBalances.value[isoDateString]
    return isBalanceSelected(balance)
  }

  const setSelection = (newSelection: Selection) => {
    selection.value = newSelection
  }

  const resetSelection = () => {
    selection.value = null
  }

  const selectedDateTimeRange = computed(() => {
    if (!dateRange.value) return null
    if (!selection.value) return null

    return {
      startDate: addDays(dateRange.value.startDate, selection.value.minDateIndex),
      endDate: addDays(dateRange.value.startDate, selection.value.maxDateIndex + 1),
      startHour: selection.value.minHourIndex,
      endHour: selection.value.maxHourIndex + 1,
    }
  })

  const totalAvailableQuantity = computed(() => {
    return Object.values(eacBalances.value).reduce((total, hour) => total + hour.active + hour.retired, 0)
  })

  const selectedBalances = computed(() => Object.values(eacBalances.value).filter((balance) => isBalanceSelected(balance)))

  const totalSelectedQuantity = computed(() => selectedBalances.value.reduce((total, balance) => total + balance.active + balance.retired, 0))

  const totalSelectedActiveQuantity = computed(() => selectedBalances.value.reduce((total, balance) => total + balance.active, 0))

  return {
    // state
    eacBalances,
    isLoading,
    dateRange,
    // getters
    totalAvailableQuantity,
    totalSelectedQuantity,
    totalSelectedActiveQuantity,
    isDateTimeSelected,
    selectedDateTimeRange,
    // actions
    setDateRange,
    setSelection,
    resetSelection,
    fetchEacBalances,
  }
})

function hasTimeZone(dateString: string) {
  const timeZoneRegex = /(?:Z|[+-](?:2[0-3]|[01]\d):[0-5]\d)$/
  return timeZoneRegex.test(dateString)
}

export function parseUTCTimestamp(dateString: string) {
  if (!hasTimeZone(dateString)) {
    dateString += "Z" // treat naive timestamps as UTC
  }
  return new Date(dateString)
}

// Pick out the year, month, and date portions of the Date and construct a new
// UTC Date with only those pieces
function constructUtcDateWithoutTime(utcDateWithTime: Date) {
  return new Date(Date.UTC(utcDateWithTime.getUTCFullYear(), utcDateWithTime.getUTCMonth(), utcDateWithTime.getUTCDate()))
}

function dateDiffInDays(startDate: Date, endDate: Date) {
  const dateDiff = endDate.getTime() - startDate.getTime()
  return Math.floor(dateDiff / (1000 * 3600 * 24))
}
