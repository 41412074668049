<template>
  <AppPage>
    <AppPageHeader :backlink-route="{ name: 'wc-assets-upload' }" backlink-text="Back to Upload Assets">Review assets</AppPageHeader>
    <AppPageContent>
      <div class="max-w-[780px] pb-8 pt-4">We found metadata for {{ assetSubmissionRows.length }} assets in the uploaded file.</div>
      <AssetUploadErrorSummary class="mt-4 border-y border-neutral-300 py-6" />
      <AssetUploadWarningSummary class="border-b border-neutral-300 py-6" />
      <div v-if="assetSubmissionRows.length > 0" class="mt-4 max-h-[calc(100vh-40rem)] min-h-[400px] overflow-scroll border-b border-neutral-300">
        <WcTable
          :data="assetSubmissionRows"
          :columns="columns"
          row-id-field="id"
          table-class="min-w-main-content mx-auto text-body-2 asset-submission-table"
          :tr-class="getRowClass"
          th-class="text-left py-4 top-0 bg-white sticky"
          td-class="px-3 py-4 align-baseline min-w-36"
          :sortable="true">
          <template #issues="{ row }">
            <div class="min-w-64 max-w-[280px]">
              <div v-if="row.errors?.length > 0">
                Errors:
                <ol class="list-inside list-decimal">
                  <li v-for="(error, index) in row.errors" :key="index">{{ error }}</li>
                </ol>
              </div>
              <div v-if="row.warnings?.length > 0" class="mt-2">
                Warnings:
                <ol class="list-inside list-decimal">
                  <li v-for="(warning, index) in row.warnings" :key="index">{{ warning }}</li>
                </ol>
              </div>
            </div>
          </template>
        </WcTable>
      </div>
      <div v-else class="mt-16" />
      <div class="mt-4 flex flex-wrap gap-2">
        <WcButton
          :icon="isSubmittingAssets ? 'spinner' : undefined"
          :is-disabled="!isSubmissionValid || isSubmittingAssets"
          text="Submit Assets"
          @click="submitAssets" />
        <WcButton variant="outlined" :is-disabled="isSubmittingAssets" text="Upload Again" @click="routeToUploadAgain" />
        <WcButton variant="outlined" :is-disabled="isSubmittingAssets || isSubmissionValid" text="Download CSV With Issues" @click="downloadCsv" />
      </div>
    </AppPageContent>
  </AppPage>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue"
import { useRouter } from "vue-router"
import { useToast } from "vue-toastification"
import { AppPage, AppPageContent, AppPageHeader } from "@/components/layout"
import { WcButton } from "@/components/button"
import type { TableHeader } from "@/components/WcTable/WcTable"
import WcTable from "@/components/WcTable/WcTable.vue"
import { useAssetService } from "@/services/service-container"
import { createCsvBlob } from "@/utils/csv"
import { useAssetStore } from "./asset.state"
import AssetUploadErrorSummary from "./components/AssetUploadErrorSummary.vue"
import AssetUploadWarningSummary from "./components/AssetUploadWarningSummary.vue"

const SPECIAL_COLUMN_KEYS = ["line", "issues", "Name", "errors", "warnings", "Name", "Internal ID"]

const router = useRouter()
const assetService = useAssetService()
const assetStore = useAssetStore()
const toast = useToast()

const assetSubmissionRows = ref<Record<string, any>[]>([])
const isSubmissionValid = ref<boolean>(false)
const isSubmittingAssets = ref<boolean>(false)

const makeColumn = (label: string, key?: string): TableHeader => ({ key: key ?? label, label, align: "left" })

const columns = computed(() => {
  const firstRow = assetSubmissionRows.value[0]
  if (!firstRow) {
    return []
  }

  const columnNames = Object.keys(firstRow)

  // Pick out these special columns, because we want to customize either their
  // names or the order they show in
  const filteredColumnNames = columnNames.filter((name) => !SPECIAL_COLUMN_KEYS.includes(name))
  return [
    makeColumn("Row", "line"),
    makeColumn("Errors/Warnings", "issues"),
    makeColumn("Name", "Name"),
    makeColumn("Internal ID"),
    ...filteredColumnNames.map((name) => makeColumn(name)),
  ]
})

const getRowClass = (row: Record<string, any>) => {
  if (row.errors.length > 0) {
    return "bg-error-100 hover:bg-error-100 border-error border-y"
  }

  if (row.warnings.length > 0) {
    return "bg-cornyellow-100 hover:bg-cornyellow-100 border-cornyellow border-y"
  }

  return "even:bg-blue-100 hover:bg-blue-200 transition-colors"
}

onMounted(() => {
  assetSubmissionRows.value = assetStore.assetSubmissionRows
  isSubmissionValid.value = assetStore.isSubmissionValid
})

const submitAssets = async () => {
  if (!assetStore.assetSubmissionFile || !assetStore.assetSubmissionKind) {
    console.error("No file or asset kind selected")
    return
  }

  isSubmittingAssets.value = true
  try {
    const results = await assetService.uploadAssets(assetStore.assetSubmissionKind, assetStore.assetSubmissionFile)
    if (results.length === 0) {
      throw new Error("No assets were uploaded")
    } else if (results.length === 1) {
      toast.success(`1 asset was uploaded`)
    } else {
      toast.success(`${results.length} assets were uploaded`)
    }
    assetStore.$reset()
    router.push({ name: "wc-assets" })
  } catch (error) {
    if (error instanceof Error) {
      toast.error(`There was a problem uploading the assets\n${error.message}`)
    }
  }
  isSubmittingAssets.value = false
}

const routeToUploadAgain = () => {
  router.push({ name: "wc-assets-upload" })
}

const downloadCsv = () => {
  const data = assetSubmissionRows.value.map((row) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { line, issues, warnings, ...rest } = row
    return rest
  })
  const fileNameWithoutExtension = assetStore.assetSubmissionFile?.name.split(".")[0] || "asset-import"
  const exportFileName = `${fileNameWithoutExtension}-with-errors.csv`
  const blob = createCsvBlob(data)
  const objectUrl = window.URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = objectUrl
  link.download = exportFileName
  link.style.display = "none"
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  window.URL.revokeObjectURL(objectUrl)
}
</script>

<style lang="scss">
// Override table heading to show the bottom border as a box-shadow when sticky
.asset-submission-table {
  thead {
    border-bottom-width: 0 !important;
  }
  th {
    box-shadow: inset 0 -1px theme("colors.neutral.300");
  }
}
</style>
