<template>
  <IconField v-if="icon" class="w-full" :class="inputClass">
    <InputIcon class="-mt-2.5 stroke-blue-400 stroke-1" :class="{ '-mt-2': size === 'small', '-mt-2.5': size === 'large' }">
      <Icon :class="{ 'size-4': size === 'small', 'size-5': size === 'large' }" :icon="icon" />
    </InputIcon>
    <InputText v-model="model" option-label="label" :placeholder="insetLabel" :name="name" class="w-full pr-9" />
  </IconField>
  <InputText
    v-else
    v-model="model"
    option-label="label"
    :placeholder="insetLabel"
    :name="name"
    class="w-full"
    :type="type"
    :required="required"
    :class="inputClass" />
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue"
import IconField from "primevue/iconfield"
import InputIcon from "primevue/inputicon"
import InputText from "primevue/inputtext"
import type { InputSize } from "@/components/input"
import { useInputClass } from "@/components/input"

const model = defineModel<string | null>()
const props = defineProps<{
  icon?: string
  insetLabel?: string
  name: string
  required?: boolean
  size?: InputSize
  type?: "email"
}>()
const inputClass = useInputClass(props)
</script>
