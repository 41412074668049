<template>
  <AppPageSection class="text-body-2 pb-8">
    <AppPageSectionHeader>Assets</AppPageSectionHeader>
    <AppPageSectionDescription>
      View and manage assets that you have registered on WEATS. Each of these represents either an energy-consuming asset or an energy intervention
      resulting in EACs. Examples of energy interventions include solar panels, batteries, demand response events, and buildings that underwent an
      energy efficiency upgrade.
      <LearnMore :href="`${WWW_BASE_URL}/faq#selling-eacs`" />
    </AppPageSectionDescription>
  </AppPageSection>
  <AppPageSection class="mt-2">
    <div class="flex flex-wrap items-end justify-between gap-2">
      <div class="flex grow flex-wrap items-center gap-3">
        <WcDropdown
          v-model="filterAssetStatus"
          class="shrink-0 basis-40"
          name="filterAssetStatus"
          :options="statusOptions"
          inset-label="Status"
          size="small" />
        <WcCalendarRange
          v-model="filterAssetCreatedDateRange"
          class="shrink-0 basis-40"
          :class="{ 'basis-56': (filterAssetCreatedDateRange || [])[1] != null }"
          name="filterAssetCreatedDateRange"
          inset-label="Created date"
          size="small" />
        <WcInputText
          v-model="filterAssetLocationKeyword"
          icon="wc-carbon:search"
          class="shrink-0 basis-48"
          name="filterAssetLocationKeyword"
          inset-label="Filter by location..."
          size="small" />
        <WcCTA
          class="shrink-0 basis-52 pl-1"
          icon-position="right"
          icon="wc-carbon:close-outline"
          text="Clear Filters"
          size="small"
          @click="handleClearFilters" />
      </div>
      <WcViewModeButton v-model="viewMode" class="ml-auto" size="small" />
    </div>
    <div>
      <div v-if="viewMode === ViewMode.table">
        <div class="mt-4 overflow-x-scroll pb-3">
          <AssetsTable
            :assets="assets"
            allow-multiple-selection
            :selected-row-ids="selectedAssetIds"
            show-price
            @row-clicked="handleRowClicked"
            @rows-selected="handleRowsSelected" />
          <PaginationButtons class="mt-3" :page-info="pageInfo" :is-disabled="isLoading" @load-items="loadAssets" />
        </div>
        <div v-if="selectedAssetIds.length" class="flex items-center justify-between">
          <div class="text-subheading-1">{{ selectedAssetIds.length }} selected</div>
          <div class="flex flex-wrap justify-end gap-3">
            <WcButton variant="outlined" text="Cancel" size="small" @click="handleClearSelection" />
            <WcButton text="List EACs for Sale" size="small" @click="handleListAssetEacsForSale" />
            <WcButton :is-disabled="!hasListedAssetsSelected" text="Unlist assets" size="small" @click="handleUnlistAssetEacs" />
          </div>
        </div>
      </div>
      <div v-if="viewMode === ViewMode.map && assetLocations?.length > 0">
        <div class="relative my-4 size-full h-[600px]">
          <WcMap :locations="assetLocations ?? []" class="mb-8" locations-are-precise>
            <template #popup="asset">
              <AssetPopup :asset="asset" />
            </template>
          </WcMap>
          <PaginationButtons :page-info="pageInfo" :is-disabled="isLoading" @load-items="loadAssets" />
        </div>
      </div>
    </div>
  </AppPageSection>
  <EmptyState v-if="!isLoading && !hasError && assetTotalCount === 0" title="No registered assets">
    <WcLink to="/assets/register" class="text-hyperlink">Submit assets for review</WcLink> to begin registering your assets on
    <a :href="`${WWW_BASE_URL}/weats`" target="_blank" class="text-hyperlink">WEATS</a> and selling EACs.
    <p class="text-body-2 mt-2">If you have recently submitted assets for review, a member of the WattCarbon team will email you with next steps.</p>
  </EmptyState>
  <InlineLoading v-if="isLoading" />
  <InlineError v-if="hasError" error-message="There was a problem loading assets. Please try again." />
</template>

<script setup lang="ts">
import { format } from "date-fns"
import { computed, onMounted, ref, watch } from "vue"
import { useRouter } from "vue-router"
import { WcButton, WcCTA } from "@/components/button"
import type { SelectOption } from "@/components/input"
import { ViewMode, WcCalendarRange, WcDropdown, WcInputText, WcViewModeButton } from "@/components/input"
import { AppPageSection, AppPageSectionDescription, AppPageSectionHeader } from "@/components/layout"
import EmptyState from "@/components/ui/EmptyState.vue"
import InlineError from "@/components/ui/InlineError.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import LearnMore from "@/components/ui/LearnMore.vue"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import WcLink from "@/components/ui/WcLink.vue"
import type { Location } from "@/components/WcMap/WcMap.utils"
import WcMap from "@/components/WcMap/WcMap.vue"
import type { Asset, AssetsSummary, AssetStatus } from "@common/models/asset"
import { ASSET_STATUS } from "@common/models/asset"
import type { PageInfo } from "@/services/base-fetcher"
import { useAssetService } from "@/services/service-container"
import { useMainStore } from "@/store"
import AssetPopup from "./components/AssetPopup.vue"
import AssetsTable from "./components/AssetsTable.vue"
import { debounce } from "@/utils/debounce"
import { getEnvironment } from "@/environment"

const { WWW_BASE_URL } = getEnvironment()

const router = useRouter()
const assetService = useAssetService()
const store = useMainStore()

const assets = ref<Asset[]>([])
const hasError = ref<boolean>(false)
const isLoading = ref<boolean>(true)
const pageInfo = ref<PageInfo>()
const viewMode = ref<ViewMode>(ViewMode.table)
const filterAssetStatus = ref<SelectOption<AssetStatus> | null>(null)
const filterAssetLocationKeyword = ref<string | null | undefined>(null)
const filterAssetCreatedDateRange = ref<Date[] | null | undefined>(null)

const statusOptions = Object.entries(ASSET_STATUS).map(([status, { summary }]) => ({ label: summary, value: status }))
const assetCountsByStatus = ref<AssetsSummary["countsByStatus"]>()
const assetTotalCount = ref<number>(0)
const selectedAssetIds = ref<number[]>([])

const loadAssets = async (url?: string) => {
  try {
    const createdDateStart = (filterAssetCreatedDateRange.value ?? [])[0]
    const createdDateEnd = (filterAssetCreatedDateRange.value ?? [])[1]
    const filters = {
      ...(filterAssetStatus.value && { status: filterAssetStatus.value.value }),
      ...(filterAssetLocationKeyword.value && { location: filterAssetLocationKeyword.value }),
      ...(createdDateStart && { createdDateStart: format(createdDateStart, "yyyy-MM-dd") }),
      ...(createdDateEnd && { createdDateEnd: format(createdDateEnd, "yyyy-MM-dd") }),
    }
    const result = await assetService.listAssets({ url, ...filters })
    assets.value = result.data
    pageInfo.value = result.pageInfo
    selectedAssetIds.value = []
  } catch (error) {
    hasError.value = true
    console.error("There was an error loading assets", error)
  }
  isLoading.value = false
}

const loadAssetsSummary = async () => {
  try {
    const result = await assetService.getAssetsSummary()
    assetCountsByStatus.value = result.countsByStatus
    assetTotalCount.value = result.assetCount
  } catch (error) {
    console.error("There was an error loading assets summary info", error)
  }
}

onMounted(async () => {
  loadAssetsSummary()
  loadAssets()
})

watch([filterAssetStatus], () => {
  loadAssets()
})

// Debounced text input
watch(
  [filterAssetCreatedDateRange, filterAssetLocationKeyword],
  debounce(() => {
    loadAssets()
  })
)
const assetLocations = computed<Location[]>(() => {
  return assets.value.reduce((acc, asset) => {
    const { coordinates, status } = asset
    return [
      ...acc,
      {
        ...coordinates,
        color: ASSET_STATUS[status].color,
        strokeColor: ASSET_STATUS[status].strokeColor,
        metadata: asset,
      },
    ]
  }, [] as Location[])
})

const hasListedAssetsSelected = computed<boolean>(() => {
  // TODO - selectedAssetIds and assets both being arrays makes getting the list of selected
  // assets a bit inefficient!
  return assets.value.some((asset) => asset.price && selectedAssetIds.value.includes(asset.id))
})

const handleRowClicked = (row: { id: number }, event: MouseEvent | KeyboardEvent) => {
  const path = `/assets/${row.id}`
  if (event.ctrlKey || event.metaKey || event.shiftKey) {
    window.open(path, "_blank")
  } else {
    router.push(path)
  }
}

const handleRowsSelected = (selectedRowIDs: number[]) => {
  selectedAssetIds.value = selectedRowIDs
}

const handleClearSelection = () => {
  selectedAssetIds.value = []
}

const handleListAssetEacsForSale = () => {
  store.assetsToUpdate = assets.value.filter(({ id }) => selectedAssetIds.value.includes(id))
  store.isUnlisting = false
  router.push({ name: "wc-assets-list-eacs-for-sale" })
}

const handleUnlistAssetEacs = () => {
  store.assetsToUpdate = assets.value.filter((asset) => asset.price && selectedAssetIds.value.includes(asset.id))
  store.isUnlisting = true
  router.push({ name: "wc-assets-list-eacs-for-sale" })
}

const handleClearFilters = () => {
  filterAssetStatus.value = null
  filterAssetLocationKeyword.value = null
  filterAssetCreatedDateRange.value = null
  loadAssets()
}
</script>
