<template>
  <AppPage>
    <AppPageHeader show-account-context>
      EAC Management
      <template #description>
        View, retire, and report on your Energy Attribute Certificates (EACs). Each one represents a unique claim to the environmental benefits of an
        energy decarbonization project. This includes EACs purchased from suppliers as well as any EACs that were minted from assets you've
        registered.
        <LearnMore :href="`${WWW_BASE_URL}/faq#eacs`" />
      </template>
    </AppPageHeader>

    <AppPageContent>
      <AppPageSection>
        <EacBalanceSummary :show-button="false" />
      </AppPageSection>

      <AppPageSection class="flex flex-col">
        <div class="border-t border-neutral-300"></div>
        <div class="my-4 flex w-full flex-row justify-between">
          <h3 class="text-subheading-large-bold">Select EACs by Date and Hour (UTC)</h3>
          <QuarterSelector :default-quarter="route.query.quarter as string" />
        </div>
        <EacHourGrid />
      </AppPageSection>

      <AppPageSection class="relative !mb-24 min-h-[352px]">
        <AppPageSectionHeader
          class="sticky top-0 z-10 flex w-full items-end justify-between border-b border-neutral-300 bg-white py-4"
          :class="selectedEacs.length ? '!-mb-12' : '!mb-0'">
          Selected EACs
          <button class="text-body-2-link" @click="eacStore.resetSelection()">Reset selection</button>
        </AppPageSectionHeader>
        <!-- The negative bottom margin for this element compensates for the empty space leftover by the sticky element's original placement -->
        <div
          class="sticky top-[calc(100%-64px)] z-10 -mb-8 flex h-16 w-full flex-wrap items-center justify-between gap-2 border-t border-neutral-300 bg-white">
          <div class="text-body-2 shrink-0">
            <div class="text-overline-1 text-blue-400">Selected total</div>
            <div class="block">{{ eacStore.totalSelectedQuantity.toLocaleString() }} Wh</div>
          </div>
          <div class="flex items-center gap-2">
            <ButtonCTA theme="light" :is-filled="true" :is-disabled="!canRetire" @click="handleClickRetireEACs()">Retire EACs</ButtonCTA>
            <ButtonCTA theme="light" :is-filled="false" :is-disabled="selectedEacs.length === 0" @click="handleDownloadCSV()">Download CSV</ButtonCTA>
          </div>
        </div>
        <div v-if="isLoadingCsv" class="flex h-36 items-center justify-center">
          <WcLoadingSpinner />
        </div>
        <div v-else-if="selectedEacs.length" class="relative overflow-x-scroll">
          <WcTable
            :data="selectedEacs"
            :columns="eacTableHeaders"
            row-id-field="EAC Start ID"
            table-class="mt-4 mb-10 text-body-2"
            tr-class="transition-colors hover:bg-neutral-200"
            td-class="whitespace-nowrap pr-2 py-0.5">
            <template #Status="{ row }">
              <span :class="{ 'text-error': isRetired(row) }">
                {{ row["Status"] }}
              </span>
            </template>
          </WcTable>
          <PaginationButtons class="mb-12" :page-info="pageInfo" @load-items="(url?: string) => loadCsv({ url })" />
        </div>
        <EmptyState v-else title="No EACs selected">
          To select EACs and view their data,<br />
          click or drag over colored cells in the chart above.
        </EmptyState>
      </AppPageSection>
    </AppPageContent>

    <WcModal
      class="positions-modal"
      :is-open="retireConfirmationIsVisible"
      size="small"
      :header="retireDialogTitle"
      @update:is-open="(isOpen) => (retireConfirmationIsVisible = isOpen)">
      <template #panel>
        <div v-if="retireRequestStatus < RetireRequestStatus.SUCCESS">
          <div class="relative">
            <!-- Make invisible to prevent modal from jumping during request -->
            <div :class="{ invisible: retireRequestStatus === RetireRequestStatus.PENDING }">
              <p class="text-subheading-1">You are about to retire {{ eacStore.totalSelectedActiveQuantity.toLocaleString() }} Wh of EACs.</p>
              <p class="text-body-1 mt-2">Once an EAC is retired, it can no longer be transferred to another owner. You cannot undo this action.</p>
              <p class="text-body-1 mt-2">
                <LearnMore :href="`${WWW_BASE_URL}/faq#carbon-accounting`">Learn more about retirement in our FAQ.</LearnMore>
              </p>
              <p class="text-body-1 mt-2">Click "Confirm" to retire the selected EACs.</p>
            </div>
            <!-- Absolute position to prevent modal size from jumping during request -->
            <div v-if="retireRequestStatus === RetireRequestStatus.PENDING" class="absolute left-0 top-0 w-full">
              <p class="text-subheading-1">Please wait. Your EACs are being retired.</p>
              <p class="text-body-1 mt-4">If you are retiring a large number of EACs, this may take a few minutes.</p>
            </div>
          </div>
        </div>
        <div v-else-if="retireRequestStatus === RetireRequestStatus.SUCCESS">
          <p class="text-body-1">Your EACs have been successfully retired in your WattCarbon account.</p>
        </div>
        <div v-else-if="retireRequestStatus === RetireRequestStatus.ERROR">
          <p class="text-body-1 mt-8">An error occurred while retiring your EACs.</p>
        </div>
      </template>
      <template #footer>
        <template v-if="retireRequestStatus < RetireRequestStatus.SUCCESS">
          <ButtonCTA theme="light" :is-disabled="retireRequestStatus === RetireRequestStatus.PENDING" @click="retireConfirmationIsVisible = false"
            >Cancel</ButtonCTA
          >
          <ButtonCTA
            theme="light"
            :is-filled="true"
            :is-disabled="retireRequestStatus === RetireRequestStatus.PENDING"
            @click="handleConfirmRetirement"
            >Confirm</ButtonCTA
          >
        </template>
        <ButtonCTA
          v-if="retireRequestStatus === RetireRequestStatus.SUCCESS || retireRequestStatus === RetireRequestStatus.ERROR"
          theme="light"
          class="ml-auto"
          :is-filled="true"
          @click="retireConfirmationIsVisible = false"
          >Close</ButtonCTA
        >
      </template>
    </WcModal>
  </AppPage>
</template>

<script setup lang="ts">
import { computed, ref, watch, watchPostEffect } from "vue"
import { useRoute } from "vue-router"
import { useMainStore } from "@/store"
import { AppPage, AppPageContent, AppPageSection, AppPageHeader, AppPageSectionHeader } from "@/components/layout"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import EacHourGrid from "./components/EacHourGrid.vue"
import QuarterSelector from "./components/QuarterSelector.vue"
import WcTable from "@/components/WcTable/WcTable.vue"
import { useEacStore, isRetired } from "./eac.state"
import posthog from "posthog-js"
import { CSVToArray } from "@common/services/utility.helper"
import { useRegistryService } from "@/services/service-container"
import _ from "lodash"
import WcLoadingSpinner from "@/components/WcLoadingSpinner.vue"
import { EacRetirementItem, EacRetirementCreate } from "@/services/api/registry.model"
import type { PageInfo } from "@/services/base-fetcher"
import { GetBalancesOptions } from "@/services/api/registry.service"
import EmptyState from "@/components/ui/EmptyState.vue"
import LearnMore from "@/components/ui/LearnMore.vue"
import WcModal from "@/components/WcModal.vue"
import { getEnvironment } from "@/environment"
import EacBalanceSummary from "./components/EacBalanceSummary.vue"

const { WWW_BASE_URL } = getEnvironment()

const captureDownloadEacsEvent = () => posthog.capture("Clicked on the 'Download CSV' button - Retire EACs View")
const captureClickRetireEvent = () => posthog.capture("Clicked on the 'Retire EACs' button to open confirmation - Retire EACs View")
const captureConfirmRetireEvent = () => posthog.capture("Clicked on the 'Confirm' button to confirm EAC Retirement - Retire EACs View")

const route = useRoute()
const store = useMainStore()
const eacStore = useEacStore()
const registryService = useRegistryService()

enum RetireRequestStatus {
  READY = 0,
  PENDING = 1,
  SUCCESS = 2,
  ERROR = 3,
}
const retireRequestStatus = ref<RetireRequestStatus>(RetireRequestStatus.READY)
const retireRequestError = ref<string>("")
const retireConfirmationIsVisible = ref(false)

const retireDialogTitle = computed(() => {
  switch (retireRequestStatus.value) {
    case RetireRequestStatus.PENDING:
      return ""
    case RetireRequestStatus.SUCCESS:
      return "Your EACs have been retired"
    case RetireRequestStatus.ERROR:
      return "An error occurred"
    default:
      return "Confirm EAC Retirement"
  }
})

const canRetire = computed(() => {
  return selectedEacs.value.length > 0 && eacStore.totalSelectedActiveQuantity > 0 && retireRequestStatus.value !== RetireRequestStatus.PENDING
})

watch(retireConfirmationIsVisible, (isVisible) => {
  if (!isVisible) retireRequestStatus.value = RetireRequestStatus.READY
})

// Parses the raw CSV data from the registry
const selectedEacs = ref<any[]>([])
const eacTableHeaders = ref<any[]>([])
const isLoadingCsv = ref<boolean>(false)
const timestampFieldName = "Generation/Savings Time"
const parseEacCsvData = (eacCsvData: any) => {
  const data1 = CSVToArray(eacCsvData, ",")
  // find the index of the first row that is an array of just one empty string
  // this is the row that separates the introductory matter from the headers and data
  const firstEmptyRowIndex = data1.findIndex((row) => row.length === 1 && row[0] === "")
  const headers: string[] = data1[firstEmptyRowIndex + 1]
  const rows = data1.slice(firstEmptyRowIndex + 2)
  // turn csv array into a javascript object using the first row as headers
  const records = rows
    .filter((row) => row.length > 0 && row[0] != "")
    .map((row) => {
      return row.reduce((obj, value, index) => {
        obj[headers[index]] = value
        return obj
      }, {} as any)
    })
    .sort((a: any, b: any) => {
      const aDate = new Date(a[timestampFieldName])
      const bDate = new Date(b[timestampFieldName])
      return aDate.getTime() - bDate.getTime()
    })
  return { records: records, headers: headers }
}

const rawCsvData = ref<string>("")
const pageInfo = ref<PageInfo>()
const loadCsv = async (options: GetBalancesOptions) => {
  isLoadingCsv.value = true
  selectedEacs.value = []
  pageInfo.value = undefined

  const result = await registryService.getBalances(options)

  rawCsvData.value = result.data
  pageInfo.value = result.pageInfo

  const { records, headers } = parseEacCsvData(rawCsvData.value)

  // get the "status" entry in the headers array and put it at the beginning
  const statusIndex = headers.indexOf("Status")
  if (statusIndex > 0) {
    headers.splice(statusIndex, 1)
    headers.unshift("Status")
  }

  selectedEacs.value = records
  eacTableHeaders.value = headers.map((header) => ({ key: header, label: header }))
  isLoadingCsv.value = false
}

const debouncedLoadCsv = _.debounce(async (selectedDateTimeRange) => {
  if (selectedDateTimeRange) {
    await loadCsv({ dateTimeRange: selectedDateTimeRange })
  } else {
    selectedEacs.value = []
  }
}, 500)

watchPostEffect(async () => {
  debouncedLoadCsv(eacStore.selectedDateTimeRange)
})

const handleClickRetireEACs = async () => {
  captureClickRetireEvent()
  retireConfirmationIsVisible.value = true
}

const quantityFieldName = "EAC Quantity"
const retireSelectedEacs = async () => {
  const certificates = selectedEacs.value
    .filter((eac) => !isRetired(eac)) // only retire EACs that are not already retired
    .map((eac) => {
      return {
        certificateId: eac["EAC Start ID"],
        quantity: parseInt(eac[quantityFieldName]),
      } as EacRetirementItem
    })
  const payload: EacRetirementCreate = { certificates }
  await registryService.retireEacs(payload)
  for (const eac of selectedEacs.value) {
    eac["Status"] = "retired"
  }
  await eacStore.fetchEacBalances()
}

const handleConfirmRetirement = async () => {
  captureConfirmRetireEvent()
  retireRequestStatus.value = RetireRequestStatus.PENDING
  const accountId = store.auth.user?.account_id
  try {
    if (!accountId) throw new Error("Your account ID is not available.")
    if (eacStore.totalSelectedActiveQuantity === 0) throw new Error("You have not selected any active EACs to retire.")
    await resolveWithMinimumTime(retireSelectedEacs()) // request takes a minimum of 1 second to show the loading state
    retireRequestStatus.value = RetireRequestStatus.SUCCESS
    retireConfirmationIsVisible.value = true // even if user closes modal, they will still see success message
  } catch (error: any) {
    retireRequestStatus.value = RetireRequestStatus.ERROR
    retireRequestError.value = error?.message
    retireConfirmationIsVisible.value = true // even if user closes modal, they will still see error message
  }
}

const handleDownloadCSV = () => {
  captureDownloadEacsEvent()
  // Generate CSV file from selectedEacs and download it
  if (!selectedEacs.value.length) return
  const csvContent = "data:text/csv;charset=utf-8," + rawCsvData.value
  const encodedUri = encodeURI(csvContent)
  const link = document.createElement("a")
  link.setAttribute("href", encodedUri)
  link.setAttribute("download", "eacs.csv")
  document.body.appendChild(link)
  link.click()
}

async function resolveWithMinimumTime(promise: Promise<any>, minimumTime = 1000) {
  const delay = new Promise((resolve) => setTimeout(resolve, minimumTime))
  return Promise.all([promise, delay]).then((results) => results[0])
}
</script>

<style lang="scss">
.positions-modal {
  .p-dialog-header,
  .p-dialog-content,
  .p-dialog-footer {
    @apply bg-blue-100;
  }
}
</style>
