<template>
  <BasePageSideNav
    :selected-asset-ids="selectedAssetIds"
    :apply-button-is-disabled="pageState.is8760Loading || pageState.isLoadshapeLoading"
    @update="onSideNavUpdate">
    <template #default>
      <section class="flex grow flex-col gap-4">
        <WcCard>
          <template #header>
            <div class="p-4">
              <div class="flex justify-between">
                <h4 class="inline text-lg font-semibold leading-6">Market Emissions</h4>
                <div class="ml-1 inline font-bold">
                  {{ format(sideNavFilters.startDate, "MMM yyyy") }} - {{ format(sideNavFilters.endDate, "MMM yyyy") }}
                </div>
              </div>
              <div class="text-sm">Power consumption and emissions of selected sites and assets</div>
            </div>
          </template>
          <div class="flex items-center justify-between gap-4 pb-4">
            <div class="flex w-1/4 flex-col items-center gap-6" data-cy="market-stats">
              <WcStat
                title="Net Carbon Emissions"
                :figure="`${stats.market.carbon.actual}`"
                unit="tCO2"
                :marginal-stats="stats.market.carbon.marginal"
                :loading="pageState.isLoadshapeLoading" />
              <WcStat
                title="Net Energy Consumption"
                :figure="`${stats.market.energy.actual}`"
                unit="MWh"
                :marginal-stats="stats.market.energy.marginal"
                :loading="pageState.isLoadshapeLoading" />
              <div class="w-full">
                <WcFuelMixBarChart :locational="data.loadshape8760.locationalGridFuelMixMwh" :market="data.loadshape8760.marketBasedVppFuelMixMwh" />
              </div>
            </div>
            <div class="mr-2 w-3/4 pb-4">
              <div class="flex justify-center">
                <div class="text-center font-bold">Percentage of carbon-free energy every hour</div>
              </div>
              <div v-if="pageState.is8760Loading" class="h-[350px]">
                <WcLoadingSpinner />
              </div>
              <WcFuelHeatMap v-else :data="data.loadshape8760.rows" :is-market="true" height="350" />
            </div>
          </div>
        </WcCard>

        <WcCard>
          <template #header>
            <div class="px-4 py-2">
              <div class="flex justify-between">
                <h4 class="inline text-lg font-semibold leading-6">Locational Emissions</h4>
                <div class="ml-1 inline font-bold">
                  {{ format(sideNavFilters.startDate, "MMM yyyy") }} - {{ format(sideNavFilters.endDate, "MMM yyyy") }}
                </div>
              </div>
              <div class="text-sm">Power consumption and emissions of selected sites and assets based on local power grids</div>
            </div>
          </template>
          <div class="flex items-center justify-between gap-4 pb-4">
            <div class="flex w-1/4 flex-col items-center gap-6" data-cy="locational-stats">
              <WcStat
                title="Carbon Emissions"
                :figure="`${stats.location.carbon.actual}`"
                unit="tCO2"
                :marginal-stats="stats.location.carbon.marginal"
                :loading="pageState.isLoadshapeLoading" />
              <WcStat
                title="Energy Consumption"
                :figure="`${stats.location.energy.actual}`"
                unit="MWh"
                :marginal-stats="stats.location.energy.marginal"
                :loading="pageState.isLoadshapeLoading" />
              <div class="w-full">
                <WcFuelMixBarChart :locational="data.loadshape8760.locationalGridFuelMixMwh" />
              </div>
            </div>
            <div class="mr-2 w-3/4 pb-4">
              <div class="flex justify-center">
                <div class="text-center font-bold">Percentage of carbon-free energy every hour</div>
              </div>
              <div v-if="pageState.is8760Loading" class="h-[350px]">
                <WcLoadingSpinner />
              </div>
              <WcFuelHeatMap v-else :data="data.loadshape8760.rows" height="350" />
            </div>
          </div>
        </WcCard>
      </section>
    </template>
  </BasePageSideNav>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from "vue"
import { useSitesStore } from "@/store/sites"
import { useAccountingStore } from "./accounting.state"
import { LoadshapeAssetActualsQueryRequest, LoadshapeSummaryQueryRequest, QueryGroupByType } from "@common/models/loadshapeQueryRequest"
import WcFuelMixBarChart from "./components/WcFuelMixBarChart.vue"
import WcFuelHeatMap from "./components/WcFuelHeatMap.vue"
import WcStat from "@/components/WcStat.vue"
import { addMonths, format } from "date-fns"
import WcLoadingSpinner from "@/components/WcLoadingSpinner.vue"
import { Loadshape } from "@common/models/loadshape"
import BasePageSideNav from "@/components/layout/BasePageSideNav.vue"
import { SideNavFilters } from "@/components/layout/layout.model"

import { useQueryService } from "@/services/service-container"
import { formatNumericalFigure } from "../dashboard/dashboard.helper"
import WcCard from "@/components/WcCard.vue"

const sitesStore = useSitesStore()
const accountingStore = useAccountingStore()
const queryService = useQueryService()

const pageState = reactive({
  error: undefined,
  isLoadshapeLoading: false,
  is8760Loading: false,
})
const selectedAssetIds = ref(new Array<number>())
const sideNavFilters = ref(new SideNavFilters())

const data: any = reactive({
  loadshape8760: Loadshape.GetEmptyLoadshape(),
  loadshape: Loadshape.GetEmptyLoadshape(),
  loadshapeVs: Loadshape.GetEmptyLoadshape(),
})

// TODO: consolidate MarginalStat type with the type in WcStat
type MarginalStat = { text: string; percentChange: number; directionChange: number; class: string }
const stats = computed(() => {
  const locationalCarbon = data.loadshape.locationalCarbonSumKgCo2 / 1000 || 0
  const locationalEnergy = data.loadshape.locationalEnergySumMwh || 0
  const marketCarbon = data.loadshape.marketBasedCarbonSumKgCo2 / 1000 || 0
  const marketEnergy = data.loadshape.marketBasedEnergySumMwh || 0

  const marketVsLocationCarbon = Math.round(((marketCarbon - locationalCarbon) / locationalCarbon) * 100) || 0
  const marketVsLocationEnergy = Math.round(((marketEnergy - locationalEnergy) / locationalEnergy) * 100) || 0

  const result = {
    market: {
      carbon: {
        actual: formatNumericalFigure(marketCarbon),
        marginal: [
          {
            text: `${marketVsLocationCarbon}% vs Locational`,
            directionChange: marketVsLocationCarbon > 0 ? 1 : -1,
            class: "text-blue-500 dark:text-dark-blue-300 font-semibold",
          },
        ] as MarginalStat[],
      },
      energy: {
        actual: formatNumericalFigure(marketEnergy),
        marginal: [
          {
            text: `${marketVsLocationEnergy}% vs Locational`,
            directionChange: marketVsLocationEnergy >= 0 ? 1 : -1,
            class: "text-blue-500 dark:text-dark-blue-300 font-semibold",
          },
        ] as MarginalStat[],
      },
    },
    location: {
      carbon: {
        actual: formatNumericalFigure(locationalCarbon),
        marginal: [] as MarginalStat[],
      },
      energy: {
        actual: formatNumericalFigure(locationalEnergy),
        marginal: [] as MarginalStat[],
      },
    },
  }

  const previousPeriodYear = "Last Year" //format(addMonths(sideNavFilters.value.endDate, -12), "yyyy")
  const locationalCarbonLastYear = data.loadshapeVs.locationalCarbonSumKgCo2 / 1000 || 0
  const locationalEnergyLastYear = data.loadshapeVs.locationalEnergySumMwh || 0
  const marketCarbonLastYear = data.loadshapeVs.marketBasedCarbonSumKgCo2 / 1000 || 0
  const marketEnergyLastYear = data.loadshapeVs.marketBasedEnergySumMwh || 0
  const marketYoYCarbon = Math.round(((marketCarbon - marketCarbonLastYear) / marketCarbonLastYear) * 100) || 0
  const marketYoYEnergy = Math.round(((marketEnergy - marketEnergyLastYear) / marketEnergyLastYear) * 100) || 0
  const locationalYoYCarbon = Math.round(((locationalCarbon - locationalCarbonLastYear) / locationalCarbonLastYear) * 100) || 0
  const locationalYoYEnergy = Math.round(((locationalEnergy - locationalEnergyLastYear) / locationalEnergyLastYear) * 100) || 0
  result.market.carbon.marginal.unshift({
    text: `${marketYoYCarbon}% vs ${previousPeriodYear}`,
    directionChange: marketYoYCarbon >= 0 ? 1 : -1,
    class: "text-blue-800 dark:text-dark-blue-200",
  } as MarginalStat)
  result.market.energy.marginal.unshift({
    text: `${marketYoYEnergy}% vs ${previousPeriodYear}`,
    directionChange: marketYoYEnergy >= 0 ? 1 : -1,
    class: "text-blue-800 dark:text-dark-blue-200",
  } as MarginalStat)
  result.location.carbon.marginal.unshift({
    text: `${locationalYoYCarbon}% vs ${previousPeriodYear}`,
    directionChange: locationalYoYCarbon >= 0 ? 1 : -1,
    class: "text-blue-800 dark:text-dark-blue-200",
  } as MarginalStat)
  result.location.energy.marginal.unshift({
    text: `${locationalYoYEnergy}% vs ${previousPeriodYear}`,
    directionChange: locationalYoYEnergy >= 0 ? 1 : -1,
    class: "text-blue-800 dark:text-dark-blue-200",
  } as MarginalStat)

  return result
})

const onSideNavUpdate = async (assetIds: Array<number>, filters: SideNavFilters) => {
  selectedAssetIds.value = assetIds
  sideNavFilters.value = filters
  await updateGraphData()
}

const updateGraphData = async () => {
  const sourceIds = selectedAssetIds.value
  const startDate = sideNavFilters.value.startDate
  const endDate = sideNavFilters.value.endDate
  pageState.isLoadshapeLoading = true
  pageState.is8760Loading = true

  const request = new LoadshapeSummaryQueryRequest(sourceIds, startDate, endDate)
  request.groupBy = QueryGroupByType.Total

  // One year previous period.
  const requestVs = new LoadshapeAssetActualsQueryRequest(sourceIds, addMonths(startDate, -12), addMonths(endDate, -12))
  requestVs.groupBy = QueryGroupByType.Total
  try {
    const [loadshapeResult, loadshapeVsResult] = await Promise.all([
      queryService.getLoadshapeGroupedSummary(request),
      queryService.getLoadshapeGroupedSummary(requestVs),
    ])
    data.loadshape = loadshapeResult
    data.loadshapeVs = loadshapeVsResult
  } finally {
    pageState.isLoadshapeLoading = false
  }

  // 8760
  const loadshape8760Request = new LoadshapeAssetActualsQueryRequest(sourceIds, startDate, endDate)
  loadshape8760Request.groupBy = QueryGroupByType.DateTime
  // Don't use await so that this query doesn't block the rest.
  // const result = await queryService.getLoadshape(queries.cleanPower.request)
  // TODO I would like this to start first but on dev it looks like this ends up blocking, possibly at the web server or possibly at the DB
  queryService
    .getLoadshape(loadshape8760Request)
    .then((loadshape) => {
      data.loadshape8760 = loadshape
      // data.locationalFuelMix = loadshape.locationalGridFuelMixPercent
      // data.marketBasedGridFuelMix = loadshape.marketBasedGridCarbonBasedFuelMixPercent
      // data.marketBasedVppFuelMix = loadshape.marketBasedVppFuelMixMwh
    })
    .finally(() => {
      pageState.is8760Loading = false
    })
}

onMounted(async () => {
  accountingStore.resetSelectedDateFilter()
  accountingStore.resetSites()
})

watch(
  () => sitesStore.sites,
  async () => {
    // If assets have been removed redo the graph
    const assetIds = sitesStore.assetIds
    const updatedAssetIds = selectedAssetIds.value.filter((id) => assetIds.includes(id))

    const doUpdateGraph = updatedAssetIds.length !== selectedAssetIds.value.length
    selectedAssetIds.value = updatedAssetIds
    if (doUpdateGraph) {
      await updateGraphData()
    }
  }
)
</script>
