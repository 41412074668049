<template>
  <div class="flex flex-wrap gap-4">
    <SelectResourceType v-model="marketStore.listingFilters.deviceKind" class="basis-12" name="deviceKind" />
    <SelectUsState v-model="marketStore.listingFilters.state" class="basis-12" name="state" />
    <SelectDispatchDate v-model="marketStore.listingFilters.quarter" class="basis-12" name="quarter" />
  </div>
</template>

<script setup lang="ts">
import SelectResourceType from "@/modules/market/components/filter/SelectResourceType.vue"
import SelectDispatchDate from "@/modules/market/components/filter/SelectDispatchDate.vue"
import SelectUsState from "@/modules/market/components/filter/SelectUsState.vue"
import { useMarketStore } from "@/modules/market/market.state"

const marketStore = useMarketStore()
</script>
