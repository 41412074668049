import ApiFetcher from "@/services/api-fetcher"
import { Project } from "@common/models/project"

export interface ProjectFilter {
  accountId?: number
  portfolioId?: number
}

export default class ProjectService {
  fetcher: ApiFetcher

  constructor(fetcher: ApiFetcher) {
    this.fetcher = fetcher
  }

  async listProjectsAdmin(filters: ProjectFilter): Promise<Array<Project>> {
    return await this.fetcher.httpGet<Array<Project>>("/projects/admin", filters)
  }
}
