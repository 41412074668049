<template>
  <div>
    <!-- Payment form -->
    <form id="payment-form" @submit.prevent="submitPayment">
      <div id="payment-element">
        <!-- Elements will create form elements here -->
      </div>
      <div class="mt-4">
        <input id="agreement" v-model="isAgreementChecked" type="checkbox" />
        <label for="agreement" class="text-body-2 inline">
          I acknowledge that I have read and agree to the
          <a :href="`${WWW_BASE_URL}/terms`" class="text-hyperlink" target="_blank">terms and conditions</a>.
        </label>
      </div>
      <div v-if="errorMessage" class="text-body-2 mt-6 text-error">
        {{ errorMessage }}
      </div>
      <ButtonCTA
        width="full"
        class="mb-12 mt-6 flex w-full justify-center"
        button-classes="bg-blue-500 text-white hover:bg-blue-300 ph-no-capture"
        :is-disabled="isSubmitButtonDisabled"
        data-cy="continue-to-agreement"
        @click="submitPayment"
        >Continue</ButtonCTA
      >
    </form>
  </div>
</template>

<script setup lang="ts">
import { StripeElements } from "@stripe/stripe-js"
import { computed, onMounted, ref } from "vue"
import { useRouter } from "vue-router"
import { useMarketStore } from "@/modules/market/market.state"
import { useStripe, useListingService } from "@/services/service-container"
import { getEnvironment } from "@/environment"
import posthog from "posthog-js"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import { gtmService } from "@/services/gtm.service"

const stripe = await useStripe()
const listingService = useListingService()
const marketStore = useMarketStore()
const router = useRouter()

const props = defineProps({
  procurement: { type: Object, required: true },
})

let elements: StripeElements

const { WWW_BASE_URL } = getEnvironment()

const errorMessage = ref("")
const isPaymentFormComplete = ref(false)
const isAgreementChecked = ref(false)
const isPaymentPending = ref(false)
const isSubmitButtonDisabled = computed(() => {
  return !isPaymentFormComplete.value || !isAgreementChecked.value || isPaymentPending.value
})
const captureCompletePurchaseEvent = () => posthog.capture("Clicked on the 'I Agree & Complete Purchase' button - Listing Checkout View")

onMounted(() => {
  elements = stripe.elements({
    mode: "payment",
    amount: props.procurement.pricePenniesUsd,
    currency: "usd",
  })
  const paymentElement = elements.create("payment")
  paymentElement.mount("#payment-element")
  paymentElement.on("change", (event) => {
    isPaymentFormComplete.value = event.complete
  })
})

const handleError = (message: string) => {
  errorMessage.value = message
  isPaymentPending.value = false
}

// https://stripe.com/docs/payments/finalize-payments-on-the-server-confirmation-tokens
const submitPayment = async () => {
  if (isSubmitButtonDisabled.value) return

  isPaymentPending.value = true

  const { error: submitError } = await elements.submit()
  if (submitError) {
    handleError(submitError.message || submitError.type)
    return
  }

  // https://stripe.com/docs/payments/finalize-payments-on-the-server-confirmation-tokens?platform=web&type=payment#create-ct
  // @ts-ignore Ignoring because this is missing from Stripe's exported types https://github.com/stripe/stripe-js/pull/488
  const { error, confirmationToken } = await stripe.createConfirmationToken({ elements })
  if (error) {
    handleError(error)
    return
  }

  let response
  try {
    response = await listingService.submitListingProcurement(props.procurement, confirmationToken.id)
  } catch (err) {
    handleError(err as string)
    return
  }

  // https://stripe.com/docs/payments/finalize-payments-on-the-server-confirmation-tokens?platform=web&type=payment#next-actions
  if (response.status == "requires_action") {
    const { error } = await stripe.handleNextAction({ clientSecret: response.clientSecret })
    if (error) {
      handleError(error.message ?? "An unknown error occurred")
      return
    }
  }

  if (response.status == "succeeded") {
    captureCompletePurchaseEvent()
    gtmService.trackEvent("conversion", {
      action: "Purchase",
      category: "Listing",
      currency: "USD",
      label: `Payment ID: ${response.paymentId}`,
      value: props.procurement.pricePenniesUsd / 100,
    })
    marketStore.clearCheckout()
    router.push({ name: "wc-listing-confirmation", params: { paymentId: response.paymentId } })
  }
}
</script>
