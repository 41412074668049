<template>
  <main class="overflow-auto" :class="[store.isDark ? 'bg-blue-500' : 'bg-neutral-200']">
    <LayoutHeader />
    <section class="mb-4 flex w-full justify-center">
      <slot />
    </section>
    <div class="invisible h-0">Build #{{ appVersion }}</div>
  </main>
</template>

<script setup lang="ts">
import LayoutHeader from "@/components/layout/LayoutHeader.vue"
import { useMainStore } from "@/store"
const store = useMainStore()

// eslint-disable-next-line no-undef
const appVersion = __APP_VERSION__
</script>
