<template>
  <section class="flex justify-between gap-4">
    <div class="sticky top-4 flex h-[calc(100vh-8rem)] w-1/4 flex-col rounded-md bg-white shadow-md">
      <div class="flex h-full flex-col">
        <div class="border-b border-neutral-500 py-2">
          <div class="text-gray-600 relative mx-auto px-2 pt-2">
            <input
              v-model="nameFilter"
              class="border-gray-300 mb-2 h-10 rounded-lg border-2 bg-white px-5 pr-16 text-sm focus:outline-none"
              type="search"
              :disabled="adminPortfoliosStore.isLoading"
              placeholder="Filter portfolios" />
          </div>
          <label class="my-2 flex flex-row items-center gap-4 px-4">
            Status
            <select v-model="statusFilter" class="p-2">
              <option :value="null">Any</option>
              <option v-for="[key, value] of Object.entries(PortfolioStatusType)" :key="value" :value="value">{{ key }}</option>
            </select>
          </label>
        </div>
        <div class="h-full grow overflow-y-scroll">
          <Icon v-if="adminPortfoliosStore.isLoading" icon="mdi:loading" class="mx-auto my-8 size-20 animate-spin" />
          <div class="grow" data-cy="portfolios-list">
            <p v-if="filteredPortfolios.length === 0" class="px-4 py-2 text-neutral-500">No portfolios match filters</p>
            <router-link
              v-for="p in filteredPortfolios"
              :key="p.id"
              :to="{ name: 'wc-admin-portfolio', params: { portfolioId: p.id } }"
              class="flex w-full cursor-pointer justify-between px-4 py-2 text-left text-sm font-medium hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-300/75">
              <div class="flex w-full flex-col justify-between">
                <div class="flex w-full justify-between">
                  <span class="inline-block overflow-x-hidden text-ellipsis pr-2">{{ p.name }}</span>
                  <span class="inline-block">{{ p.id }}</span>
                </div>
                <div class="flex w-full justify-between font-mono text-neutral-500">
                  {{ p.displayId }}
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="border-t-2 border-neutral-300">
          <router-link
            :to="{ name: 'wc-admin-portfolio-new' }"
            class="flex w-full flex-row items-center justify-end p-4 disabled:cursor-not-allowed disabled:text-neutral-500">
            <Icon icon="wc-carbon:add" class="mr-2" />
            New Portfolio
          </router-link>
        </div>
      </div>
    </div>
    <div class="flex w-full flex-col">
      <router-view v-if="!adminPortfoliosStore.isLoading"></router-view>
      <Icon v-else icon="mdi:loading" class="mx-auto my-8 size-20 animate-spin" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { Icon } from "@iconify/vue"
import { useAdminPortfoliosStore } from "@/modules/admin/adminPortfolios.state"
import { PortfolioStatusType } from "@common/models/order"

const adminPortfoliosStore = useAdminPortfoliosStore()

const nameFilter = ref<string>("")
const statusFilter = ref<PortfolioStatusType | null>(null)
const filteredPortfolios = computed(() => {
  return adminPortfoliosStore.portfolios
    .filter((p) => p.name.toLowerCase().indexOf(nameFilter.value.toLowerCase()) !== -1)
    .filter((p) => statusFilter.value === null || p.status == statusFilter.value)
})
</script>

<style scoped lang="scss">
.router-link-active {
  @apply bg-neutral-300;
}
</style>
