import ApiFetcher from "@/services/api-fetcher"
import type { Asset, AssetDocument, AssetKind, AssetPrice, AssetsSummary, AssetStatus, Implementer, PublicAsset } from "@common/models/asset"
import { AxiosError } from "axios"
import type { AssetTimeSeriesUploadError, AssetTimeSeriesUploadInput, TimeSeriesEntry } from "./assetTimeSeries.state"

type ApiAssetsSummary = {
  countsByStatus: Record<AssetStatus, number>
  deviceCount: number
}

export type AssetUploadError = {
  loc: string[]
  msg: string
  type: string
}

export type AssetUploadResult = {
  device: Asset | null
  errors: AssetUploadError[] | null
  line: number
}

type AssetUploadGlobalErrorResult = {
  detail: string
}

export type AssetTimeSeriesUploadResult = TimeSeriesEntry[]

type AssetTimeSeriesUploadGlobalErrorResult = {
  detail: string | AssetTimeSeriesUploadError[]
}

export default class AssetService {
  fetcher: ApiFetcher
  constructor(fetcher: ApiFetcher) {
    this.fetcher = fetcher
  }

  async getAsset(assetId: number): Promise<Asset | undefined> {
    return await this.fetcher.httpGet<Asset>(`/devices/${assetId}`, {})
  }

  async getAssetAdmin(assetId: number): Promise<Asset> {
    return await this.fetcher.httpGet<Asset>(`/devices/${assetId}/admin`, {})
  }

  async listAssets(options: { status?: AssetStatus; url?: string }) {
    const { url, ...queryOptions } = options
    if (url) {
      // strip the domain portion off the url as the fetcher will add it back
      const pathIndex = url.indexOf("/devices")
      const parsedUrl = url.slice(pathIndex)
      return await this.fetcher.httpGetPaginated<Asset[]>(parsedUrl, queryOptions)
    }
    return await this.fetcher.httpGetPaginated<Asset[]>(`/devices`, queryOptions)
  }

  async listAssetsAdmin(options: { status?: AssetStatus; url?: string }) {
    const { url, ...queryOptions } = options
    if (url) {
      // strip the domain portion off the url as the fetcher will add it back
      const pathIndex = url.indexOf("/devices/admin")
      const parsedUrl = url.slice(pathIndex)
      return await this.fetcher.httpGetPaginated<Asset[]>(parsedUrl, queryOptions)
    }
    return await this.fetcher.httpGetPaginated<Asset[]>(`/devices/admin`, queryOptions)
  }

  async listAssetsPublic(options: { storyId?: number; url?: string }) {
    const { url, ...queryOptions } = options
    if (url) {
      // strip the domain portion off the url as the fetcher will add it back
      const pathIndex = url.indexOf("/devices/public")
      const parsedUrl = url.slice(pathIndex)
      return await this.fetcher.httpGetPaginated<PublicAsset[]>(parsedUrl, queryOptions)
    }
    return await this.fetcher.httpGetPaginated<PublicAsset[]>(`/devices/public`, queryOptions)
  }

  async getAssetImplementers(): Promise<Implementer[]> {
    return await this.fetcher.httpGet("/attestations/implementers", {})
  }

  async submitAssetRegistration(attestation: any) {
    return await this.fetcher.httpPost("/attestations", attestation)
  }

  async submitAssetReview(assetId: number, review: { status: AssetStatus; reviewNotesExternal?: string; reviewNotesInternal?: string }) {
    return await this.fetcher.httpPost(`/devices/${assetId}/reviews`, review)
  }

  async getAssetsSummary(): Promise<AssetsSummary> {
    const apiAssetsSummary = await this.fetcher.httpGet<ApiAssetsSummary>("/devices/summary", {})
    const { countsByStatus, deviceCount: assetCount } = apiAssetsSummary
    return { assetCount, countsByStatus }
  }

  async getAssetsAdminSummary(): Promise<AssetsSummary> {
    const apiAssetsSummary = await this.fetcher.httpGet<ApiAssetsSummary>("/devices/admin/summary", {})
    const { countsByStatus, deviceCount: assetCount } = apiAssetsSummary
    return { assetCount, countsByStatus }
  }

  async uploadAssetDocument(assetId: number, data: Partial<AssetDocument>, fileData: File) {
    return await this.fetcher.httpUpload<AssetDocument>(`/devices/${assetId}/documents`, data, fileData)
  }

  async listAssetDocuments(assetId: number) {
    return await this.fetcher.httpGet<AssetDocument[]>(`/devices/${assetId}/documents`, {})
  }

  async getAssetDocument(assetId: number, documentId: number) {
    return await this.fetcher.httpGet<AssetDocument>(`/devices/${assetId}/documents/${documentId}`, {})
  }

  async deleteAssetDocument(assetId: number, documentId: number) {
    return await this.fetcher.httpDelete(`/devices/${assetId}/documents/${documentId}`, {})
  }

  async setAssetPrice(assetId: number, priceUpdate: AssetPrice) {
    return await this.fetcher.httpPut(`/devices/${assetId}/price`, priceUpdate)
  }

  async deleteAssetPrice(assetId: number) {
    return await this.fetcher.httpDelete(`/devices/${assetId}/price`)
  }

  async setAssetPriceBulk(assetIds: number[], priceUpdate: AssetPrice) {
    const update = assetIds.map((assetId) => {
      return {
        deviceId: assetId,
        op: "setPrice",
        price: priceUpdate,
      }
    })
    return await this.fetcher.httpPatch(`/devices`, update)
  }

  async deleteAssetPriceBulk(assetIds: number[]) {
    const update = assetIds.map((assetId) => {
      return {
        deviceId: assetId,
        op: "deletePrice",
      }
    })
    return await this.fetcher.httpPatch(`/devices`, update)
  }

  async setAssetStoryBulkAdmin(assetIds: number[], storyId: number | null) {
    const update = assetIds.map((assetId) => {
      return {
        deviceId: assetId,
        op: "setStory",
        story: {
          storyId,
        },
      }
    })
    return await this.fetcher.httpPatch(`/devices/admin`, update)
  }

  async validateUploadedAssets(kind: AssetKind, fileData: File): Promise<AssetUploadResult[] | AssetUploadGlobalErrorResult> {
    try {
      return await this.fetcher.httpUpload(`/devices/csv`, { kind, validateOnly: true }, fileData)
    } catch (error) {
      const axiosError = error as AxiosError
      if (axiosError.code === AxiosError.ERR_BAD_REQUEST) {
        return (axiosError.response?.data || []) as AssetUploadResult[] | AssetUploadGlobalErrorResult
      }
      return { detail: "There was an error processing this file. Please try again." }
    }
  }

  async uploadAssets(kind: AssetKind, fileData: File): Promise<AssetUploadResult[]> {
    return await this.fetcher.httpUpload<AssetUploadResult[]>(`/devices/csv`, { kind, validateOnly: false }, fileData)
  }

  async validateUploadedAssetTimeSeries(
    props: AssetTimeSeriesUploadInput
  ): Promise<AssetTimeSeriesUploadResult | AssetTimeSeriesUploadGlobalErrorResult> {
    const { assetId, fileData, timezone, ...other } = props
    try {
      return await this.fetcher.httpUpload(`/devices/${assetId}/timeseries`, { timezone, validate_only: true, ...other }, fileData)
    } catch (error) {
      const axiosError = error as AxiosError
      if (axiosError.code === AxiosError.ERR_BAD_REQUEST) {
        return (axiosError.response?.data || []) as AssetTimeSeriesUploadResult | AssetTimeSeriesUploadGlobalErrorResult
      }
      return { detail: "There was an error processing this file. Please try again." }
    }
  }

  async uploadAssetTimeSeries(props: AssetTimeSeriesUploadInput): Promise<AssetTimeSeriesUploadResult> {
    const { assetId, fileData, timezone, ...other } = props
    return await this.fetcher.httpUpload(`/devices/${assetId}/timeseries`, { timezone, validate_only: false, ...other }, fileData)
  }
}
