import { colors } from "@common/global"
import { LoadshapeRow, Loadshape } from "@common/models/loadshape"
import { LoadshapeQueryRequest, QueryGroupByType } from "@common/models/loadshapeQueryRequest"
import { format, startOfMonth } from "date-fns"

export enum VizDateFormat {
  hour = "HH",
  monthYear = "MMM yyyy",
  monthHour = "MMM HH yyyy",
}

export class VizHelper {
  static minValues = [0, -2.5, -5, -10, -25, -50, -100, -250, -500, -1000, -2500, -5000, -10000, -25000, -100000, -1000000, -10000000]
  static maxValues = VizHelper.minValues.map((v: number) => Math.abs(v))
  static isSameLoadshape = (request: LoadshapeQueryRequest, sourceIds: Array<number>) => {
    return request.sourceIds.length === sourceIds.length && sourceIds.every((value, index) => value === request.sourceIds[index])
  }

  static isSameLoadshapeRequest = (
    request: LoadshapeQueryRequest,
    sourceIds: Array<number>,
    startDate: string,
    endDate: string,
    groupBy: QueryGroupByType = request.groupBy
  ) => {
    const isSameSourceIds = request.sourceIds.length === sourceIds.length && sourceIds.every((value, index) => value === request.sourceIds[index])
    const isSameDate = request.startDate === startDate && request.endDate === endDate
    const isSameGroup = request.groupBy === groupBy
    return isSameSourceIds && isSameDate && isSameGroup
  }

  static addPatterns(svg: any) {
    svg
      .append("defs")
      .append("pattern")
      .attr("id", "diagonal")
      .attr("patternUnits", "userSpaceOnUse")
      .attr("width", 8)
      .attr("height", 8)
      .append("path")
      .attr("d", "M-1,1 l2,-2 M0,8 l8,-8 M7,9 l4,-4") //
      .attr("fill", colors.white)
      .attr("stroke", colors.highlight)
      .attr("stroke-width", 1)

    return svg
  }

  static getStackedBarData(isEnergy = true) {
    const data = new StackedBarData()
    data.name = isEnergy ? "MWh" : "tCO₂e"
    if (!isEnergy) {
      data.barOptions.colors = [colors.neutral["500"], colors.neutral["500"], colors.neutral["500"], "url(#diagonal)"]
      data.barOptions.opacity = [1, 1, 1, 1]
    }
    return data
  }

  static getStackedBarDataSetItems(
    rows: Array<LoadshapeRow>,
    isConsumed = true,
    isEnergy = true,
    groupBy = VizDateFormat.monthYear
  ): Array<DateDataItem> {
    const field = isConsumed ? "consumed" : "avoided"
    const fieldValue = isEnergy ? `${field}EnergySumMwh` : `${field}CarbonSumTonnesCo2e`
    return rows.map((h: LoadshapeRow) => {
      return {
        date: h.datetime,
        label: format(h.datetime, groupBy),
        value: Math.abs(h[fieldValue]),
      } as DateDataItem
    })
  }

  static listChartDataItems(rows: Array<LoadshapeRow>, isConsumed = true, isEnergy = true, labelField: string): Array<DateDataItem> {
    const field = isConsumed ? "consumed" : "avoided"
    const fieldValue = isEnergy ? `${field}EnergySumMwh` : `${field}CarbonSumTonnesCo2e`
    return rows.map((h: LoadshapeRow) => {
      return {
        label: h[labelField],
        value: Math.abs(h[fieldValue]),
      } as DateDataItem
    })
  }

  static getStackedBars(
    loadshape: Loadshape,
    previewLoadshape: Loadshape,
    highlightLoadshape: Loadshape,
    isConsumed = false,
    isEnergy = true,
    groupBy = VizDateFormat.monthYear
  ) {
    const currentYearMonth = new Date()
    const loadshapeHours = VizHelper.getStackedBarDataSetItems(loadshape.rows, isConsumed, isEnergy, groupBy)
    const actual = loadshapeHours.filter((o) => o.date <= currentYearMonth)
    const forecast = loadshapeHours.filter((o) => o.date > currentYearMonth)
    const preview = VizHelper.getStackedBarDataSetItems(previewLoadshape.rows, isConsumed, isEnergy, groupBy)
    const highlight = VizHelper.getStackedBarDataSetItems(highlightLoadshape.rows, isConsumed, isEnergy, groupBy)
    const xAxis = loadshapeHours.map((h) => h.label)
    return xAxis
      .map((c) => {
        const o = actual.find((o) => o.label === c)?.value
        const of = forecast.find((o) => o.label === c)?.value
        const op = preview.find((o) => o.label === c)?.value
        const os = highlight.find((o) => o.label === c)?.value

        let oValue = (o ?? 0) - (op ?? 0) - (os ?? 0)
        let ofValue = (of ?? 0) - (op ?? 0) - (os ?? 0)
        oValue = oValue > 0 ? oValue : 0
        ofValue = ofValue > 0 ? ofValue : 0
        return {
          label: c,
          highlight: os ? os : null,
          actual: o ? oValue : null,
          forecast: of ? ofValue : null,
          preview: op ? op : null,
          total: (o ?? 0) > (of ?? 0) ? o : of,
        }
      })
      .filter((v) => {
        // must have at least 1 non-null value
        return v.highlight || v.actual || v.forecast || v.preview
      })
  }

  static getMinAxisTick = (min: number, range: Array<number>): number => {
    if (min >= 0) {
      return 0
    }
    return range.find((v: number) => v <= min) || min
  }

  static getMaxAxisTick = (max: number, range: Array<number>): number => {
    if (max <= 0) {
      return 0
    }
    return range.find((v: number) => v >= max) || max
  }

  static getAxisTicks = (minIn: number, maxIn: number, tickCount: number) => {
    const min = VizHelper.getMinAxisTick(minIn, VizHelper.minValues)
    const max = VizHelper.getMaxAxisTick(maxIn, VizHelper.maxValues)
    let tickCountAdjust = 0
    // It is really hard to get the ticks exactly right with all the rounding. typically adjusting the min or max will bust the ticks.
    if (min !== minIn) {
      tickCountAdjust += 1
    }
    if (max !== maxIn) {
      tickCountAdjust += 1
    }

    // There are particular problems when the ticks are above and below zero.
    if (min < 0 && max > 0) {
      tickCountAdjust -= 1
    }
    let tickLength = (max - min) / (tickCount - tickCountAdjust)
    let tens = 0
    let roundLength = tickLength
    let count = 0
    const maxCount = 20
    while (roundLength > 10 && count < maxCount) {
      tens += 1
      roundLength = roundLength / 10
      count++
    }
    const rounder = Math.pow(10, tens)
    tickLength = (tickLength / rounder) * rounder
    const ticks = []
    const roundDigits = tickLength < 1 ? 1 : 0
    let tick = Number(max.toFixed(roundDigits))
    count = 0
    while (tick > 0 && count < maxCount) {
      ticks.push(tick)
      tick = Number((tick - tickLength).toFixed(roundDigits))
      // TODO for the last tick make sure it isn't inside the ticklength, just use 0
      if (tick < tickLength) {
        tick = 0
      }
      count++
    }

    tick = min
    count = 0
    while (tick < 0 && count < maxCount) {
      ticks.unshift(tick)
      tick = Number((tick + tickLength).toFixed(roundDigits))
      if (Math.abs(tick) < tickLength) {
        tick = 0
      }
      count++
    }
    // Make sure the min and max are included.
    if (Math.max(...ticks) < max) {
      ticks.push(max)
    }

    if (Math.min(...ticks) > min) {
      ticks.unshift(min)
    }

    if (!ticks.some((t: number) => t === 0)) {
      ticks.push(0)
    }

    return { ticks: ticks.sort((a: number, b: number) => a - b), interval: tickLength }
  }

  // static getEChartsZeroAxis = (
  //   min: number,
  //   max: number,
  //   min2: number,
  //   max2: number
  // ): { min1: number; max1: number; interval1: number; max2: number; min2: number; interval2: number } => {
  //   // const min1 = VizHelper.getMinAxisTick(min1, )
  //   // max = max < 0 ? 0 : max
  //
  //   // find the
  //
  //   const defaultTickCount = 7
  //   let tickLength = (max - min) / defaultTickCount
  //   let tens = 0
  //   let roundLength = tickLength
  //   while (roundLength > 10) {
  //     tens += 1
  //     roundLength = roundLength / 10
  //   }
  //   const rounder = Math.pow(10, tens)
  //
  //   console.log(rounder)
  //   tickLength = (tickLength / rounder) * rounder
  //   const ticks = []
  //   const roundDigits = tickLength < 1 ? 1 : 0
  //   let tick = Number(tickLength.toFixed(roundDigits))
  //   while (tick < max) {
  //     ticks.push(tick)
  //     tick = Number((tick + tickLength).toFixed(roundDigits))
  //   }
  //   tick = 0
  //   while (tick >= min) {
  //     ticks.unshift(tick)
  //     tick = Number((tick - tickLength).toFixed(roundDigits))
  //   }
  //
  //   // Make sure the min and max are included.
  //   if (Math.max(...ticks) < max) {
  //     ticks.push(max)
  //   }
  //
  //   if (Math.min(...ticks) > min) {
  //     ticks.unshift(min)
  //   }
  //
  //   // Make sure positive and negative have some ticks.
  //   // if (maxY > 0 && !ticks.find((y) => y > 0)) {
  //   //   ticks.unshift(Math.round(maxY))
  //   // }
  //   // if (minY < 0 && !ticks.find((y) => y < 0)) {
  //   //   ticks.push(Math.round(minY))
  //   // }
  //
  //   return { min1: 0, max1: 0, interval1: 0, min2: 0, max2: 0, interval2: 0 }
  // }
  /// Always includes 0
  static getAxisTicksRoundedWith0 = (min: number, max: number, tickCount: number) => {
    min = min > 0 ? 0 : min
    max = max < 0 ? 0 : max
    let tickLength = (max - min) / (tickCount - 1)
    let tens = 0
    let roundLength = tickLength
    while (roundLength > 10) {
      tens += 1
      roundLength = roundLength / 10
    }
    const rounder = Math.pow(10, tens)
    tickLength = (tickLength / rounder) * rounder
    const ticks = []
    const roundDigits = tickLength < 1 ? 1 : 0
    let tick = Number(tickLength.toFixed(roundDigits))
    while (tick < max) {
      ticks.push(tick)
      tick = Number((tick + tickLength).toFixed(roundDigits))
    }
    tick = 0
    while (tick >= min) {
      ticks.unshift(tick)
      tick = Number((tick - tickLength).toFixed(roundDigits))
    }

    // Make sure the min and max are included.
    if (Math.max(...ticks) < max) {
      ticks.push(max)
    }

    if (Math.min(...ticks) > min) {
      ticks.unshift(min)
    }

    // Make sure positive and negative have some ticks.
    // if (maxY > 0 && !ticks.find((y) => y > 0)) {
    //   ticks.unshift(Math.round(maxY))
    // }
    // if (minY < 0 && !ticks.find((y) => y < 0)) {
    //   ticks.push(Math.round(minY))
    // }

    return ticks
  }
  static getTotalLines(loadshape: Loadshape, isConsumed = false, isEnergy = true) {
    const totalLines = VizHelper.getStackedBarDataSetItems(loadshape.rows, isConsumed, isEnergy)
    const currentYearMonth = startOfMonth(new Date())
    const forecast = totalLines.filter((o) => o.date >= currentYearMonth)
    const actual = totalLines.filter((o) => o.date <= currentYearMonth)

    return [actual, forecast]
  }
}

export class DateDataItem {
  date = new Date()
  label = ""
  value = 0
}

export class StackedBarData {
  name = ""
  total = 0
  options = {
    xAxis: [],
  }
  barOptions = {
    keys: ["highlight", "actual", "forecast", "preview"],
    colors: [colors.blue["100"], colors.highlight, colors.highlight, "url(#diagonal)"],
    opacity: [0.7, 1, 0.2, 0.2],
  }
  bars = new Array<any>()
  lineOptions = {
    colors: [colors.highlight, colors.highlight],
    stroke: [2, 2],
    strokeDashArray: [null, "3, 3"],
  }
  lines = new Array<Array<DateDataItem>>()
}
