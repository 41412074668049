import ApiFetcher from "@/services/api-fetcher"
import { Account, MinimalUser } from "@common/models/models"

interface BaseTransaction {
  id: number
  kind: string
  createdTime: string
  eacCount: number
  memo?: string
  penniesUsd?: number
  accountId: number
}

export interface Payment {
  id: number
  penniesUsd: number
  status: "payment_pending" | "completed" | "canceled"
  paidTime?: string
  createdTime: string
  memo?: string
  user: MinimalUser
  account: Account
}

interface MintingTransaction extends BaseTransaction {
  kind: "minting"
  deviceId: number
}

interface AllocationTransaction extends BaseTransaction {
  kind: "allocation"
  deviceId: number
}

interface TransferTransaction extends BaseTransaction {
  kind: "transfer"
}

interface RetirementTransaction extends BaseTransaction {
  kind: "retirement"
}

interface ListingSaleTransaction extends BaseTransaction {
  kind: "listing_sale"
  paymentId: number
}

interface PurchaseTransaction extends BaseTransaction {
  kind: "purchase"
  paymentId: number
  paymentPenniesUsd: number
}

interface PaymentPostedTransaction extends BaseTransaction {
  kind: "payment_posted"
  paymentId: number
}

interface WithdrawalTransaction extends BaseTransaction {
  kind: "withdrawal"
}

interface FeeTransaction extends BaseTransaction {
  kind: "fee"
}

export type Transaction =
  | MintingTransaction
  | AllocationTransaction
  | TransferTransaction
  | RetirementTransaction
  | ListingSaleTransaction
  | PaymentPostedTransaction
  | PurchaseTransaction
  | WithdrawalTransaction
  | FeeTransaction

export default class TransactionService {
  fetcher: ApiFetcher

  constructor(fetcher: ApiFetcher) {
    this.fetcher = fetcher
  }

  listTransactions = async (options: { url?: string; per_page?: number }) => {
    const { url, ...queryOptions } = options
    if (url) {
      // strip the domain portion off the url as the fetcher will add it back
      const pathIndex = url.indexOf("/transactionsv2")
      const parsedUrl = url.slice(pathIndex)
      return await this.fetcher.httpGetPaginated<Transaction[]>(parsedUrl, {})
    }
    return await this.fetcher.httpGetPaginated<Transaction[]>(`/transactionsv2`, queryOptions)
  }

  getPayment = async (paymentId: number) => {
    return await this.fetcher.httpGet<Payment>(`/payments/${paymentId}`, {})
  }

  getCashBalance = async () => {
    return await this.fetcher.httpGet<number>("/transactionsv2/balance/cash", {})
  }
}
