import type { TableHeader } from "@/components/WcTable/WcTable"

const sortNumerically = (a: string, b: string): number => {
  const numericA = undoFormat12Hour(a.trim())
  const numericB = undoFormat12Hour(b.trim())

  if (numericB < numericA) {
    return 1
  } else if (numericB > numericA) {
    return -1
  } else {
    return 0
  }
}

export function getLocalTZName() {
  return new Date().toLocaleTimeString("en-US", { timeZoneName: "short" }).split(" ")[2]
}

export const handleHourColumnSort: NonNullable<TableHeader["customSort"]> = ({ data, isAscending }) => {
  const modifier = isAscending ? 1 : -1
  return data.sort((a, b) => {
    const firstValue = a?.hoursOfDayLocal
    const secondValue = b?.hoursOfDayLocal

    if (!firstValue?.length) {
      return modifier * -1
    }

    if (!secondValue?.length) {
      return modifier * 1
    }

    const firstValueRanges = firstValue.split(",")
    const secondValueRanges = secondValue.split(",")

    const numRanges = Math.max(firstValueRanges.length, secondValueRanges.length)

    for (let i = 0; i < numRanges; i++) {
      const rangeA = (firstValueRanges[i] ?? "").trim()
      const rangeB = (secondValueRanges[i] ?? "").trim()

      const rangeAFirstPiece = rangeA.split("-")[0] ?? ""
      const rangeBFirstPiece = rangeB.split("-")[0] ?? ""
      const firstPieceComparison = sortNumerically(rangeAFirstPiece, rangeBFirstPiece)

      if (firstPieceComparison !== 0) {
        return firstPieceComparison * modifier
      }

      const rangeASecondPiece = rangeA.split("-")[1] ?? ""
      const rangeBSecondPiece = rangeB.split("-")[1] ?? ""
      const secondPieceComparison = sortNumerically(rangeASecondPiece, rangeBSecondPiece)

      if (secondPieceComparison !== 0) {
        return modifier * secondPieceComparison
      }
    }

    return 0
  })
}

function undoFormat12Hour(hour: string) {
  let hourValue = Number(hour.replace(/(AM|PM)$/, "").trim())
  if (hour.endsWith("PM") && hourValue != 12) {
    hourValue = hourValue + 12
  }
  return hourValue
}
