<template>
  <main class="flex min-h-full flex-col justify-between">
    <div>
      <Header />
      <Transition name="fade">
        <div v-if="error" class="fixed inset-x-0 bottom-0 z-[100] flex justify-center p-2">
          <div class="text-body-2 max-w-[390px] rounded-md bg-neonred px-5 py-6 text-left text-blue-800">
            <p class="text-body-2-strong">We ran into an unexpected error.</p>
            <p class="mt-4">Error message: {{ error }}</p>
            <button class="mt-4 underline" @click="error = null">Close</button>
          </div>
        </div>
      </Transition>
      <section class="flex w-full justify-center">
        <RouterView v-slot="{ Component }">
          <!-- A timeout value of 0 will cause the fallback content to be displayed immediately when default content is replaced. -->
          <Suspense timeout="0">
            <template #default>
              <!-- key tells Suspense to trigger each time the path changes. otherwise, the loading screen doesn't work when navigating between routes -->
              <component :is="Component" :key="$route.fullPath" />
            </template>
            <template #fallback>
              <LoaderFullScreen />
            </template>
          </Suspense>
        </RouterView>
      </section>
    </div>
    <WwwFooter :is-dark-theme="false" client:load @open-contact-form="handleOpenContactForm" />
    <ContactUsForm ref="contactUsForm" client:load />
  </main>
</template>

<script setup lang="ts">
// Components
import { onErrorCaptured, ref } from "vue"
import ContactUsForm from "@/components/ContactUsForm.vue"
import Header from "@/components/layout/LayoutHeader.vue"
import WwwFooter from "@/components/layout/WwwFooter.vue"
import LoaderFullScreen from "@/components/ui/LoaderFullScreen.vue"

const error = ref()

onErrorCaptured((err) => {
  error.value = err
  console.error(err)
  return false
})

const contactUsForm = ref()
const handleOpenContactForm = () => {
  contactUsForm.value.openContactForm()
}
</script>
