<template>
  <section class="flex h-full flex-col justify-between">
    <section v-if="!activeAsset.type">
      <WcAssetModel v-model:asset="activeAsset"></WcAssetModel>
    </section>
    <section v-if="activeAsset.type" class="flex w-full flex-col justify-between">
      <div class="flex gap-2 p-2">
        <label>
          <span class="pl-0">Asset Name<span class="pl-0 text-error" title="Required">*</span></span>
          <input
            v-model="activeAsset.name"
            class="w-full"
            :class="{ 'border-error': !activeAsset.name }"
            data-cy="input-asset-name"
            @change="updateState" />
        </label>
        <label>
          Asset Type
          <select v-model="activeAsset.type" class="wc-input w-full" @update:model-value="updateState">
            <option v-for="t in assetTypes" :key="t.type" class="flex" :value="t.type">
              {{ t.label }}
            </option>
          </select>
        </label>
        <label class="w-1/5">
          Fuel
          <select v-model="activeAsset.fuelSource" class="wc-input w-full">
            <option value="electricity">Electricity</option>
            <option value="natural_gas">Gas</option>
          </select>
        </label>
        <label class="w-1/4">
          Provider
          <select
            v-model="activeAsset.provider"
            :disabled="activeAsset.fuelSource === 'natural_gas'"
            class="wc-input w-full"
            @update:model-value="setProviderOption">
            <option v-for="option in activeAsset.fuelSource === 'electricity' ? props.providers : []" :key="option.key" :value="option.key">
              {{ option.key }}
            </option>
          </select>
        </label>
      </div>
      <div class="w-full px-2">
        <WcAssetData v-model:asset="activeAsset" @save-asset="save()" @update:is-valid="(newValue: boolean) => isDataValid = newValue" />
      </div>
    </section>
    <section v-if="showButtons" class="flex w-full items-center justify-end gap-8 px-4 py-2">
      <button
        v-if="activeAsset.id"
        class="btn btn-flat danger"
        data-cy="delete-asset-button"
        :disabled="isTalkingToServer"
        @click="deleteAsset(activeAsset.id)">
        <Icon icon="wc-carbon:delete" />
        Delete Asset
      </button>
      <button
        v-if="activeAsset.type"
        class="btn"
        :disabled="isTalkingToServer || !isDataValid || activeAsset.name.length === 0"
        data-cy="button-asset-save"
        @click="save()">
        <Icon icon="wc-carbon:save" />
        Save Asset
      </button>
    </section>
  </section>
</template>

<script setup lang="ts">
import { computed, PropType, ref, watch } from "vue"
import WcAssetModel from "@/modules/sites/components/WcAssetModel.vue"
import WcAssetData from "@/modules/sites/components/WcAssetData.vue"
import { SiteAsset, GridPurpose } from "@common/models/siteAsset"
import { Provider } from "@common/models/models"
import { Icon } from "@iconify/vue"
import { gModelDict } from "@common/global"
import { useSiteService } from "@/services/service-container"

const props = defineProps({
  providers: { type: Array<Provider> },
  asset: { type: Object as PropType<SiteAsset>, required: true },
})
const emit = defineEmits(["update:view", "update:asset", "save:asset"])

const service = useSiteService()
const activeAsset = ref<SiteAsset>(props.asset)
const isDataValid = ref<boolean>(false)
const showButtons = ref<boolean>(true)
const isTalkingToServer = ref(false)

const assetTypes = computed(() =>
  Object.values(gModelDict).map((r: any) => {
    let assetDict = gModelDict["summary"]
    if (Object.hasOwn(gModelDict, r.name)) {
      assetDict = gModelDict[r.name]
    }
    return { label: assetDict.label, type: r.name, icon: assetDict.icon, id: r.id }
  })
)

const setProviderOption = (provider: string) => {
  activeAsset.value.provider = provider
  updateState()
}

const updateState = async () => {
  emit("update:asset", { ...activeAsset.value })
}

// TODO this is a hack. asset.type is actually asset.name from the assetDict
const setTypeAndGridPurpose = (asset: SiteAsset): SiteAsset => {
  if (Object.hasOwn(gModelDict, asset.type)) {
    const assetDict = gModelDict[asset.type]
    asset.type = assetDict.type // This will override any
    asset.gridPurpose = assetDict.gridPurpose
  }
  return asset
}

const save = async () => {
  try {
    isTalkingToServer.value = true
    const asset = setTypeAndGridPurpose(activeAsset.value)
    activeAsset.value = await service.saveAsset(asset)
    emit("save:asset", { ...activeAsset.value })
  } catch (error) {
    // let the toast talk
  } finally {
    isTalkingToServer.value = false
  }
  setView()
}

const deleteAsset = async (assetId: number) => {
  const asset = { id: assetId } as SiteAsset
  try {
    isTalkingToServer.value = true
    await service.deleteAsset(asset)
    emit("save:asset", undefined)
  } catch (error) {
    // let the toast talk
  } finally {
    isTalkingToServer.value = false
  }
  setView()
}

const setView = (view = 2) => {
  emit("update:view", view)
}

watch(
  () => props.asset,
  async (value: SiteAsset) => {
    // TODO this is a hack because we use the dict name as the type instead of having a separate grid purpose field.
    if (value.gridPurpose === GridPurpose.LocationalProduction) {
      const assetDict = Object.values(gModelDict).find((a) => a.type === value.type && a.gridPurpose === GridPurpose.LocationalProduction)
      if (assetDict) {
        value.type = assetDict.name
      }
    }

    // for new assets, default the provider to the first in the list
    if (value.id == 0 && !value.provider) {
      const firstProvider = props.providers?.[0].key
      if (firstProvider) {
        value.provider = firstProvider
      }
    }

    activeAsset.value = value
  }
)
</script>

<style scoped lang="scss"></style>
