<template>
  <main>
    <Header />
    <section class="w-full">
      <slot :open-contact-form="handleOpenContactForm" />
    </section>
    <WwwFooter :is-dark-theme="false" @open-contact-form="handleOpenContactForm" />
    <ContactUsForm ref="contactUsForm" client:load />
  </main>
</template>

<script setup lang="ts">
import { ref } from "vue"

// Components
import Header from "@/components/layout/LayoutHeader.vue"
import WwwFooter from "@/components/layout/WwwFooter.vue"
import ContactUsForm from "@/components/ContactUsForm.vue"

const contactUsForm = ref()
const handleOpenContactForm = () => {
  contactUsForm.value.openContactForm()
}
</script>

<style scoped></style>
