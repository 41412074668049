<template>
  <AppPage class="w-full">
    <section class="bg-neutral-200">
      <AppPageHeader :backlink-route="{ name: 'wc-assets' }" backlink-text="Back to Assets" show-account-context>
        {{ asset ? getAssetDisplayName(asset) : "" }}
        <template #title-adjacent>
          <div class="flex flex-col items-end">
            <div v-if="asset" class="flex rounded bg-neutral-100 px-6 py-4">
              <AssetStatusIcon :status="asset.status" class="mt-0.5" />
              <div class="text-subheading-2">
                {{ ASSET_STATUS[asset.status]?.summary }}
              </div>
            </div>
            <div v-if="asset && asset.status === AssetStatus.changes_requested" class="text-body-2 mt-6">
              {{ reviewNotesSummary }}
              <button v-if="isReviewNotesTruncated" class="text-body-2-link inline pl-1" @click="changesRequestedModalVisible = true">
                read more
              </button>
            </div>
          </div>
        </template>
      </AppPageHeader>
    </section>
    <section class="wc-page-container mb-24 w-full">
      <InlineLoading v-if="isLoading" />
      <InlineError v-if="hasError" error-message="There was a problem loading this asset. Please try again." />

      <div v-if="asset">
        <AssetMetadataTable title="Pricing" :metadata="{ Price: getAssetPrice(asset) }" class="mb-4 mt-8" />
        <div v-if="listingStatus != ListingStatus.none" class="text-body-2 mb-8 flex items-center">
          <Icon
            class="mr-2 size-4 min-w-4 stroke-[4px] drop-shadow"
            :icon="LISTING_STATUS[listingStatus].icon"
            :title="LISTING_STATUS[listingStatus].label" />
          {{ LISTING_STATUS[listingStatus].label }}: {{ LISTING_STATUS[listingStatus].summary }}
        </div>
        <div class="flex gap-3">
          <ButtonCTA theme="light" :is-filled="true" :is-disabled="!asset" is-compact @click="handleListAssetEacsForSale">
            <template v-if="asset.price"> Update list price for EACs </template>
            <template v-else> List EACs for Sale </template>
          </ButtonCTA>
          <ButtonCTA v-if="allowUploadAssetTimeSeries" theme="light" :is-filled="true" is-compact @click="handleUploadTimeSeriesData">
            Upload time series data
          </ButtonCTA>
          <ButtonCTA v-if="asset.price" theme="light" :is-filled="true" is-compact @click="handleUnlistAssetEacs"> Unlist EACs </ButtonCTA>
        </div>
      </div>
      <AssetMetadata v-if="asset && !isLoading && !hasError" :asset="asset" :excluded-fields="['status', 'statusDetail']" class="mt-12" />
      <AssetDocuments v-if="documents && !isLoading && !hasError" :documents="documents" class="mt-12" />
    </section>
  </AppPage>
  <WcModal
    :is-open="changesRequestedModalVisible"
    size="small"
    header="Changes Requested"
    @update:is-open="(isOpen) => (changesRequestedModalVisible = isOpen)">
    <template #panel>
      {{ asset!.reviewNotes }}
    </template>
    <template #footer>
      <WcButton text="Close" class="ml-auto" color="primary" @click="changesRequestedModalVisible = false" />
    </template>
  </WcModal>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue"
import { useRouter } from "vue-router"
import { Icon } from "@iconify/vue"
import type { Asset, AssetDocument } from "@common/models/asset"
import { ASSET_STATUS, AssetStatus, getAssetDisplayName, getAssetPrice, LISTING_STATUS, ListingStatus } from "@common/models/asset"
import { WcButton } from "@/components/button"
import { AppPage, AppPageHeader } from "@/components/layout"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import InlineError from "@/components/ui/InlineError.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import WcModal from "@/components/WcModal.vue"
import { useAssetService } from "@/services/service-container"
import { useMainStore } from "@/store"
import AssetStatusIcon from "./components/AssetStatusIcon.vue"
import AssetMetadata from "./components/AssetMetadata.vue"
import AssetMetadataTable from "./components/AssetMetadataTable.vue"
import AssetDocuments from "./components/AssetDocuments.vue"
import { useAllowUploadAssetTimeSeries } from "@/features"

const MAX_REVIEW_NOTES_LENGTH = 40

const allowUploadAssetTimeSeries = useAllowUploadAssetTimeSeries()
const assetService = useAssetService()
const store = useMainStore()
const router = useRouter()

const props = defineProps<{ assetId: number }>()

const asset = ref<Asset | undefined>()
const documents = ref<AssetDocument[]>([])
const hasError = ref<boolean>(false)
const isLoading = ref<boolean>(true)
const changesRequestedModalVisible = ref<boolean>(false)
const isReviewNotesTruncated = computed(() => {
  return (asset.value?.reviewNotes || "").length > MAX_REVIEW_NOTES_LENGTH
})
const reviewNotesSummary = computed(() => {
  const reviewNotes = asset.value?.reviewNotes
  if (isReviewNotesTruncated.value) {
    return reviewNotes!.slice(0, MAX_REVIEW_NOTES_LENGTH) + "..."
  } else {
    return reviewNotes
  }
})
const listingStatus = computed<ListingStatus>(() => {
  if (!asset.value) return ListingStatus.none
  if (asset.value.status === AssetStatus.draft) return ListingStatus.none

  if (asset.value.status !== AssetStatus.approved) return asset.value.price ? ListingStatus.pending : ListingStatus.none

  return asset.value.price ? ListingStatus.listed : ListingStatus.unlisted
})

onMounted(async () => {
  try {
    asset.value = await assetService.getAsset(props.assetId)
    documents.value = await assetService.listAssetDocuments(props.assetId)
  } catch (error) {
    hasError.value = true
    console.error("There was an error loading this asset", error)
  }
  isLoading.value = false
})

const routeToListEacsForSale = (isUnlisting: boolean) => {
  if (asset.value) {
    store.assetsToUpdate = [asset.value]
    store.isUnlisting = isUnlisting
    router.push({ name: "wc-assets-list-eacs-for-sale" })
  }
}

const handleListAssetEacsForSale = () => routeToListEacsForSale(false)

const handleUnlistAssetEacs = () => routeToListEacsForSale(true)

const handleUploadTimeSeriesData = () => {
  if (asset.value) {
    router.push({ name: "wc-assets-asset-timeseries-upload", params: { assetId: props.assetId } })
  }
}
</script>
