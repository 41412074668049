<template>
  <div class="flex flex-row flex-wrap gap-5 lg:flex-row-reverse lg:flex-nowrap">
    <div class="basis-full lg:basis-1/2">
      <WcCarousel v-if="props.metaData.images[0].url !== undefined" :slides="props.metaData.images" class="h-[300px] w-full sm:h-[400px]">
        <template #slide="image">
          <div class="size-full bg-marketplace-grey-100">
            <img :alt="image.description" :src="image.url" class="size-full object-cover mix-blend-multiply sm:px-[70px] sm:py-[68px]" />
          </div>
        </template>
      </WcCarousel>
      <StoryImpactStatementsSection v-if="props.expanded" :story="story" class="hidden lg:block" />
    </div>

    <div class="text-body-3 basis-full lg:basis-1/2">
      <h2 class="text-heading-4 mb-6">{{ story.name }}</h2>
      <h3 class="text-body-1 mb-6">{{ story.shortDescription }}</h3>
      <section v-if="props.expanded" class="my-12">
        <slot name="cta"></slot>
      </section>
      <section>
        <div class="text-subheading-1 my-4">Overview</div>
        <table class="border-separate border-spacing-y-3.5">
          <tr v-if="story.locationDescription">
            <th class="text-subheading-1 w-[200px] text-left align-top font-normal">Grid Region</th>
            <td class="text-body-1 align-top">{{ story.locationDescription }}</td>
          </tr>
          <tr>
            <th class="text-subheading-1 w-[200px] text-left align-top font-normal">Dates</th>
            <td class="text-body-1 align-top">{{ story.dateRangeDescription }}</td>
          </tr>
          <tr v-if="story.availabilityDescription">
            <th class="text-subheading-1 w-[200px] text-left align-top font-normal">Type</th>
            <td class="text-body-1 align-top">{{ story.availabilityDescription }}</td>
          </tr>
          <tr v-if="props.metaData.resource">
            <th class="text-subheading-1 w-[200px] text-left align-top font-normal">Resource</th>
            <td class="text-body-1 align-top">{{ props.metaData.resource }}</td>
          </tr>
          <tr v-if="props.metaData.volume">
            <th class="text-subheading-1 w-[200px] text-left align-top font-normal">Volume</th>
            <td class="text-body-1 align-top">{{ props.metaData.volume }}</td>
          </tr>
          <tr v-if="props.metaData.price">
            <th class="text-subheading-1 w-[200px] text-left align-top font-normal">Price</th>
            <td class="text-body-1 align-top">{{ props.metaData.price }}</td>
          </tr>
          <tr v-if="story.methodologyDescription || props.metaData.methodologies?.length">
            <th class="text-subheading-1 w-[200px] text-left align-top font-normal">
              {{ (props.metaData.methodologies?.length ?? 0) > 1 ? "Methodologies" : "Methodology" }}
            </th>
            <td v-if="story.methodologyDescription" class="text-body-1 align-top">
              <WcMarkdown :source="story.methodologyDescription" />
            </td>
            <td v-else class="text-body-1 align-top">
              <div v-for="methodology in props.metaData.methodologies" :key="methodology.url">
                <a :href="methodology.url" class="text-body-1-link" target="_blank">{{ methodology.displayName }}</a>
              </div>
            </td>
          </tr>
        </table>
      </section>
      <div v-if="props.expanded">
        <section v-if="story.longDescription">
          <div class="text-subheading-1 my-4">About this Partner</div>
          <WcMarkdown :source="story.longDescription ?? ''" />
        </section>

        <section v-if="props.metaData.locations && props.metaData.locations.length > 0">
          <div class="text-subheading-1 my-4">Locations</div>
          <div class="relative mb-4 size-full h-[350px]">
            <WcMap client:load :locations="props.metaData.locations ?? []" class="mb-8 rounded-2xl" />
          </div>
        </section>
      </div>

      <StoryImpactStatementsSection v-if="props.expanded" :story="props.story" class="block lg:hidden" />
    </div>
  </div>

  <div v-if="props.expanded" class="flex flex-row flex-wrap lg:flex-nowrap lg:gap-12">
    <div class="basis-full lg:basis-1/2">
      <section v-if="story.bodyContent" class="!my-0">
        <div class="text-subheading-1 my-4">Co-Benefits</div>
        <WcMarkdown :source="story.bodyContent ?? ''" />
      </section>
    </div>

    <StoryImpactSection :story="story" class="text-body-3 mt-10 basis-full lg:basis-1/2 lg:pl-9" />
  </div>
</template>

<script setup lang="tsx">
import type { Story, MarketplaceStory } from "@common/models/story"

import WcCarousel from "@/components/ui/WcCarousel.vue"
import WcMap from "@/components/WcMap/WcMap.vue"
import WcMarkdown from "@/components/ui/WcMarkdown.vue"

import StoryImpactStatementsSection from "./components/StoryImpactStatementsSection.vue"
import StoryImpactSection from "./components/StoryImpactSection.vue"

import type { StoryMetaData } from "./story.utils"

const props = defineProps<{
  story: Story | MarketplaceStory
  metaData: StoryMetaData
  expanded: boolean
}>()
</script>

<style lang="scss" scoped>
section {
  @apply mb-12;
}
.mapboxgl-map {
  clip-path: border-box;
}
</style>
