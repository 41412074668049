<template>
  <div class="mx-auto max-w-screen-xl">
    <section class="col-span-6 my-20 md:col-span-12">
      <h3 class="text-heading-4 mb-8 text-center">Portfolios</h3>
      <div v-if="pageState.isLoading" class="h-[350px]">
        <WcLoadingSpinner />
      </div>
      <div v-else>
        <TabGroup :default-index="defaultTabIndex" :selected-index="selectedTabIndex" @change="changeTab">
          <TabList class="wc-page-container mt-8 flex gap-6">
            <Tab v-slot="{ selected }" as="TabButton" tabindex="0" class="ph-no-capture">
              <TabButton :selected="selected" data-cy="portfolios-tab-now-funding" @click="captureNowFundingEvent">Now Funding</TabButton>
            </Tab>
            <Tab v-slot="{ selected }" as="TabButton" tabindex="0" class="ph-no-capture">
              <TabButton :selected="selected" data-cy="portfolios-tab-funded" @click="captureFundedEvent">Funded</TabButton>
            </Tab>
          </TabList>
          <TabPanels class="wc-page-container mt-8 px-5 pb-8">
            <TabPanel>
              <div v-if="fundingPortfolios.length === 0" class="text-body-2 w-full rounded-md bg-blue-100 px-4 py-12 md:mx-5">
                <h3 class="text-heading-6 text-center">Stay tuned</h3>
                <p class="mx-auto mt-4 max-w-[450px] text-center">
                  We're currently preparing new portfolios for funding. Contact us to learn about upcoming opportunities.
                </p>
                <div class="mt-8 flex justify-center">
                  <ButtonCTA class="ph-no-capture self-stretch" :is-filled="true" theme="light" @click="openContactForm">Contact us</ButtonCTA>
                </div>
              </div>
              <div v-else class="flex w-full snap-x gap-5 overflow-x-auto md:flex-wrap md:justify-center">
                <div v-for="p in fundingPortfolios" :key="p.id" class="ph-no-capture shrink-0 grow-0 basis-[300px] snap-center md:basis-[380px]">
                  <PortfolioPreviewCard :portfolio="p" @click="capturePortfolioClickEvent(p)" />
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div
                v-if="fundedPortfolios.length === 0"
                class="text-body-2 flex h-48 w-full items-center justify-center rounded-md bg-neutral-200 md:mx-5">
                There are no portfolios that match your filters.
              </div>
              <div v-else class="flex w-full snap-x gap-5 overflow-x-auto md:flex-wrap md:justify-center">
                <div v-for="p in fundedPortfolios" :key="p.id" class="ph-no-capture shrink-0 grow-0 basis-[300px] snap-center md:basis-[380px]">
                  <PortfolioPreviewCard :portfolio="p" @click="capturePortfolioClickEvent(p)" />
                </div>
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </section>
    <ContactUsForm ref="contactUsForm" client:load form-context="Portfolio Request Form" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, reactive } from "vue"
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue"
import posthog from "posthog-js"

// Components
import ContactUsForm from "@/components/ContactUsForm.vue"
import TabButton from "@/components/ui/TabButton.vue"
import PortfolioPreviewCard from "./PortfolioPreviewCard.vue"
import WcLoadingSpinner from "@/components/WcLoadingSpinner.vue"

// Models
import { Portfolio, PortfolioStatusType } from "@common/models/order"

// Services
import { usePortfolioService } from "@/services/service-container"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"

const contactUsForm = ref()
const openContactForm = () => {
  posthog.capture('Clicked on "Submit a Portfolio Request" form - Portfolios View')
  contactUsForm.value.openContactForm()
}

const portfolioService = usePortfolioService()

const pageState = reactive({ isLoading: false, tab: "featured" })

const portfolios = ref<Array<Portfolio>>(new Array<Portfolio>())
const fundingPortfolios = computed(() => portfolios.value.filter((portfolio) => portfolio.status === PortfolioStatusType.funding))
const fundedPortfolios = computed(() =>
  portfolios.value.filter((portfolio) => portfolio.status === PortfolioStatusType.deploying || portfolio.status === PortfolioStatusType.complete)
)
const defaultTabIndex = computed(() => {
  if (fundingPortfolios.value.length > 0) {
    return 0
  } else {
    return 1
  }
})
const selectedTabIndex = ref(defaultTabIndex.value)
function changeTab(index: number) {
  selectedTabIndex.value = index
}

onMounted(async () => {
  pageState.isLoading = true
  try {
    portfolios.value = await portfolioService.listPortfolios()
  } finally {
    pageState.isLoading = false
  }
})

// PostHog Events
const captureNowFundingEvent = () => posthog.capture('Clicked on the "Now Funding" tab - Portfolios View')
const captureFundedEvent = () => posthog.capture('Clicked on the "Funded" tab - Portfolios View')
const capturePortfolioClickEvent = (portfolio: Portfolio) => posthog.capture(`Clicked on the '${portfolio.name}' portfolio card - Portfolios View`)
</script>
