<template>
  <AppPageSection class="w-full bg-blue-100 p-4">
    <WcDataWithLabel>
      <template #label>
        <span>Status</span>
      </template>
      <template #content>
        <div class="mb-2 flex items-center">
          <AssetStatusIcon :status="props.asset.status" />
          <div>
            <strong>{{ ASSET_STATUS[props.asset.status]?.summary }}</strong>
            <p v-if="asset.status === AssetStatus.changes_requested">{{ asset.reviewNotes }}</p>
          </div>
        </div>
        <div v-if="listingStatus != ListingStatus.none" class="flex items-center">
          <Icon
            class="mr-2 size-4 min-w-4 stroke-[4px] drop-shadow"
            :icon="LISTING_STATUS[listingStatus].icon"
            :title="LISTING_STATUS[listingStatus].label" />
          {{ LISTING_STATUS[listingStatus].label }}: {{ LISTING_STATUS[listingStatus].summary }}
        </div>
      </template>
    </WcDataWithLabel>
  </AppPageSection>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { AppPageSection } from "@/components/layout"
import WcDataWithLabel from "@/components/ui/WcDataWithLabel.vue"
import type { Asset } from "@common/models/asset"
import { ASSET_STATUS, AssetStatus, LISTING_STATUS, ListingStatus } from "@common/models/asset"
import AssetStatusIcon from "./AssetStatusIcon.vue"
import { Icon } from "@iconify/vue"

const props = defineProps<{
  asset: Asset
}>()

const listingStatus = computed<ListingStatus>(() => {
  if (props.asset.status === AssetStatus.draft) return ListingStatus.none

  if (props.asset.status !== AssetStatus.approved) return props.asset.price ? ListingStatus.pending : ListingStatus.none

  return props.asset.price ? ListingStatus.listed : ListingStatus.unlisted
})
</script>
