<template>
  <InlineLoading v-if="isLoading" />
  <InlineError v-else-if="hasError" error-message="There was a problem loading this page. Please try again." />
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue"

import { useAssetService, useListingService, useStoryService } from "@/services/service-container"

import { createMetaData } from "./story.utils"

import type { PublicAsset } from "@common/models/asset"
import type { LoadableStory, StoryMetaData } from "./story.utils"

const props = defineProps<{ storyId: number }>()

const emit = defineEmits<{
  (e: "load", story: LoadableStory, metaData: StoryMetaData): void
  (e: "error", err: any): void
  (e: "not-found"): void
}>()

const assetService = useAssetService()
const storyService = useStoryService()
const listingService = useListingService()

const isLoading = ref<boolean>(true)
const hasError = ref<boolean>(false)

onMounted(async () => {
  try {
    // Search listings for the story to get the total EACs and CO2 available.
    // If there are no listings, then fetch the story directly.
    const data = await listingService.getAllListings({ storyId: props.storyId })
    let loadedStory: LoadableStory | undefined = data.stories.find((s) => s.id === props.storyId)
    let publicAssets: PublicAsset[] | undefined

    const isAvailableToOrder = loadedStory !== undefined

    if (!loadedStory) {
      loadedStory = await storyService.getStory(props.storyId)
      publicAssets = (await assetService.listAssetsPublic({ storyId: props.storyId })).data
    }

    if (loadedStory) {
      const metaData = createMetaData(loadedStory, isAvailableToOrder, publicAssets)
      emit("load", loadedStory, metaData)
    }
  } catch (error: any) {
    if (error?.message === "Story not found") {
      emit("not-found")
    } else {
      hasError.value = true
      console.error("There was an error loading the story", error)
      emit("error", error)
    }
  }
  isLoading.value = false
})
</script>
