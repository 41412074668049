<template>
  <section class="flex justify-between gap-4">
    <div class="sticky top-4 flex h-[calc(100vh-8rem)] w-1/4 flex-col rounded-md bg-white shadow-md">
      <div class="flex h-full flex-col">
        <div class="border-b border-neutral-500 py-2">
          <div class="text-gray-600 relative mx-auto px-2 pt-2">
            <input
              v-model="nameFilter"
              class="border-gray-300 mb-2 h-10 rounded-lg border-2 bg-white px-5 pr-16 text-sm focus:outline-none"
              type="search"
              :disabled="adminStoriesStore.isLoading"
              placeholder="Filter stories" />
          </div>
          <label class="my-2 flex flex-row items-center gap-4 px-4">
            Status
            <select v-model="statusFilter" class="p-2">
              <option :value="null">Any</option>
              <option v-for="[key, value] of Object.entries(StoryStatusType)" :key="value" :value="value">{{ key }}</option>
            </select>
          </label>
        </div>
        <div class="h-full grow overflow-y-scroll">
          <Icon v-if="adminStoriesStore.isLoading" icon="mdi:loading" class="mx-auto my-8 size-20 animate-spin" />
          <div class="grow">
            <p v-if="filteredStories.length === 0" class="px-4 py-2 text-neutral-500">No stories match filters</p>
            <router-link
              v-for="s in filteredStories"
              :key="s.id"
              :to="{ name: 'wc-admin-story', params: { storyId: s.id } }"
              class="flex w-full cursor-pointer justify-between px-4 py-2 text-left text-sm font-medium hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-300/75">
              <div class="flex w-full flex-col justify-between">
                <div class="flex w-full justify-between">
                  <span class="inline-block overflow-x-hidden text-ellipsis pr-2">{{ s.name }}</span>
                  <span class="inline-block">{{ s.id }}</span>
                </div>
                <div class="flex w-full justify-between font-mono text-neutral-500">
                  {{ s.status }}
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="border-t-2 border-neutral-300">
          <router-link
            :to="{ name: 'wc-admin-story-new' }"
            class="flex w-full flex-row items-center justify-end p-4 disabled:cursor-not-allowed disabled:text-neutral-500">
            <Icon icon="wc-carbon:add" class="mr-2" />
            New Story
          </router-link>
        </div>
      </div>
    </div>
    <div class="flex w-full flex-col">
      <router-view v-if="!adminStoriesStore.isLoading"></router-view>
      <Icon v-else icon="mdi:loading" class="mx-auto my-8 size-20 animate-spin" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { Icon } from "@iconify/vue"
import { useAdminStoriesStore } from "@/modules/admin/adminStories.state"
import { StoryStatusType } from "@common/models/story"

const adminStoriesStore = useAdminStoriesStore()

const nameFilter = ref<string>("")
const statusFilter = ref<StoryStatusType | null>(null)
const filteredStories = computed(() => {
  return adminStoriesStore.stories
    .filter((s) => s.name.toLowerCase().indexOf(nameFilter.value.toLowerCase()) !== -1)
    .filter((s) => statusFilter.value === null || s.status == statusFilter.value)
})
</script>

<style scoped lang="scss">
.router-link-active {
  @apply bg-neutral-300;
}
</style>
