<template>
  <AppPage>
    <AppPageHeader show-account-context>
      Assets
      <template #title-adjacent>
        <ButtonCTA type="router-link" :to="{ name: 'wc-register-asset' }" theme="light" :is-filled="true">Submit assets for review</ButtonCTA>
      </template>
      <template #description>
        View and manage assets that you have registered on WEATS. Each asset represents an energy intervention resulting in EACs, such as a solar
        panel, battery, demand response event, or building that underwent an energy efficiency upgrade.
        <LearnMore :href="`${WWW_BASE_URL}/faq#selling-eacs`" />
      </template>
    </AppPageHeader>
    <AppPageContent>
      <template v-if="isLoading || assetTotalCount > 0">
        <div class="mb-12">
          <h4 class="text-subheading-1 mb-4">Overview</h4>
          <div v-if="!!assetCountsByStatus" class="flex flex-wrap gap-8">
            <div v-for="[status, count] in Object.entries(assetCountsByStatus)" :key="status" class="text-body-1 flex items-center">
              <AssetStatusIcon :status="status as AssetStatus" />
              {{ `${count} ${ASSET_STATUS[status as AssetStatus].summary}` }}
            </div>
          </div>
        </div>
        <div class="mb-6 flex justify-between">
          <div class="w-4/6">
            <h4 class="text-subheading-1 mb-4">Filters</h4>
            <div class="flex flex-wrap gap-3">
              <WcDropdown v-model="filterAssetStatus" class="basis-40" name="filterAssetStatus" :options="statusOptions" inset-label="Status" />
              <WcCalendarRange
                v-model="filterAssetCreatedDateRange"
                class="basis-40"
                :class="{ 'basis-60': (filterAssetCreatedDateRange || [])[1] != null }"
                name="filterAssetCreatedDateRange"
                inset-label="Created date" />
              <WcInputText
                v-model="filterAssetLocationKeyword"
                icon="wc-carbon:search"
                class="basis-56"
                name="filterAssetLocationKeyword"
                inset-label="Filter by location..." />
            </div>
          </div>

          <div class="flex flex-col items-start justify-end">
            <div class="text-subheading-1 mb-4">View as</div>
            <WcViewModeButton v-model="viewMode" />
          </div>
        </div>

        <div v-if="viewMode === ViewMode.table">
          <div class="flex flex-wrap gap-3">
            <ButtonCTA theme="light" :is-disabled="!selectedAssetIds.length" @click="handleClearSelection">Clear Selection</ButtonCTA>
            <ButtonCTA theme="light" :is-filled="true" :is-disabled="!selectedAssetIds.length" @click="handleListAssetEacsForSale"
              >List EACs For Sale</ButtonCTA
            >
            <ButtonCTA theme="light" :is-filled="true" :is-disabled="!hasListedAssetsSelected" @click="handleUnlistAssetEacs"
              >Unlist assets</ButtonCTA
            >
          </div>
          <div class="mt-4 overflow-x-scroll pb-4">
            <AssetsTable
              :assets="assets"
              allow-multiple-selection
              :selected-row-ids="selectedAssetIds"
              show-price
              @row-clicked="handleRowClicked"
              @rows-selected="handleRowsSelected" />
          </div>
        </div>
        <div v-if="viewMode === ViewMode.map && assetLocations?.length > 0">
          <div class="relative mb-6 size-full h-[600px]">
            <WcMap :locations="assetLocations ?? []" class="mb-8" locations-are-precise>
              <template #popup="asset">
                <AssetPopup :asset="asset" />
              </template>
            </WcMap>
          </div>
        </div>
        <PaginationButtons :page-info="pageInfo" :is-disabled="isLoading" @load-items="loadAssets" />
      </template>

      <EmptyState v-if="!isLoading && !hasError && assetTotalCount === 0" title="No registered assets">
        <WcLink to="/assets/register" class="text-hyperlink">Submit assets for review</WcLink> to begin registering your assets on
        <a :href="`${WWW_BASE_URL}/weats`" target="_blank" class="text-hyperlink">WEATS</a> and selling EACs.
        <p class="text-body-2 mt-2">
          If you have recently submitted assets for review, a member of the WattCarbon team will email you with next steps.
        </p>
      </EmptyState>
      <InlineLoading v-if="isLoading" />
      <InlineError v-if="hasError" error-message="There was a problem loading assets. Please try again." />
    </AppPageContent>
  </AppPage>
</template>

<script setup lang="ts">
import { format } from "date-fns"
import { computed, onMounted, ref, watch } from "vue"
import { useRouter } from "vue-router"
import type { SelectOption } from "@/components/input"
import { ViewMode, WcCalendarRange, WcDropdown, WcInputText, WcViewModeButton } from "@/components/input"
import { AppPage, AppPageContent, AppPageHeader } from "@/components/layout"
import EmptyState from "@/components/ui/EmptyState.vue"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import InlineError from "@/components/ui/InlineError.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import LearnMore from "@/components/ui/LearnMore.vue"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import WcLink from "@/components/ui/WcLink.vue"
import type { Location } from "@/components/WcMap/WcMap.utils"
import WcMap from "@/components/WcMap/WcMap.vue"
import type { Asset, AssetsSummary, AssetStatus } from "@common/models/asset"
import { ASSET_STATUS } from "@common/models/asset"
import type { PageInfo } from "@/services/base-fetcher"
import { useAssetService } from "@/services/service-container"
import { useMainStore } from "@/store"
import AssetPopup from "./components/AssetPopup.vue"
import AssetStatusIcon from "./components/AssetStatusIcon.vue"
import AssetsTable from "./components/AssetsTable.vue"
import { debounce } from "@/utils/debounce"
import { getEnvironment } from "@/environment"

const { WWW_BASE_URL } = getEnvironment()

const router = useRouter()
const assetService = useAssetService()
const store = useMainStore()

const assets = ref<Asset[]>([])
const hasError = ref<boolean>(false)
const isLoading = ref<boolean>(true)
const pageInfo = ref<PageInfo>()
const viewMode = ref<ViewMode>(ViewMode.table)
const filterAssetStatus = ref<SelectOption<AssetStatus> | null>(null)
const filterAssetLocationKeyword = ref<string | null | undefined>(null)
const filterAssetCreatedDateRange = ref<Date[] | null | undefined>(null)

const statusOptions = Object.entries(ASSET_STATUS).map(([status, { summary }]) => ({ label: summary, value: status }))
const assetCountsByStatus = ref<AssetsSummary["countsByStatus"]>()
const assetTotalCount = ref<number>(0)
const selectedAssetIds = ref<number[]>([])

const loadAssets = async (url?: string) => {
  try {
    const createdDateStart = (filterAssetCreatedDateRange.value ?? [])[0]
    const createdDateEnd = (filterAssetCreatedDateRange.value ?? [])[1]
    const filters = {
      ...(filterAssetStatus.value && { status: filterAssetStatus.value.value }),
      ...(filterAssetLocationKeyword.value && { location: filterAssetLocationKeyword.value }),
      ...(createdDateStart && { createdDateStart: format(createdDateStart, "yyyy-MM-dd") }),
      ...(createdDateEnd && { createdDateEnd: format(createdDateEnd, "yyyy-MM-dd") }),
    }
    const result = await assetService.listAssets({ url, ...filters })
    assets.value = result.data
    pageInfo.value = result.pageInfo
    selectedAssetIds.value = []
  } catch (error) {
    hasError.value = true
    console.error("There was an error loading assets", error)
  }
  isLoading.value = false
}

const loadAssetsSummary = async () => {
  try {
    const result = await assetService.getAssetsSummary()
    assetCountsByStatus.value = result.countsByStatus
    assetTotalCount.value = result.assetCount
  } catch (error) {
    console.error("There was an error loading assets summary info", error)
  }
}

onMounted(async () => {
  loadAssetsSummary()
  loadAssets()
})

watch([filterAssetStatus], () => {
  loadAssets()
})

// Debounced text input
watch(
  [filterAssetCreatedDateRange, filterAssetLocationKeyword],
  debounce(() => {
    loadAssets()
  })
)
const assetLocations = computed<Location[]>(() => {
  return assets.value.reduce((acc, asset) => {
    const { coordinates, status } = asset
    return [
      ...acc,
      {
        ...coordinates,
        color: ASSET_STATUS[status].color,
        strokeColor: ASSET_STATUS[status].strokeColor,
        metadata: asset,
      },
    ]
  }, [] as Location[])
})

const hasListedAssetsSelected = computed<boolean>(() => {
  // TODO - selectedAssetIds and assets both being arrays makes getting the list of selected
  // assets a bit inefficient!
  return assets.value.some((asset) => asset.price && selectedAssetIds.value.includes(asset.id))
})

const handleRowClicked = (row: { id: number }, event: MouseEvent | KeyboardEvent) => {
  const path = `/assets/${row.id}`
  if (event.ctrlKey || event.metaKey || event.shiftKey) {
    window.open(path, "_blank")
  } else {
    router.push(path)
  }
}

const handleRowsSelected = (selectedRowIDs: number[]) => {
  selectedAssetIds.value = selectedRowIDs
}

const handleClearSelection = () => {
  selectedAssetIds.value = []
}

const handleListAssetEacsForSale = () => {
  store.assetsToUpdate = assets.value.filter(({ id }) => selectedAssetIds.value.includes(id))
  store.isUnlisting = false
  router.push({ name: "wc-assets-list-eacs-for-sale" })
}

const handleUnlistAssetEacs = () => {
  store.assetsToUpdate = assets.value.filter((asset) => asset.price && selectedAssetIds.value.includes(asset.id))
  store.isUnlisting = true
  router.push({ name: "wc-assets-list-eacs-for-sale" })
}
</script>
