<template>
  <h4 class="text-heading-5 text-blue-400">About demand response</h4>
  <p class="mt-4">
    Demand response is the fastest way to mitigate the use of fossil fuel energy, with the potential for huge decarbonization impacts when implemented
    at scale.
  </p>
  <p class="mt-4">
    Demand response projects are coordinated events to simultaneously reduce energy consumption in buildings during the most carbon-intensive hours on
    the grid.
  </p>
  <p class="mt-4">
    The collective impact of a demand response event is enough to keep fossil-fuel-based peaker plants offline during periods of peak energy demand.
    According to the Clean Energy Group, phasing out peaker plants is one of the most important environmental justice opportunities in the country
    today, as they are disproportionately located near low-income communities and communities of color.
  </p>
  <p class="mt-4">
    Demand response events can be as simple as a group of businesses simultaneously turning off their industrial fridges for several hours to take
    advantage of passive cooling, or reducing computer or HVAC usage overnight. While participating businesses save on energy costs, demand response
    projects pay them additional incentives for participating, since these events can significantly reduce carbon emissions.
  </p>
  <h4 class="text-heading-5 mt-10 whitespace-pre-line text-blue-400">Why funding is necessary</h4>
  <p class="mt-4">
    Demand response is frequently used during times of peak energy consumption, to mitigate price spikes and avoid blackouts. Historically, we haven’t
    had the same market signals to prioritize avoiding fossil fuel usage, so funding is needed to incentivize this shift until we can power all grids
    on 24/7 renewable energy.
  </p>
  <h4 class="text-heading-5 mt-10 text-blue-400">Project deployment and delivery</h4>
  <p class="mt-4">
    For every demand response event, WattCarbon issues Energy Attribute Certificates (EACs) that record the event’s hour, location, and energy
    savings. These certificates are then assigned to investors in the portfolio.
  </p>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
p {
  @apply my-4;
}
</style>
