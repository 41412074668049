<template>
  <footer class="wc-footer" :class="{ 'bg-blue-500': props.isDarkTheme, 'border-t border-neutral-300': !props.isDarkTheme }">
    <nav class="wc-page-container pt-12 md:py-20" :class="{ 'text-white': props.isDarkTheme }">
      <div class="wc-homepage-grid w-full items-end">
        <div class="col-span-6 md:col-span-4 lg:col-span-6">
          <a
            href="https://www.wattcarbon.com"
            class="logo ph-no-capture block h-[40px] w-[210px] shrink-0 bg-contain text-[transparent] md:h-[48px] md:w-[252px]"
            :class="{ dark: !props.isDarkTheme }"
            @click="captureHomeEvent"
            >WattCarbon</a
          >
          <div class="mt-14 hidden flex-wrap gap-4 md:flex">
            <ButtonCTA class="self-stretch" :is-filled="true" :theme="theme" button-classes="ph-no-capture" @click="openContactForm"
              >Contact us</ButtonCTA
            >
            <ButtonCTA
              v-if="signInButtonIsVisible"
              type="anchor"
              :href="`${appUrl}/signin`"
              :is-filled="false"
              :theme="theme"
              button-classes="ph-no-capture"
              @click="captureSignInEvent"
              >Sign in</ButtonCTA
            >
          </div>
        </div>
        <div class="col-span-6 mt-6 md:col-span-8 md:mt-0 lg:col-span-6">
          <div class="grid grid-cols-3 gap-y-8 md:gap-x-5 lg:gap-x-12">
            <div class="col-span-3 flex flex-col items-start gap-4 md:col-span-1">
              <h5 class="text-subheading-1">Solutions</h5>
              <ButtonNav
                :theme="theme"
                button-classes="ml-4 md:ml-0 ph-no-capture"
                type="anchor"
                :href="`${appUrl}/marketplace`"
                @click="captureMarketEvent"
                >Market</ButtonNav
              >
              <ButtonNav
                :theme="theme"
                button-classes="ml-4 md:ml-0 ph-no-capture"
                type="anchor"
                :href="`${appUrl}/assets`"
                @click="captureSellEacsEvent"
                >Sell EACs</ButtonNav
              >
              <ButtonNav :theme="theme" button-classes="ml-4 md:ml-0 ph-no-capture" type="anchor" :href="`${wwwUrl}/plus`" @click="captureCfxEvent"
                >WattCarbon+</ButtonNav
              >
            </div>
            <div class="col-span-3 flex flex-col items-start gap-4 md:col-span-1">
              <h5 class="text-subheading-1">Resources</h5>
              <ButtonNav :theme="theme" button-classes="ml-4 md:ml-0 ph-no-capture" type="anchor" :href="`${wwwUrl}/weats`" @click="captureWEATSEvent"
                >Registry</ButtonNav
              >
              <ButtonNav :theme="theme" button-classes="ml-4 md:ml-0 ph-no-capture" type="anchor" :href="`${wwwUrl}/faq`" @click="captureFAQEvent"
                >FAQ</ButtonNav
              >
              <ButtonNav
                :theme="theme"
                button-classes="ml-4 md:ml-0 ph-no-capture"
                type="anchor"
                href="https://blog.wattcarbon.com/archive"
                open-in-new-tab="true"
                @click="captureBlogEvent"
                >Blog</ButtonNav
              >
            </div>
            <div class="col-span-3 flex flex-col items-start gap-4 md:col-span-1">
              <h5 class="text-subheading-1">Connect</h5>
              <ButtonNav
                :theme="theme"
                button-classes="ml-4 md:ml-0 ph-no-capture"
                type="anchor"
                href="https://www.linkedin.com/company/wattcarbon/"
                open-in-new-tab="true"
                @click="captureLinkedinEvent"
                >LinkedIn</ButtonNav
              >
              <ButtonNav
                :theme="theme"
                button-classes="ml-4 md:ml-0 ph-no-capture"
                type="anchor"
                href="https://open.spotify.com/show/4vfuykuAPXrnXb3nZZ5iGg"
                open-in-new-tab="true"
                @click="capturePodcastEvent"
                >Podcast</ButtonNav
              >
              <ButtonNav
                :theme="theme"
                button-classes="ml-4 md:ml-0 ph-no-capture"
                type="anchor"
                href="https://climatebase.org/company/1132206/wattcarbon"
                open-in-new-tab="true"
                @click="captureJobsEvent"
                >Jobs</ButtonNav
              >
            </div>
          </div>
        </div>
        <div class="col-span-6 mt-10 flex flex-col gap-4 md:hidden">
          <ButtonCTA :theme="theme" :is-filled="true" class="self-stretch" button-classes="ph-no-capture" @click="openContactForm"
            >Contact us</ButtonCTA
          >
          <ButtonCTA
            v-if="signInButtonIsVisible"
            :theme="theme"
            :is-filled="false"
            type="anchor"
            :href="`${appUrl}/signin`"
            button-classes="ph-no-capture"
            @click="captureSignInEvent"
            >Sign in</ButtonCTA
          >
        </div>
      </div>
      <div class="text-caption -mx-4 mt-8 border-t border-neutral-500 px-4 py-8 md:mx-0 md:border-t-0 md:px-0 md:text-right">
        Copyright © 2024 WattCarbon.
        <span class="inline-block">All rights reserved</span> •
        <a class="ph-no-capture inline-block underline" :href="`${wwwUrl}/terms`" @click="captureTermsEvent">Terms & conditions</a> •
        <a class="ph-no-capture inline-block underline" :href="`${wwwUrl}/privacy-policy`" @click="capturePrivacyPolicyEvent">Privacy Policy</a>
      </div>
    </nav>
  </footer>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue"
import posthog from "posthog-js"
import { useMainStore } from "@/store"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import ButtonNav from "@/components/ui/ButtonNav.vue"
import { getEnvironment } from "@/environment"

const { WWW_BASE_URL, APP_BASE_URL } = getEnvironment()
const store = useMainStore()

const props = defineProps({
  isDarkTheme: { type: Boolean, default: true },
})

const theme = computed(() => (props.isDarkTheme ? "dark" : "light"))
const signInButtonIsVisible = computed(() => !store.auth.isAuthenticated)
const captureContactUsEvent = () => posthog.capture("Clicked on the 'Contact Us' button - Footer")
const captureHomeEvent = () => posthog.capture("Clicked on the 'Home' link - Footer")
const captureSignInEvent = () => posthog.capture("Clicked on the 'Sign In' button - Footer")
const captureMarketEvent = () => posthog.capture("Clicked on the 'Market' link - Footer")
const captureSellEacsEvent = () => posthog.capture("Clicked on the 'Sell EACs' link - Footer")
const captureCfxEvent = () => posthog.capture("Clicked on the 'WattCarbon Plus' link - Footer")
const captureFAQEvent = () => posthog.capture("Clicked on the 'FAQ' link - Footer")
const captureWEATSEvent = () => posthog.capture("Clicked on the 'WEATS' link - Footer")
const captureBlogEvent = () => posthog.capture("Clicked on the 'Blog' link - Footer")
const capturePodcastEvent = () => posthog.capture("Clicked on the 'Podcast' link - Footer")
const captureLinkedinEvent = () => posthog.capture("Clicked on the 'LinkedIn' link - Footer")
const captureJobsEvent = () => posthog.capture("Clicked on the 'Jobs' link - Footer")
const captureTermsEvent = () => posthog.capture("Clicked on the 'Terms & conditions' link - Footer")
const capturePrivacyPolicyEvent = () => posthog.capture("Clicked on the 'Privacy Policy' link - Footer")

const emit = defineEmits(["openContactForm"])
const openContactForm = () => {
  emit("openContactForm")
  captureContactUsEvent()
}

const wwwUrl = ref(WWW_BASE_URL)
const appUrl = ref(APP_BASE_URL)
</script>

<style scoped>
.wc-footer a.logo {
  background: url("@/static/img/WattCarbon_logo_05172023.svg") no-repeat;
}

.wc-footer a.logo.dark {
  background: url("@/static/img/wattcarbon_logo_on-light.svg") no-repeat;
  background-size: contain;
}
</style>
