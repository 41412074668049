<template>
  <WcRouterLink
    :route="path"
    :href="href"
    :is-back-link="isBackLink"
    class="arrow-link flex items-center justify-center gap-2 py-2 transition-colors"
    :class="buttonClasses">
    <span class="order-2">
      <slot />
    </span>
    <div :class="arrowDirectionClasses">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M13.3319 7.33449L4.27647 7.33449L8.46928 3.14167C8.72928 2.88167 8.72928 2.455 8.46928 2.195C8.20928 1.935 7.78928 1.935 7.52928 2.195L2.19646 7.52783C1.93646 7.78783 1.93646 8.20783 2.19646 8.46783L7.53122 13.805C7.79122 14.065 8.21122 14.065 8.47122 13.805C8.73122 13.545 8.73122 13.125 8.47122 12.865L4.27647 8.66782L13.3319 8.66782C13.6985 8.66782 13.9985 8.36782 13.9985 8.00115C13.9985 7.63449 13.6985 7.33449 13.3319 7.33449Z"
          fill="#003E4A" />
      </svg>
    </div>
  </WcRouterLink>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import WcRouterLink from "@/components/WcRouterLink.vue"

const props = defineProps({
  width: { type: String },
  path: { type: [String, Object] },
  href: { type: String },
  openInNewTab: { type: String },
  buttonClasses: { type: String },
  isDisabled: { type: Boolean },
  arrowClass: { type: String },
  direction: { type: String, default: "forward" },
})

const arrowDirectionClasses = computed(() => {
  switch (props.direction) {
    case "forward":
      return "order-3 rotate-180"
    case "backward":
      return "order-1 origin-center"
    case "upward":
      return "order-3 rotate-90 origin-center"
    case "downward":
      return "order-3 -rotate-90 origin-center"
    default:
      return ""
  }
})

const isBackLink = computed(() => {
  return props.direction == "backward" && props.path
})
</script>
