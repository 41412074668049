<template>
  <section class="flex justify-between gap-4">
    <div class="section-panel w-2/5">
      <div v-if="!!assetCountsByStatus" class="p-3 pb-1">
        <h1 class="text-heading-5 pb-2">
          {{ `${assetTotalCount} Assets` }}
          <span class="text-body-2">{{ `(${assetRemainder} to go)` }}</span>
        </h1>
        <ProgressBar :value="assetPercentCompleteDisplay" :show-value="false" />
        <div class="flex flex-wrap gap-3 pt-2">
          <div v-for="[status, count] in Object.entries(assetCountsByStatus)" :key="status" class="text-body-3 flex items-center">
            <AssetStatusIcon :status="status as AssetStatus" />
            {{ `${count} ${ASSET_STATUS[status as AssetStatus].summary}` }}
          </div>
        </div>
      </div>
      <div class="flex flex-wrap gap-2 p-3">
        <WcDropdown v-model="filterAssetStatus" class="basis-40" name="filterAssetStatus" :options="statusOptions" inset-label="Status" />
        <WcDropdown v-model="filterAssetKind" class="basis-40" name="filterAssetKind" :options="kindOptions" inset-label="Type" />
        <WcDropdown v-model="filterAssetAccount" class="basis-40" name="filterAssetAccount" :options="accountOptions" inset-label="Account" />
      </div>
      <div class="flex grow flex-col overflow-scroll">
        <WcTable
          :data="assetsData"
          :columns="[
            { key: 'id', label: 'ID', align: 'left' },
            { key: 'status', label: 'Status', align: 'left' },
            { key: 'daysSinceUpdated', label: 'Updated', align: 'left', thClass: 'max-w-20' },
            { key: 'kind', label: 'Type', align: 'left' },
            { key: 'account', label: 'Account', align: 'left' },
          ]"
          row-id-field="id"
          table-class="text-body-2"
          td-class="px-3 py-2 align-top"
          tr-class="even:bg-blue-100 cursor-pointer hover:bg-blue-200 transition-colors"
          :sortable="true"
          :selected-row-ids="selectedAssetId ? [selectedAssetId] : []"
          @row-clicked="handleRowClicked">
          <template #account="{ row }">
            <div class="max-w-32">
              {{ row.account }}
            </div>
          </template>
          <template #status="{ row }">
            <div class="flex max-w-24">
              <AssetStatusIcon :status="row.status" class="mt-[2px]" />
              {{ row.statusSummary }}
            </div>
          </template>
          <template #daysSinceUpdated="{ row }"> {{ row.daysSinceUpdated }} {{ row.daysSinceUpdated === 1 ? "day ago" : "days ago" }} </template>
          <template #kind="{ row }">
            <div class="max-w-24">
              {{ row.kind }}
            </div>
          </template>
        </WcTable>
      </div>
      <PaginationButtons class="shadow-top p-2" :page-info="pageInfo" @load-items="loadAssets" />
    </div>
    <div class="section-panel w-3/5">
      <router-view v-if="assets" @on-asset-status-update="updateAssetsStateForAsset"></router-view>
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import { useRouter } from "vue-router"
import ProgressBar from "primevue/progressbar"
import type { Asset, AssetKind, AssetsSummary, AssetStatus } from "@common/models/asset"
import { ASSET_KIND, ASSET_STATUS } from "@common/models/asset"
import type { SelectOption } from "@/components/input/WcDropdown"
import WcDropdown from "@/components/input/WcDropdown.vue"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import WcTable from "@/components/WcTable/WcTable.vue"
import { useAdminAccountsStore } from "@/modules/admin/adminAccounts.state"
import AssetStatusIcon from "@/modules/asset/components/AssetStatusIcon.vue"
import type { PageInfo } from "@/services/base-fetcher"
import { useAssetService } from "@/services/service-container"

const NUM_ASSETS_TARGET = 1_000_000

const statusOptions = Object.entries(ASSET_STATUS).map(([status, { summary }]) => ({ label: summary, value: status }))
const kindOptions = Object.entries(ASSET_KIND).map(([value, label]) => ({ label, value }))
const accountOptions = computed(() => {
  return adminAccountsStore.accounts.filter(({ id }) => id > 0).map(({ id, name }) => ({ label: name, value: id }))
})
const assetCountsByStatus = ref<AssetsSummary["countsByStatus"]>()
const assetTotalCount = ref<number>(0)
const assetPercentCompleteDisplay = computed(() => {
  const percent = assetTotalCount.value / NUM_ASSETS_TARGET
  return percent > 2 ? percent : 2
})
const assetRemainder = computed(() => {
  return (NUM_ASSETS_TARGET - assetTotalCount.value).toLocaleString()
})

const adminAccountsStore = useAdminAccountsStore()
const assetService = useAssetService()
const router = useRouter()

type AssetRow = {
  account: string | number
  daysSinceUpdated: number
  id: number
  kind: string
  status: AssetStatus
  statusSummary: string
}

const props = defineProps<{ selectedAssetId?: number | null }>()

const assets = ref<Asset[]>([])

const filterAssetStatus = ref<SelectOption<AssetStatus> | null>(null)
const filterAssetKind = ref<SelectOption<AssetKind> | null>(null)
const filterAssetAccount = ref<SelectOption<number> | null>(null)
const selectedAssetId = ref<number | null>(props.selectedAssetId || null)
const pageInfo = ref<PageInfo>()

const loadAssets = async (url?: string) => {
  try {
    const filters = {
      ...(filterAssetStatus.value && { status: filterAssetStatus.value.value }),
      ...(filterAssetKind.value && { kind: filterAssetKind.value.value }),
      ...(filterAssetAccount.value && { accountIds: filterAssetAccount.value.value }),
    }
    const result = await assetService.listAssetsAdmin({ url, ...filters })
    assets.value = result.data
    pageInfo.value = result.pageInfo
  } catch (error) {
    console.error("There was an error loading assets", error)
  }
}

const loadAssetsSummary = async () => {
  try {
    const result = await assetService.getAssetsAdminSummary()
    assetCountsByStatus.value = result.countsByStatus
    assetTotalCount.value = result.assetCount
  } catch (error) {
    console.error("There was an error loading assets summary info", error)
  }
}

onMounted(async () => {
  loadAssets()
  loadAssetsSummary()
})

watch([filterAssetStatus, filterAssetKind, filterAssetAccount], () => {
  loadAssets()
})

const daysSinceUpdated = (asset: Asset) => {
  const differenceInSeconds = new Date().getTime() - new Date(asset.updatedTime).getTime()
  return Math.round(differenceInSeconds / (1000 * 3600 * 24))
}

const assetsData = computed<AssetRow[]>(() => {
  return assets.value.map((asset) => {
    return {
      account: adminAccountsStore.getAccountById(asset.accountId)?.name ?? asset.accountId,
      daysSinceUpdated: daysSinceUpdated(asset),
      id: asset.id,
      kind: ASSET_KIND[asset.kind],
      status: asset.status,
      statusSummary: ASSET_STATUS[asset.status].summary || asset.status,
    }
  })
})

const updateAssetsStateForAsset = (status: AssetStatus) => {
  assets.value.find((asset) => {
    if (asset.id === selectedAssetId.value) {
      asset.status = status
    }
  })
}

const handleRowClicked = (row: AssetRow, event: MouseEvent | KeyboardEvent) => {
  const path = `/admin/assets/${row.id}`
  if (event.ctrlKey || event.metaKey || event.shiftKey) {
    window.open(path, "_blank")
  } else {
    selectedAssetId.value = row.id
    router.push(path)
  }
}
</script>

<style lang="scss">
.section-panel {
  @apply sticky top-4 flex h-[calc(100vh-8rem)] flex-col overflow-scroll rounded-md bg-white shadow-md;
}
.shadow-top {
  box-shadow: 0 -1px 3px 0 rgb(0 0 0 / 0.1);
}
</style>
