import { RouteRecordRaw } from "vue-router"
export const orderRoutes: Array<RouteRecordRaw> = [
  {
    path: "/orders",
    name: "wc-orders",
    redirect: () => {
      return {
        path: "/accounts/orders",
      }
    },
  },
]
