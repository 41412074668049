import { computed, ref } from "vue"
import type { AssetKind, AssetSubmissionRow } from "@common/models/asset"
import { defineStore } from "pinia"

export const useAssetStore = defineStore("asset", () => {
  const assetSubmissionKind = ref<AssetKind>()
  const assetSubmissionFile = ref<File>()
  const assetSubmissionRows = ref<AssetSubmissionRow[]>([])
  const assetSubmissionGlobalErrors = ref<string[]>([])
  const assetSubmissionGlobalWarnings = ref<string[]>([])

  const numErrorRows = computed<number>(() => assetSubmissionRows.value.filter((row) => row.errors.length > 0).length)
  const numGlobalErrors = computed<number>(() => assetSubmissionGlobalErrors.value.length)
  const hasSubmissionErrors = computed<boolean>(() => numErrorRows.value > 0 || numGlobalErrors.value > 0)
  const isSubmissionValid = computed<boolean>(() => !hasSubmissionErrors.value && assetSubmissionRows.value.length > 0)

  const numWarningRows = computed<number>(() => assetSubmissionRows.value.filter((row) => row.warnings.length > 0).length)
  const numGlobalWarnings = computed<number>(() => assetSubmissionGlobalWarnings.value.length)
  const hasSubmissionWarnings = computed<boolean>(() => numWarningRows.value > 0 || numGlobalWarnings.value > 0)

  const $reset = () => {
    assetSubmissionKind.value = undefined
    assetSubmissionFile.value = undefined
    assetSubmissionRows.value = []
    assetSubmissionGlobalErrors.value = []
    assetSubmissionGlobalWarnings.value = []
  }

  return {
    // refs
    assetSubmissionFile,
    assetSubmissionGlobalErrors,
    assetSubmissionGlobalWarnings,
    assetSubmissionKind,
    assetSubmissionRows,

    // computed
    hasSubmissionErrors,
    hasSubmissionWarnings,
    isSubmissionValid,
    numErrorRows,
    numWarningRows,
    numGlobalErrors,
    numGlobalWarnings,

    // methods
    $reset,
  }
})
