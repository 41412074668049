<template>
  <WcModal :is-open="isOpen" :header="`Add Assets to Story ${story.id}`" size="xlarge" @update:is-open="(event) => $emit('update:is-open', event)">
    <template #panel>
      <section class="flex flex-wrap gap-2 py-2">
        <WcDropdown v-model="filterAssetStatus" class="basis-40" name="filterAssetStatus" :options="statusOptions" inset-label="Status" />
        <WcDropdown v-model="filterAssetKind" class="basis-40" name="filterAssetKind" :options="kindOptions" inset-label="Type" />
        <WcDropdown v-model="filterAssetAccount" class="basis-40" name="filterAssetAccount" :options="accountOptions" inset-label="Account" />
        <WcDropdown v-model="filterAssetStory" class="basis-40" name="filterAssetStory" :options="storyOptions" inset-label="Story" />
      </section>

      <AdminStoryAssetTable
        :assets="assets"
        allow-multiple-selection
        :selected-row-ids="selectedStoryAssetIds"
        :stories="stories"
        show-price
        show-story-column
        @row-clicked="handleRowClicked"
        @rows-selected="handleStoryAssetRowsSelected" />
      <PaginationButtons :page-info="pageInfo" @load-items="loadAvailableAssets" />
    </template>
    <template #footer>
      <ButtonCTA theme="light" :is-filled="false" type="anchor" @click="$emit('update:is-open', false)">Close</ButtonCTA>
      <ButtonCTA theme="dark" :is-disabled="selectedStoryAssetIds.length === 0" :is-filled="true" type="anchor" @click="handleAddAssetsToStory()"
        >Add Assets to Story</ButtonCTA
      >
    </template>
  </WcModal>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import { useToast } from "vue-toastification"
import type { SelectOption } from "@/components/input"
import { WcDropdown } from "@/components/input"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import WcModal from "@/components/WcModal.vue"
import { useAdminAccountsStore } from "@/modules/admin/adminAccounts.state"
import type { PageInfo } from "@/services/base-fetcher"
import { useAssetService } from "@/services/service-container"
import type { Asset, AssetKind, AssetStatus } from "@common/models/asset"
import { ASSET_KIND, ASSET_STATUS } from "@common/models/asset"
import type { Story } from "@common/models/story"
import AdminStoryAssetTable from "./AdminStoryAssetTable.vue"

const statusOptions = Object.entries(ASSET_STATUS).map(([status, { summary }]) => ({ label: summary, value: status }))
const kindOptions = Object.entries(ASSET_KIND).map(([value, label]) => ({ label, value }))
const accountOptions = computed(() => {
  return adminAccountsStore.accounts.filter(({ id }) => id > 0).map(({ id, name }) => ({ label: name, value: id }))
})
const storyOptions = computed(() => {
  const noneOption = { label: "No story", value: 0 }
  const storyOptions = props.stories.filter(({ id }) => id !== props.story.id).map(({ id, name }) => ({ label: `${id}: ${name}`, value: id }))
  return [noneOption, ...storyOptions]
})

defineEmits(["update:is-open"])
const props = defineProps<{ isOpen: boolean; stories: Story[]; story: Story }>()

const adminAccountsStore = useAdminAccountsStore()
const assetService = useAssetService()
const toast = useToast()

const filterAssetStatus = ref<SelectOption<AssetStatus> | null>(null)
const filterAssetKind = ref<SelectOption<AssetKind> | null>(null)
const filterAssetAccount = ref<SelectOption<number> | null>(null)
const filterAssetStory = ref<SelectOption<number | null> | null>(storyOptions.value[0])

const pageInfo = ref<PageInfo>()
const assets = ref<Asset[]>([])
const selectedStoryAssetIds = ref<number[]>([])

const loadAvailableAssets = async (url?: string) => {
  try {
    const filters = {
      ...(filterAssetStatus.value && { status: filterAssetStatus.value.value }),
      ...(filterAssetKind.value && { kind: filterAssetKind.value.value }),
      ...(filterAssetAccount.value && { accountIds: filterAssetAccount.value.value }),
      ...(filterAssetStory.value && { storyId: filterAssetStory.value.value }),
      excludedStoryId: props.story.id,
    }
    const results = await assetService.listAssetsAdmin({ url, ...filters })
    assets.value = results.data
    pageInfo.value = results.pageInfo
  } catch (error) {
    console.error("There was an error loading story assets", error)
  }
}

onMounted(async () => {
  loadAvailableAssets()
})

watch([filterAssetStatus, filterAssetKind, filterAssetAccount, filterAssetStory], () => {
  loadAvailableAssets()
})

watch(
  () => props.isOpen,
  (isOpen) => {
    if (isOpen) {
      loadAvailableAssets()
    } else {
      selectedStoryAssetIds.value = []
    }
  }
)

const handleRowClicked = (row: { id: number }) => {
  const path = `/assets/${row.id}`
  window.open(path, "_blank")
}

const handleStoryAssetRowsSelected = (selectedRowIDs: number[]) => {
  selectedStoryAssetIds.value = selectedRowIDs
}

const handleAddAssetsToStory = async () => {
  const assetIds = selectedStoryAssetIds.value
  try {
    await assetService.setAssetStoryBulkAdmin(assetIds, props.story.id)

    toast.success(`${assetIds.length} assets were added to the story`)
    selectedStoryAssetIds.value = []
    loadAvailableAssets()
  } catch (error) {
    const message = "There was an error setting story assets"
    console.error(message, error)
    if (error instanceof Error) {
      toast.error(`${message}\n${error.message}`)
    }
  }
}
</script>
