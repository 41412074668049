import ApiFetcher from "../api-fetcher"
import { CreatePaymentIntent } from "@/services/api/payment.model"

export default class PaymentService {
  fetcher: ApiFetcher
  constructor(fetcher: ApiFetcher) {
    this.fetcher = fetcher
  }

  async createPaymentIntent(paymentIntent: CreatePaymentIntent) {
    if (paymentIntent.quantity === 0) {
      throw new Error("Minimum order is 1")
    }
    paymentIntent.promoCode = paymentIntent.promoCode === "" ? undefined : paymentIntent.promoCode?.toUpperCase()
    const endpoint = "/payments/create-payment-intent"
    return await this.fetcher.httpPost<any>(endpoint, paymentIntent)
  }
}
