<template>
  <div ref="container" class="relative w-full overflow-y-hidden overflow-x-visible" :style="{ 'max-height': computedMaxHeight }">
    <div ref="contents">
      <slot></slot>
    </div>
    <div v-if="isTruncated" class="pointer-events-none sticky inset-x-0 bottom-0 h-40 bg-gradient-to-t from-white"></div>
  </div>
  <div v-if="canTruncate" class="flex w-full justify-center pt-4">
    <ButtonCTA :is-filled="false" theme="light" @click="isTruncated = !isTruncated">
      <span v-if="isTruncated">{{ props.showAllText }}</span>
      <span v-else>{{ props.showLessText }}</span>
    </ButtonCTA>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"

interface Props {
  maxHeight: number // px
  showAllText: string
  showLessText: string
}

const props = withDefaults(defineProps<Props>(), {
  showAllText: "Show all",
  showLessText: "Show less",
})

const isTruncated = ref(true)
const canTruncate = ref(true)
const computedMaxHeight = computed(() => {
  if (!isTruncated.value) {
    return "none"
  }
  return `${props.maxHeight}px`
})
const contents = ref<HTMLElement | null>(null)
const container = ref<HTMLElement | null>(null)
onMounted(() => {
  const observer = new ResizeObserver(() => {
    if (contents.value && container.value) {
      canTruncate.value = contents.value.offsetHeight > container.value.offsetHeight
    }
  })
  observer.observe(contents.value as HTMLElement)
  return () => observer.disconnect()
})
</script>
