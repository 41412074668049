<template>
  <div class="grid grid-cols-2 gap-4 sm:grid-cols-3">
    <div class="col-span-1">
      <h3 class="text-overline-2 mb-4 inline-block">Total EACs</h3>
      <div data-cy="total-eacs">
        <div class="mb-2">
          <span class="text-featured-number pr-2">
            {{ totalEacs.quantity }}
          </span>
          <span class="text-body-1">
            {{ totalEacs.unit }}
          </span>
        </div>
        <div v-if="totalElectricity.quantity || 0 > 0 || totalEmissions.quantity || 0 > 0" class="text-body-3">
          {{ totalElectricity.quantity }} {{ totalElectricity.unit }} + {{ totalEmissions.quantity }} {{ totalEmissions.unit }}
        </div>
      </div>
    </div>
    <div class="col-span-1">
      <h3 class="text-overline-2 mb-4 inline-block">Total Assets</h3>
      <div v-if="numAssets != null" data-cy="total-assets">
        <span class="text-featured-number pr-2">
          {{ numAssets }}
        </span>
        <span class="text-body-1">assets</span>
      </div>
    </div>
    <div class="col-span-1">
      <h3 class="text-overline-2 mb-4 inline-block">Most Recent Activity</h3>
      <div class="mb-1" data-cy="most-recent-activity">
        <span v-if="recentTransactionAmount?.quantity" class="text-featured-number pr-2">
          {{ recentTransactionAmount.quantity }}
        </span>
        <span class="text-body-1"> {{ recentTransactionSummary }} </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue"
import type { AssetsSummary } from "@common/models/asset"
import { EacMeasurementParameter, getFormattedEacQuantity } from "@common/models/order"
import { useAssetService, useRegistryService, useTransactionService } from "@/services/service-container"
import type { Transaction } from "@/services/api/transaction.service"
import type { AccountBalanceSummary } from "@/services/api/registry.model"

const TRANSACTION_TYPE_TO_SUMMARY: { [key: string]: string } = {
  minting: "created",
  allocation: "allocated",
  transfer: "transferred",
  retirement: "retired",
  purchase: "purchased",
  listing_sale: "sold",
}

const assetService = useAssetService()
const registryService = useRegistryService()
const transactionService = useTransactionService()

const accountBalanceSummary = ref<AccountBalanceSummary>()
const transactions = ref<Transaction[]>()
const numAssets = ref<number>()

onMounted(async () => {
  const results = await Promise.allSettled([
    registryService.getAllTimeBalanceSummary(),
    assetService.getAssetsSummary(),
    transactionService.listTransactions({ per_page: 1 }),
  ])

  const [balanceSummaryResponse, assetsSummaryResponse, transactionsResponse] = results.map((result) => {
    if (result.status === "fulfilled") {
      return result.value
    } else {
      console.error("Error loading dashboard metrics", result.reason)
      return undefined
    }
  })

  accountBalanceSummary.value = balanceSummaryResponse && (balanceSummaryResponse as AccountBalanceSummary)
  numAssets.value = assetsSummaryResponse && (assetsSummaryResponse as AssetsSummary)?.assetCount
  transactions.value = transactionsResponse && (transactionsResponse as { data: Transaction[] })?.data
})

const numEacs = computed(() => {
  if (!accountBalanceSummary.value) return null
  const numActive = accountBalanceSummary.value.active.wh_electricity?.count ?? 0
  const numRetired = accountBalanceSummary.value.retired.wh_electricity?.count ?? 0
  return numActive + numRetired
})

const totalEacs = computed(() => {
  if (numEacs.value === null) return {}
  const combined = getFormattedEacQuantity(numEacs.value, EacMeasurementParameter.EACs)
  const [quantity, ...unit] = combined.split(" ")
  return { quantity: Number.parseFloat(quantity), unit: unit.join(" ") }
})

const totalElectricity = computed(() => {
  if (numEacs.value === null) return {}
  const combined = getFormattedEacQuantity(numEacs.value, EacMeasurementParameter.Electricity)
  const [quantity, unit] = combined.split(" ")
  return { quantity: Number.parseFloat(quantity), unit }
})

const totalEmissions = computed(() => {
  if (!accountBalanceSummary.value) return {}
  const combined = getFormattedEacQuantity(0, EacMeasurementParameter.GhgEmissions, 0)
  const [quantity, unit] = combined.split(" ")
  return { quantity: Number.parseFloat(quantity), unit }
})

const recentTransactionAmount = computed(() => {
  if (!transactions.value?.length) return null
  const combined = getFormattedEacQuantity(transactions.value[0].eacCount, EacMeasurementParameter.EACs)
  const [quantity, ...unit] = combined.split(" ")
  return { quantity: Number.parseFloat(quantity), unit: unit.join(" ") }
})

const recentTransactionSummary = computed(() => {
  if (!transactions.value) return null
  if (transactions.value.length === 0) return "None"
  const description = TRANSACTION_TYPE_TO_SUMMARY[transactions.value[0].kind] ?? "transacted"
  return `${recentTransactionAmount.value?.unit} ${description}`
})
</script>
