const productionHostnames = ["app.wattcarbon.com", "www.wattcarbon.com", "wattcarbon.com"]
export function getEnvironment() {
  const hostname = window.location.hostname
  return getEnvironmentFromHostname(hostname)
}
/**
 * This method is used to determine the environment based on the hostname.
 * It is intended to be used in the browser.
 * Because it may be used in the www subdomain or app subdomain, it needs to be able to handle both.
 */
export const getEnvironmentFromHostname = (hostname: string) => {
  // If environmentment variables from .env file are loaded, return those rather than inferring
  if (import.meta.env.PUBLIC_DEPLOYMENT) {
    return {
      DEPLOYMENT: import.meta.env.PUBLIC_DEPLOYMENT,
      BASE_DOMAIN: import.meta.env.PUBLIC_BASE_DOMAIN,
      API_BASE_URL: import.meta.env.PUBLIC_API_BASE,
      WWW_BASE_URL: import.meta.env.PUBLIC_WWW_BASE,
      APP_BASE_URL: import.meta.env.PUBLIC_APP_BASE,
    }
  }

  // TODO: Once everything between app and www is merged, clean this up!
  if (productionHostnames.includes(hostname)) {
    return {
      DEPLOYMENT: "prod",
      BASE_DOMAIN: "wattcarbon.com",
      API_BASE_URL: "https://api.wattcarbon.com",
      WWW_BASE_URL: "https://www.wattcarbon.com",
      APP_BASE_URL: "https://app.wattcarbon.com",
    }
  }
  const match = hostname.match(/^(.*)\.wattcarbon\.com$/)
  if (!match) {
    return {
      DEPLOYMENT: "local",
      BASE_DOMAIN: "wattcarbon.localhost",
      API_BASE_URL: "https://api.wattcarbon.localhost",
      WWW_BASE_URL: "https://www.wattcarbon.localhost",
      APP_BASE_URL: "https://app.wattcarbon.localhost",
    }
  }

  if (match[1] === "staging") {
    // we have a frontend staging but not a backend staging
    return {
      DEPLOYMENT: "staging",
      BASE_DOMAIN: "wattcarbon.com",
      API_BASE_URL: "https://api.wattcarbon.com",
      WWW_BASE_URL: "https://www.wattcarbon.com",
      APP_BASE_URL: "https://staging.wattcarbon.com",
    }
  }

  // regex match the string before an optional hyphen in the previously matched string
  // e.g. "beta-api" => "beta"
  // const environmentMatch = hostname.match(/^(.*)-?(.*)\.wattcarbon\.com$/) || ['beta']
  const environmentString = match[1].split("-")[0]
  return {
    DEPLOYMENT: environmentString,
    BASE_DOMAIN: "wattcarbon.com",
    API_BASE_URL: `https://${environmentString}-api.wattcarbon.com`,
    WWW_BASE_URL: `https://${environmentString}-www.wattcarbon.com`,
    APP_BASE_URL: `https://${environmentString}.wattcarbon.com`,
  }
}
