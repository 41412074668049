<template>
  <WcDropdown v-model="model" :name="name" :options="options" />
</template>

<script setup lang="ts">
import { computed, defineModel } from "vue"
import { Account, AccountType } from "@common/models/models"
import type { SelectOption } from "@/components/input"
import { WcDropdown } from "@/components/input"
import { useMainStore } from "@/store"

const ALL_ACCOUNTS_OPTION = { label: "All", value: "all" } as SelectOption<string>

const store = useMainStore()

const props = withDefaults(defineProps<{ allowSelectAll?: boolean; name: string }>(), { allowSelectAll: false })

const model = defineModel<SelectOption<string | number> | null>()

const personalAccount = computed(() => {
  return store.accounts.find((account) => account.type === AccountType.individual) as Account
})

const organizationAccounts = computed(() => {
  return store.accounts.filter((account) => account.type === AccountType.organization)
})

const options = computed(() => {
  const accountOptions = [personalAccount.value, ...organizationAccounts.value].filter(Boolean).map((account) => ({
    label: account.name,
    value: account.id,
  })) as SelectOption<number>[]

  if (props.allowSelectAll) {
    return [ALL_ACCOUNTS_OPTION, ...accountOptions]
  } else {
    return accountOptions
  }
})
</script>
