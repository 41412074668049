<template>
  <AssetMetadataTable class="mt-8" title="Electrification Info" :metadata="details" />
</template>

<script setup lang="ts">
import { computed } from "vue"
import type { ElectrificationAsset } from "@common/models/asset"
import { ENERGY_EFFICIENCY_IMPROVEMENTS, HEATING_BACKUP, HEATING_FUEL, INSULATION_STATUS } from "@common/models/asset"
import AssetMetadataTable from "./AssetMetadataTable.vue"

const { asset } = defineProps<{ asset: ElectrificationAsset }>()

const details = computed(() => {
  return {
    "Area Square Feet": asset.areaSqft,
    "Effective Useful Life (Years)": asset.effectiveUsefulLifeYears,
    "Heating Backup System": asset.heatingBackupSystem ? HEATING_BACKUP[asset.heatingBackupSystem] : "",
    "Water Heater UEF": asset.waterHeaterUef,
    "Heat Pump HSPF": asset.heatPumpHspf,
    "Dryer CEF": asset.dryerCef,
    "Cold Climate": asset.coldClimate === true ? "Yes" : "",
    "Energy Efficiency Improvements": asset.energyEfficiencyImprovements ? ENERGY_EFFICIENCY_IMPROVEMENTS[asset.energyEfficiencyImprovements] : "",
    "Replaces Furnace": asset.replacesFurnace ? "Yes" : "No",
    "Replaces Water Heater": asset.replacesWaterHeater ? "Yes" : "No",
    "Replaces Oven": asset.replacesOven ? "Yes" : "No",
    "Replaces Dryer": asset.replacesDryer ? "Yes" : "No",
    "Replaces Pool Heater": asset.replacesPoolHeater ? "Yes" : "No",
    "Previous Fuel": asset.previousFuel ? HEATING_FUEL[asset.previousFuel] : "",
    "Previous Furnace AFUE": asset.previousFurnaceAfue,
    "Previous Water Heater UEF": asset.previousWaterHeaterUef,
    "Previous Insulation Status": asset.previousInsulationStatus ? INSULATION_STATUS[asset.previousInsulationStatus] : "",
    "Likely Heating Thermostat Setpoint (°F)": asset.likelyHeatingThermostatSetpointF,
    "Likely Heating Thermostat Setpoint Offset (°F)": asset.likelyHeatingThermostatSetpointOffsetF,
    "Infiltration Rate (ACH50)": asset.infiltrationRateAch50,
    "Decade Built": asset.decadeBuilt,
  }
})
</script>
