import ApiFetcher from "@/services/api-fetcher"
import { Listing, ListingPurchase, ListingQuarter, ProcurementResponse } from "@common/models/listing"
import { AssetKind } from "@common/models/asset"
import { MarketplaceStory } from "@common/models/story"

type ListingSearchOptions = {
  deviceKind?: AssetKind[] | null
  quarter?: ListingQuarter[] | null
  state?: string[] | null
  portfolioId?: number
  storyId?: number
  paymentId?: number
  available?: boolean
}

type ListingProcurementOptions = {
  desiredQuantity: number
  quantityUnits: string
  sort: string
  deviceKind?: AssetKind[] | null
  quarter?: ListingQuarter[] | null
  state?: string[] | null
  portfolioId?: number
  storyId?: number
}

export default class ListingService {
  fetcher: ApiFetcher
  constructor(fetcher: ApiFetcher) {
    this.fetcher = fetcher
  }

  async getAllListings(options: ListingSearchOptions): Promise<{ stories: Array<MarketplaceStory> }> {
    return await this.fetcher.httpGet<{ stories: Array<MarketplaceStory> }>("/listings", options)
  }

  async getListingPurchases(): Promise<Array<ListingPurchase>> {
    return await this.fetcher.httpGet<Array<ListingPurchase>>("/listings/purchased", {})
  }

  async getListing(listingId: number): Promise<Listing> {
    return await this.fetcher.httpGet<Listing>(`/listings/${listingId}`, {})
  }

  async getListingPurchase(listingId: number): Promise<ListingPurchase> {
    return await this.fetcher.httpGet<ListingPurchase>(`/listings/${listingId}/purchase`, {})
  }

  async purchaseListing(listingId: number, confirmationToken: string, accountId: number): Promise<Listing> {
    return await this.fetcher.httpPut<Listing>(`/listings/${listingId}/purchase`, { confirmationToken, accountId })
  }

  async getListingProcurement(options: ListingProcurementOptions): Promise<ProcurementResponse> {
    return await this.fetcher.httpGet<ProcurementResponse>(`/listings/procurement`, options)
  }

  async submitListingProcurement(procurement: object, confirmationToken: string) {
    return await this.fetcher.httpPost<any>(`/listings/procurement`, { confirmationToken, ...procurement })
  }
}
