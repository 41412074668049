<template>
  <div class="wc-carousel relative">
    <ul class="size-full">
      <TransitionGroup>
        <li v-for="(slide, index) in props.slides" v-show="currentSlide == index" :key="index" class="absolute top-0 size-full">
          <slot name="slide" v-bind="slide"></slot>
        </li>
      </TransitionGroup>
    </ul>

    <div v-if="shouldShowButtons && shouldShowPreviousPage" class="absolute left-6 top-0 flex h-full items-center">
      <button class="page-button size-10 rounded-full bg-white opacity-0" @click="jumpToSlide(currentSlide - 1)">
        <Icon class="ml-[3px] size-8 stroke-blue-500" icon="wc-carbon:chevron-left" />
      </button>
    </div>

    <div v-if="shouldShowButtons && shouldShowNextPage" class="absolute right-6 top-0 flex h-full items-center">
      <button class="page-button size-10 rounded-full bg-white opacity-0" @click="jumpToSlide(currentSlide + 1)">
        <Icon class="ml-1 size-8 stroke-blue-500" icon="wc-carbon:chevron-right" />
      </button>
    </div>

    <div v-if="shouldShowButtons" class="absolute bottom-4 flex w-full flex-row justify-center gap-2.5">
      <button
        v-for="(_slide, index) in props.slides"
        :key="index"
        class="size-2.5 rounded-lg"
        :class="[currentSlide == index ? 'bg-white' : 'bg-neutral-300']"
        @click="jumpToSlide(index)"></button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue"
import { Icon } from "@iconify/vue"
import type { Slide } from "./WcCarousel"

export interface Props {
  slides: Slide[]
  autoplay?: number
}

const props = withDefaults(defineProps<Props>(), {
  autoplay: 3000,
})

const currentSlide = ref(0)
const shouldShowButtons = props.slides.length > 1
const shouldShowPreviousPage = computed(() => {
  return currentSlide.value > 0
})
const shouldShowNextPage = computed(() => {
  return currentSlide.value < props.slides.length - 1
})
const jumpToSlide = (index: number) => {
  currentSlide.value = index
  restartAutoplay()
}

let timer: NodeJS.Timeout | undefined = undefined
const restartAutoplay = () => {
  if (props.slides.length > 1 && props.autoplay !== 0) {
    if (timer) {
      clearInterval(timer)
    }

    timer = setInterval(() => {
      currentSlide.value = (currentSlide.value + 1) % props.slides.length
    }, props.autoplay)
  }
}

onMounted(() => {
  restartAutoplay()
})
</script>

<style scoped lang="scss">
.wc-carousel {
  &:hover {
    .page-button {
      opacity: 0.7;
    }
    button:hover {
      opacity: 1;
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
