<template>
  <AppPage>
    <AppPageHeader :backlink-route="backlinkRoute" :backlink-href="backlinkHref" :backlink-text="backlinkText" />
    <AppPageContent>
      <StoryLoader v-if="!story" :story-id="storyId" @load="onStoryLoad" @not-found="onStoryNotFound" />
      <StoryDetails v-else-if="story && storyMetaData" :story="story" :expanded="!isProcurementOptionsVisible" :meta-data="storyMetaData">
        <template #cta>
          <ButtonCTA v-if="storyMetaData.isAvailableToOrder" type="button" button-classes="bg-highlight" @click="showProcurementOptions">
            See Procurement Options
          </ButtonCTA>
          <template v-else>
            <ButtonCTA v-if="!isCustomOrderFormSubmitted" button-classes="bg-highlight" @click="openCustomOrderModal"> Custom Order </ButtonCTA>
            <div v-else class="flex h-[46px] items-center gap-4">
              <img src="@/static/img/icons/mini/checkcircle.svg" width="32px" height="32px" />
              <p class="text-body-3 !font-semibold text-blue">You're all set!</p>
            </div>
          </template>
        </template>
      </StoryDetails>
      <div v-if="isProcurementOptionsVisible" ref="procurementOptionsContainer">
        <ProcurementModule v-if="story" :story="(story as MarketplaceStory)" @close-procurement-options="isProcurementOptionsVisible = false" />
      </div>
    </AppPageContent>

    <StoryCustomOrderModal v-if="!!story" ref="customOrderModal" :story="story" :form-context="`Story page ${props.storyId}: ${story?.name}`" />
  </AppPage>
</template>

<script setup lang="ts">
import { setPageDescription, setPageImage, setPageTitle } from "@/metatags"
import { computed, nextTick, onMounted, ref } from "vue"
import { useRouter } from "vue-router"
import posthog from "posthog-js"
import { AppPage, AppPageContent, AppPageHeader } from "@/components/layout"
import ButtonCTA from "@/components/ui/ButtonCTA.vue"
import { getEnvironment } from "@/environment"
import { useMarketStore } from "./market.state"
import { Story, MarketplaceStory } from "@common/models/story"
import ProcurementModule from "./ProcurementModule.vue"
import StoryCustomOrderModal from "./StoryCustomOrderModal.vue"

import type { StoryMetaData, LoadableStory } from "./story.utils"
import StoryDetails from "./StoryDetails.vue"
import StoryLoader from "./StoryLoader.vue"

const { WWW_BASE_URL } = getEnvironment()
const router = useRouter()
const marketStore = useMarketStore()

const props = defineProps<{ storyId: number; procurementOptions?: boolean }>()

const backlinkRoute = computed(() => {
  const backUrl = router.options.history.state.back
  return backUrl ? router.resolve({ path: `${backUrl}` }) : undefined
})

const backlinkHref = computed(() => {
  return !backlinkRoute.value ? WWW_BASE_URL : undefined
})

const backlinkText = computed(() => {
  if (backlinkRoute.value?.name === "wc-marketplace-search") {
    return "Back to Search"
  } else {
    return "Back to Marketplace"
  }
})

const story = ref<Story | MarketplaceStory | null>(null)
const storyMetaData = ref<StoryMetaData | null>(null)
const isSearchHeaderVisible = ref<boolean>(false)
const customOrderModal = ref()

const onStoryLoad = (loadedStory: LoadableStory, loadedMetaData: StoryMetaData) => {
  story.value = loadedStory
  storyMetaData.value = loadedMetaData

  if (loadedStory) {
    setPageTitle(loadedStory.name)
    setPageDescription(loadedStory.shortDescription ?? undefined)
    setPageImage(loadedStory.imageUrl ?? undefined)
  }
}

const onStoryNotFound = () => {
  router.push({ name: "wc-404" })
}

const openCustomOrderModal = () => {
  posthog.capture(`Clicked on "Custom Order" from the Story View`)
  customOrderModal.value.openModal()
}

const procurementOptionsContainer = ref<HTMLElement | null>(null)
const isProcurementOptionsVisible = ref<boolean>(false)
const showProcurementOptions = () => {
  isProcurementOptionsVisible.value = true
  nextTick(() => {
    if (procurementOptionsContainer.value) {
      procurementOptionsContainer.value.scrollIntoView({ behavior: "smooth" })
    }
  })
}

const isCustomOrderFormSubmitted = computed(() => {
  return marketStore.customOrderSubmittedStoryIds.includes(props.storyId)
})

onMounted(async () => {
  if (marketStore.hasActiveFilters) {
    isSearchHeaderVisible.value = true
  }
  if (props.procurementOptions) {
    showProcurementOptions()
  }
})
</script>
